import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Injectable} from "@angular/core";
import {
  LOAD_PRODUCT_PARTS,
  LoadProductParts,
  LoadProductPartsFailure,
  LoadProductPartsPayload,
  LoadProductPartsSuccess
} from "./parts.actions";
import {catchError, concatMap, from, map, mergeMap, Observable} from "rxjs";
import {ProductPartsDataService} from "../../data-service/product-parts-data.service";
import {normalizeHttpError} from "@lm-nx-frontend/lm-inf-core";

@Injectable()
export class PartsEffect {
  load$: Observable<
    | LoadProductParts
    | LoadProductPartsFailure
    | LoadProductPartsSuccess
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_PRODUCT_PARTS),
      map((action: LoadProductParts) => action.payload),
      mergeMap((payload) => {
        return this.productPartsDataService
          .search(
            payload.userId,
            payload.productId,
            '',
          )
          .pipe(
            map(
              (response) =>
                new LoadProductPartsSuccess(
                  {
                    userId: payload.userId,
                    productId: payload.productId,
                    parts: response,
                  }
                )
            ),
            catchError((error) =>
              from([
                new LoadProductPartsFailure(
                  payload,
                  normalizeHttpError(error)
                ),
              ])
            )
          );
      }),
      // withdrawOn(this.contextChange$)
    )
  );

  constructor(
    private actions$: Actions,
    private productPartsDataService: ProductPartsDataService
  ) {}
}
