import {StateUtils} from "@lm-nx-frontend/lm-inf-core";
import {User} from "../../models/user";
import {
  CmsSetPageFailIndex,
  LoadCmsPageData,
  LoadCmsPageDataFail,
  LoadCmsPageDataSuccess
} from "../../../../../lm-inf-core/src/lib/cms/store/actions/page.action";
import {Action} from "@ngrx/store";

export const LOAD_USER_SUCCESS = '[User] Load User Success';

export const USER_DATA = '[User] User Data';

// action types
export type UserAction =
  | LoadUserSuccess
;
export interface LoadUserSuccessPayload {
  userId: string
  user: User
}

export class LoadUserSuccess extends StateUtils.EntitySuccessAction {
  override readonly type = LOAD_USER_SUCCESS;

  constructor(
    public override payload: LoadUserSuccessPayload,
  ) {
    super(USER_DATA, payload.userId);
  }
}
