/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { OccConfig } from '@lm-nx-frontend/lm-inf-core';

export const defaultOccInquiryConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        /* eslint-disable max-len */
        createInquiry:
          'users/${userId}/inquiries?fields=DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user',
        /* eslint-disable max-len */
        inquiries:
          'users/${userId}/inquiries?fields=DEFAULT',
        inquiry:
          'users/${userId}/inquiries/${inquiryId}?fields=DEFAULT',
        inquiryMessages:
          'users/${userId}/inquiries/${inquiryId}/messages?fields=DEFAULT',
      },
    },
  },
};
