/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */


import {RoutingConfig} from "@lm-nx-frontend/lm-inf-core";

export const defaultCartRoutingConfig: RoutingConfig = {
  routing: {
    routes: {
      cart: { paths: ['cart'] },
    },
  },
};
