import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {from, map, Observable, of, switchMap, timeout} from 'rxjs';
import {Session} from "./model/session";
import {Oauth2Service} from "./oauth2.service";
import {UserIdService} from "@lm-nx-frontend/lm-inf-core";

@Injectable({
  providedIn: 'root'
})
export class SessionResolver implements Resolve<Session|null> {
  constructor(
    private readonly oauth2Service: Oauth2Service,
    private readonly userIdService: UserIdService,
  ) {}


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Session|null> {
    return this.oauth2Service.session$.pipe(
      switchMap(session => {
        if (session) {
          return of(session)
        }

        sessionStorage?.setItem('redirect_url', state.url)

        this.oauth2Service.initiateAuth()

        return of(null)
      })
    )
  }
}
