/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { InjectionToken } from '@angular/core';
import { Converter } from '@lm-nx-frontend/lm-inf-core';
import {Title, UserSignUp} from "../../root/model";
import {User} from "../../../account/root/model";

export const USER_PROFILE_NORMALIZER = new InjectionToken<Converter<User, any>>(
  'UserProfileNormalizer'
);

export const USER_PROFILE_SERIALIZER = new InjectionToken<Converter<User, any>>(
  'UserProfileSerializer'
);

export const USER_SERIALIZER = new InjectionToken<Converter<User, any>>(
  'UserSerializer'
);

export const USER_SIGN_UP_SERIALIZER = new InjectionToken<
  Converter<UserSignUp, any>
>('UserSignUpSerializer');

export const TITLE_NORMALIZER = new InjectionToken<Converter<any, Title>>(
  'TitleNormalizer'
);
