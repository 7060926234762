<ng-template #details>
  <div class="popover-details">
    <div class="property">
      <label>{{ 'orgUnit.name' | cxTranslate }}</label>
      <a
        class="value"
        [routerLink]="
          {
            cxRoute: 'orgUnitDetails',
            params: { uid: model.id }
          } | cxUrl
        "
      >
        {{ model.name }}
      </a>
    </div>

    <div class="property">
      <label>{{ 'orgUnit.uid' | cxTranslate }}</label>
      <span class="value">
        {{ model.id }}
      </span>
    </div>

    <div class="property">
      <label>{{ 'orgUnit.active' | cxTranslate }}</label>
      <span class="value" [class.is-active]="model.active">
        {{
          (model.active ? 'organization.enabled' : 'organization.disabled')
            | cxTranslate
        }}
      </span>
    </div>

    <div class="property" *ngIf="model.approvalProcess?.name">
      <label>{{ 'orgUnit.approvalProcess' | cxTranslate }}</label>
      <span class="value">
        {{ model.approvalProcess?.name }}
      </span>
    </div>
  </div>
</ng-template>

<button
  class="button text"
  [cxPopover]="details"
  [cxPopoverOptions]="{
    placement: 'auto',
    class: 'my-company-popover',
    appendToBody: true,
    displayCloseButton: true
  }"
>
  {{ model.name }}
</button>
