<ng-container *ngIf="orderEntry$ | async as orderEntry">
  <ng-container *ngIf="isAttributeBasedConfigurator(orderEntry)">
    <ng-container *ngIf="hasStatus(orderEntry)">
      <span
        *ngIf="orderEntry.configurationInfos?.length ?? 0 > 0"
        class="cx-intro cx-visually-hidden"
      >
        {{ 'configurator.a11y.cartEntryInfoIntro'  }}
      </span>
      <div
        *ngFor="let info of orderEntry.configurationInfos; let i = index"
        class="cx-configuration-info"
        attr.aria-describedby="{{ getHiddenConfigurationInfoId(i) }}"
      >
        <span
          id="{{ getHiddenConfigurationInfoId(i) }}"
          class="cx-visually-hidden"
        >
          {{
            'configurator.a11y.cartEntryInfo'
          }}
        </span>
        <div class="cx-label" aria-hidden="true">
          {{ info?.configurationLabel }}:
        </div>
        <div class="cx-value" aria-hidden="true">
          {{ info?.configurationValue }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="quantityControl$ | async as quantityControl">
      <cx-configure-cart-entry
        *ngIf="(shouldShowButton$ | async) && orderEntry?.product?.configurable"
        [cartEntry]="orderEntry"
        [readOnly]="(readonly$ | async) ?? true"
        [msgBanner]="false"
        [disabled]="quantityControl.disabled"
      ></cx-configure-cart-entry
    ></ng-container>
  </ng-container>
</ng-container>
