<ng-container *ngIf="current$ | async as item">
  <section
    *ngIf="
      displayDisabledCreate(item) ||
      displayDisabledEdit(item) ||
      displayDisabledEnable(item) ||
      displayDisabledDisable(item) ||
      displayCustomInfo
    "
  >
    <cx-icon [type]="iconTypes.INFO"></cx-icon>
    <ul>
      <li *ngIf="displayDisabledEnable(item)">
        {{ i18nRoot + '.info.disabledEnable' | cxTranslate }}
      </li>
      <li *ngIf="displayDisabledCreate(item)">
        {{ i18nRoot + '.info.disabledCreate' | cxTranslate }}
      </li>
      <li *ngIf="displayDisabledEdit(item)">
        {{ i18nRoot + '.info.disabledEdit' | cxTranslate }}
      </li>
      <li *ngIf="displayDisabledDisable(item)">
        {{ i18nRoot + '.info.disabledDisable' | cxTranslate }}
      </li>
      <ng-content></ng-content>
    </ul>
  </section>
</ng-container>
