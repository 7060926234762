/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import { AdministrationComponentsModule } from './components/public_api';
import { AdministrationCoreModule } from './core/public_api';
import { AdministrationOccModule } from './occ/public_api';

@NgModule({
  imports: [
    AdministrationCoreModule.forRoot(),
    AdministrationOccModule,
    AdministrationComponentsModule,
  ],
})
export class AdministrationModule {}
