<cx-org-sub-list
  key="id"
  [routerKey]="routerKey"
  class="has-nested-view"
  [showHint]="true"
>
  <a actions class="link" routerLink="create">
    {{ 'organization.create' | cxTranslate }}
  </a>
</cx-org-sub-list>
