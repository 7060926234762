import {Action} from "@ngrx/store";
import {CustomerAddressState, defaultCustomerAddressState} from "./address.state";
import {
  CREATE_ADDRESS,
  CREATE_ADDRESS_FAILURE,
  CREATE_ADDRESS_SUCCESS,
  CreateCustomerAddressSuccess, EDIT_ADDRESS,
  EDIT_ADDRESS_FAILURE,
  EDIT_ADDRESS_SUCCESS,
  EditCustomerAddressSuccess,
  LOAD_ADDRESS, LOAD_ADDRESS_FAILURE,
  LOAD_ADDRESS_SUCCESS,
  LoadCustomerAddressSuccess
} from "./address.actions";

export function addressReducer(
  state: CustomerAddressState = defaultCustomerAddressState,
  action: Action,
): CustomerAddressState {
  switch (action.type) {
    case LOAD_ADDRESS: {
      return {
        ...state,
        loading: state.loading+1,
      };
    }

    case LOAD_ADDRESS_SUCCESS: {
      const { payload } = (action as LoadCustomerAddressSuccess);
      const entities = Object.fromEntries(
        payload.addresses.map((a) => [a.id, { value: a }])
      );
      const ids = payload.addresses.map((a) => a.id);

      return {
        ...state,
        entities,
        ids,
        loading: state.loading-1,
      };
    }

    case LOAD_ADDRESS_FAILURE:
      return {
        ...state,
        loading: state.loading-1,
      }


    case CREATE_ADDRESS:
      return {
        ...state,
        loading: state.loading+1,
      }
    case CREATE_ADDRESS_FAILURE:
      return {
        ...state,
        loading: state.loading-1,
      }

    case CREATE_ADDRESS_SUCCESS: {
      const { payload } = (action as CreateCustomerAddressSuccess);

      return {
        ...state,
        loading: state.loading-1,
        entities: {
          ...state.entities,
          [payload.address.id]: { value: payload.address },
        },
        ids: [
          ...state.ids,
          payload.address.id,
        ],
      };
    }


    case EDIT_ADDRESS:
      return {
        ...state,
        loading: state.loading+1,
      }
    case EDIT_ADDRESS_SUCCESS: {
      const { payload } = (action as EditCustomerAddressSuccess);

      return {
        ...state,
        loading: state.loading-1,
        entities: {
          ...state.entities,
          [payload.customerAddress.id]: { value: payload.customerAddress },
        },
      };
    }

    case EDIT_ADDRESS_FAILURE:
      return {
        ...state,
        loading: state.loading-1,
      }

  }

  return state;
}
