<div
  *ngIf="showRequiredMessageForUserInput$ | async"
  class="cx-required-error-msg"
  id="{{ createAttributeUiKey('attribute-msg', attribute.name) }}"
  [attr.aria-label]="
    'configurator.attribute.defaultRequiredMessage'
  "
  aria-live="assertive"
  aria-atomic="true"
  role="alert"
>
  <cx-icon [type]="iconType.ERROR"></cx-icon>
  {{ 'configurator.attribute.defaultRequiredMessage' }}
</div>
