import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Observable, tap} from "rxjs";
import {ProductPart} from "../../models/part";
import {WithProductState} from "../state";
import {selectProductPartsValueFromRouter} from "./parts.selectors";
import {LoadProductParts} from "./parts.actions";
import {ItemsModel} from "@lm-nx-frontend/lm-inf-core";

@Injectable({
  providedIn: 'root'
})
export class ProductPartsFacade {
  parts$: Observable<ItemsModel<ProductPart>> = this.store.select(
    selectProductPartsValueFromRouter,
  ).pipe(
  )

  constructor(
    protected readonly store: Store<WithProductState>
  ) {
  }

  load(
    userId: string,
    productId: string,
  ): void {
    this.store.dispatch(
      new LoadProductParts({
        userId,
        productId,
      })
    )
  }
}
