<ng-container *ngIf="product$ | async as product">
  <div class="rating" *ngIf="product?.averageRating">
    <cx-star-rating [rating]="product?.averageRating ?? 0"></cx-star-rating>

    <div class="count">({{ product?.numberOfReviews }})</div>

    <button
      *ngIf="areReviewsAvailable$ | async"
      class="btn btn-link cx-action-link"
      (click)="showReviews()"
      [attr.aria-label]="
        'productSummary.showReviewsDetailed'
          | cxTranslate
            : {
                rating: product?.averageRating | number: '1.0-1',
                count: product?.numberOfReviews
              }
      "
    >
      {{ 'productSummary.showReviews' | cxTranslate }}
    </button>
  </div>
  <div class="rating" *ngIf="!product?.averageRating">
    {{ 'productDetails.noReviews' | cxTranslate }}
  </div>
  <div class="code">
    {{ 'productSummary.id' | cxTranslate }} {{ product?.code }}
  </div>
</ng-container>
