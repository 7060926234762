/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  LoginEvent,
  LogoutEvent,
  Query,
  QueryService,
  UserIdService,
} from '@lm-nx-frontend/lm-inf-core';
import { switchMap } from 'rxjs/operators';
import { UserAccountConnector } from '../connectors/user-account.connector';
import {UserAccountChangedEvent} from "../../root/events";
import {UserAccountFacade} from "../../root/facade";
import {User} from "../../root/model";

@Injectable()
export class UserAccountService implements UserAccountFacade {
  protected userQuery: Query<User> = this.query.create(
    () =>
      this.userIdService
        .takeUserId(true)
        .pipe(switchMap((userId) => this.userAccountConnector.get(userId))),
    {
      reloadOn: [UserAccountChangedEvent],
      resetOn: [LoginEvent, LogoutEvent],
    }
  );

  constructor(
    protected userAccountConnector: UserAccountConnector,
    protected userIdService: UserIdService,
    protected query: QueryService
  ) {}

  /**
   * Returns the current user.
   */
  get(): Observable<User | undefined> {
    return this.userQuery.get();
  }
}
