<ng-container *ngIf="displayFormula()">
  <ng-container *ngIf="displayPriceOnly()">
    <div
      [ngClass]="styleClass"
      [attr.aria-label]="'configurator.a11y.valueSurcharge'"
    >
      {{ price }}
    </div>
  </ng-container>
  <ng-container *ngIf="displayQuantityAndPrice()">
    <div
      class="cx-quantity-price"
      [attr.aria-label]="'configurator.a11y.valueSurcharge'"
    >
      {{ quantityWithPrice(formula?.quantity | cxNumeric) }}
    </div>
    <div class="cx-price-total">{{ priceTotal }}</div>
  </ng-container>
</ng-container>
