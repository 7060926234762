import {OccConfig} from "@lm-nx-frontend/lm-inf-core";

export function defaultConfiguratorConfigFactory(): OccConfig {
  return {
    backend: {
      occ: {
        endpoints: {
          createConfiguration: 'configurator/configs',

          readVariantConfiguration: 'configurator/configs/${configId}',

          updateVariantConfiguration: 'configurator/configs/${configId}',

          readVariantConfigurationPriceSummary:
            'configurator/configs/${configId}/pricing',

          addVariantConfigurationToCart:
            'users/${userId}/configurator/carts/${cartId}/items',



          readVariantConfigurationForCartEntry:
            'users/${userId}/carts/${cartId}/entries/${cartEntryNumber}/ccpconfigurator',

          updateVariantConfigurationForCartEntry:
            'users/${userId}/carts/${cartId}/entries/${cartEntryNumber}/ccpconfigurator',

          readVariantConfigurationOverviewForOrderEntry:
            'users/${userId}/orders/${orderId}/entries/${orderEntryNumber}/ccpconfigurator/configurationOverview',

          getVariantConfigurationOverview:
            'ccpconfigurator/${configId}/configurationOverview',

          searchConfiguratorVariants: 'ccpconfigurator/${configId}/variants',
        },
      },
    },
  };
}
