/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { EntitiesModel, PaginationModel } from '@lm-nx-frontend/lm-inf-core';
import { TableService } from '@lm-nx-frontend/lm-inf-ui';
import { Observable } from 'rxjs';
import { SubListService } from '../../../shared/sub-list/sub-list.service';
import { OrganizationTableType } from '../../../shared/organization.model';
import {OrgUnitService} from "../../../../core/services";
import {B2BUnitNode} from "../../../../core/model";

@Injectable({
  providedIn: 'root',
})
export class UnitChildrenService extends SubListService<B2BUnitNode> {
  protected tableType = OrganizationTableType.UNIT_CHILDREN;
  protected _domainType = OrganizationTableType.UNIT;

  constructor(
    protected tableService: TableService,
    protected orgUnitService: OrgUnitService
  ) {
    super(tableService);
  }

  // method to be adjusted for proper children list when ready
  protected load(
    _pagination: PaginationModel,
    code: string
  ): Observable<EntitiesModel<B2BUnitNode>> {
    return this.orgUnitService.getChildUnits(code);
  }
}
