/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const address = {
  addressForm: {
    title: 'Title',
    defaultTitle: 'Title',
    firstName: {
      label: 'First name',
      placeholder: 'First Name',
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Last Name',
    },
    address1: 'Address 1',
    address2: 'Address 2 (optional)',
    country: 'Country/Region',
    city: {
      label: 'City',
      placeholder: 'City',
    },
    state: 'State',
    zipCode: {
      label: 'Zip code',
      placeholder: 'Postal Code/Zip',
    },
    phoneNumber: {
      label: 'Phone number (optional)',
      placeholder: '(555) 555 - 0123',
    },
    cellphone: {
      label: 'Mobile number (optional)',
      placeholder: '(555) 555 - 0123',
    },
    saveAsDefault: 'Save as default',
    chooseAddress: 'Choose address',
    streetAddress: 'Street Address',
    aptSuite: 'Apt, Suite',
    selectOne: 'Select One...',
    setAsDefault: 'Set as default delivery address',
    titleRequired: 'Title is required.',
    userAddressAddSuccess: 'New address was added successfully!',
    userAddressUpdateSuccess: 'Address updated successfully!',
    userAddressDeleteSuccess: 'Address deleted successfully!',
    invalidAddress: 'Invalid Address',
  },
  addressBook: {
    addNewDeliveryAddress: 'Add a new delivery address',
    editDeliveryAddress: 'Edit delivery address',
    areYouSureToDeleteAddress: 'Are you sure you want to delete this address?',
    addNewAddress: 'Add new address',
    addAddress: 'Add address',
    updateAddress: 'Update address',
    backToAddressList: 'Back to address list',
    defaultDeliveryAddress: 'Default Delivery Address',
    additionalDeliveryAddress: 'Additional Delivery Address {{number}}',
  },
  addressCard: {
    default: 'DEFAULT',
    selected: 'Selected',
    setAsDefault: 'Set as default',
    shipTo: 'Ship To',
    billTo: 'Bill To',
    phoneNumber: 'P',
    mobileNumber: 'M',
  },
  addressSuggestion: {
    verifyYourAddress: 'Verify your address',
    ensureAccuracySuggestChange:
      'To ensure delivery accuracy, we suggest the change selected below.',
    chooseAddressToUse: 'Please choose which address you would like to use:',
    suggestedAddress: 'Suggested address',
    enteredAddress: 'Entered address',
    editAddress: 'Edit address',
    saveAddress: 'Save address',
  },
  addressMessages: {
    setAsDefaultSuccessfully:
      'Address {{ streetAddress }} was successfully set as default',
  },
};
