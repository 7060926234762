import {ModuleWithProviders, NgModule} from '@angular/core';
import {API_CONFIG} from "./api-config.injection-token";
import {ApiConfig} from "./api-config";

@NgModule({
  providers: [
  ]
})
export class LmInfApiModule {
  static forRoot(apiConfig: ApiConfig): ModuleWithProviders<LmInfApiModule> {
    return {
      ngModule: LmInfApiModule,
      providers: [
        {
          provide: API_CONFIG,
          useValue: apiConfig,
        }
      ]
    }
  }
}
