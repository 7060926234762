/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfiguratorConflictDescriptionComponent } from './configurator-conflict-description.component';
import {IconModule} from "@lm-nx-frontend/lm-inf-ui";

@NgModule({
  imports: [CommonModule, IconModule],
  declarations: [ConfiguratorConflictDescriptionComponent],
  exports: [ConfiguratorConflictDescriptionComponent],
})
export class ConfiguratorConflictDescriptionModule {}
