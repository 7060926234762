import {createFeatureSelector, createSelector} from "@ngrx/store";
import {selectUser} from "../user.selectors";
import {UserState} from "../state";


export const selectUserUser = createSelector(
  selectUser,
  (user: UserState) => user.user
)

export const selectUserUserCurrentId = createSelector(
  selectUserUser,
  (user) => user.currentId,
)

export const selectUserUserEntities = createSelector(
  selectUserUser,
  (user) => user.entities,
)

export const selectUserUserCurrentUser = createSelector(
  selectUserUserCurrentId,
  selectUserUserEntities,
  (currentId, entities) => entities?.[currentId]?.value,
)
