<cx-carousel
  *ngIf="getItems() | async as items"
  [items]="items"
  [template]="template"
  itemWidth="100%"
  class="inline-navigation"
></cx-carousel>

<ng-template #template let-item="item">
  <ng-container
    [cxComponentWrapper]="{
      flexType: item.typeCode,
      type: item.typeCode,
      id: item?.uid
    }"
  >
  </ng-container>
</ng-template>
