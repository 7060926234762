import {Action} from "@ngrx/store";
import {
  LOAD_PRODUCT_PARTS_FAILURE,
  LOAD_PRODUCT_PARTS_SUCCESS,
  PRODUCT_PARTS_DATA,
  ProductPartsAction
} from "./parts.actions";
import {ProductPartsState} from "./parts.state";
import {entityLoaderReducer, ItemsModel} from "@lm-nx-frontend/lm-inf-core";
import {ProductPart} from "@lm-nx-frontend/lm-inf-product-root";

function partsCoreReducer(
  state: ItemsModel<ProductPart>|null = null,
  action: ProductPartsAction,
): ItemsModel<ProductPart>|null {
  switch (action.type) {
    case LOAD_PRODUCT_PARTS_SUCCESS: {
      return action.payload.parts;
    }
    case LOAD_PRODUCT_PARTS_FAILURE: {
     return null;
    }
  }

  return state;
}

export const partsReducer = entityLoaderReducer<ItemsModel<ProductPart>|null, any>(
  PRODUCT_PARTS_DATA,
  partsCoreReducer,
);
