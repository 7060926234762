import {APP_INITIALIZER, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {AuthorizationInterceptor} from "./interceptor/authorization.interceptor";
import {AuthConfig} from "./auth-config";
import {AUTH_CONFIG} from "./auth-config.injection-token";

@NgModule({
  imports: [
    CommonModule,

  ],
})
export class Oauth2Module {
  static forRoot(cfg: AuthConfig) {
    return {
      ngModule: Oauth2Module,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
        { provide: AUTH_CONFIG, useValue: cfg },
      ],
    };
  }
}
