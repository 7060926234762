<cx-org-form i18nRoot="orgBudget">
  <ng-container *ngIf="form" [formGroup]="form" main>
    <label>
      <span class="label-content required">{{
        'orgBudget.name' | cxTranslate
      }}</span>
      <input
        type="text"
        class="form-control"
        required
        placeholder="{{ 'orgBudget.name' | cxTranslate }}"
        formControlName="name"
        (blur)="createCodeWithName(form.get('name'), form.get('code'))"
      />
      <cx-form-errors [control]="form.get('name')"></cx-form-errors>
    </label>

    <label>
      <span class="label-content required">{{
        'orgBudget.code' | cxTranslate
      }}</span>
      <input
        class="form-control"
        type="text"
        required
        placeholder="{{ 'orgBudget.code' | cxTranslate }}"
        formControlName="code"
      />
      <cx-form-errors [control]="form.get('code')"></cx-form-errors>
    </label>

    <label>
      <span class="label-content">{{
        'orgBudget.startDate' | cxTranslate
      }}</span>
      <cx-date-picker
        [control]="$any(form.get('startDate'))"
        [max]="form.get('endDate')?.value"
        [required]="true"
        (update)="form.get('endDate')?.updateValueAndValidity()"
      ></cx-date-picker>
    </label>

    <label>
      <span class="label-content">{{ 'orgBudget.endDate' | cxTranslate }}</span>
      <cx-date-picker
        [control]="$any(form.get('endDate'))"
        [min]="form.get('startDate')?.value"
        [required]="true"
        (update)="form.get('startDate')?.updateValueAndValidity()"
      ></cx-date-picker>
    </label>

    <label [formGroup]="$any(form.get('currency'))">
      <span class="label-content required">{{
        'orgBudget.currency' | cxTranslate
      }}</span>
      <ng-select
        [inputAttrs]="{ required: 'true' }"
        formControlName="isocode"
        [searchable]="false"
        [clearable]="false"
        [items]="currencies$ | async"
        bindLabel="name"
        bindValue="isocode"
        [class.invalid]="form.get('currency.isocode')?.invalid ?? false"
        appendTo="cx-org-list"
        [placeholder]="'orgBudget.currency' | cxTranslate"
      >
      </ng-select>
      <cx-form-errors [control]="form.get('currency.isocode')"></cx-form-errors>
    </label>

    <label>
      <span class="label-content">{{ 'orgBudget.amount' | cxTranslate }}</span>
      <input
        required="true"
        type="number"
        class="form-control"
        placeholder="{{ 'orgBudget.amount' | cxTranslate }}"
        formControlName="budget"
        min="0"
      />
      <cx-form-errors [control]="form.get('budget')"></cx-form-errors>
    </label>

    <label
      *ngIf="units$ | async as units"
      [formGroup]="$any(form.get('orgUnit'))"
    >
      <span class="label-content required">{{
        'orgBudget.businessUnits' | cxTranslate
      }}</span>
      <ng-select
        [inputAttrs]="{ required: 'true' }"
        formControlName="uid"
        [searchable]="true"
        [clearable]="false"
        [items]="units"
        bindLabel="name"
        bindValue="id"
        [readonly]="form.get('orgUnit.uid')?.disabled ?? false"
        appendTo="cx-org-list"
        [placeholder]="'orgBudget.businessUnits' | cxTranslate"
      >
      </ng-select>
      <cx-form-errors [control]="form.get('orgUnit.uid')"></cx-form-errors>
    </label>
  </ng-container>
</cx-org-form>
