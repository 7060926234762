<ng-container *ngIf="cartEntry">
  <label *ngIf="isDisabled()" class="disabled-link">
    <ng-container *ngIf="isDisabled(); then configureText"> </ng-container>
  </label>

  <a
    *ngIf="!isDisabled()"
    class="link cx-action-link"
    [routerLink]="
      {
        cxRoute: getRoute(),
        params: {
          ownerType: getOwnerType(),
          entityKey: getEntityKey(),
          displayOnly: getDisplayOnly()
        }
      } | cxUrl
    "
    [queryParams]="getQueryParams()"
    cxAutoFocus
    attr.aria-describedby="{{ getResolveIssuesA11yDescription() }}"
  >
    <ng-container *ngIf="!isDisabled(); then configureText"> </ng-container>
  </a>
</ng-container>

<ng-template #configureText>
  <ng-container *ngIf="readOnly">
    {{ 'configurator.header.displayConfiguration' }}</ng-container
  >
  <ng-container *ngIf="!readOnly && !msgBanner">
    {{ 'configurator.header.editConfiguration' }}
  </ng-container>

  <ng-container *ngIf="!readOnly && msgBanner">
    {{ 'configurator.header.resolveIssues' }}
  </ng-container>
</ng-template>
