/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Component } from '@angular/core';
import {UnitListComponent} from "../../../administration/components/unit";
@Component({
  selector: 'cx-account-summary-list',
  templateUrl: './account-summary-list.component.html',
})
export class AccountSummaryListComponent extends UnitListComponent {}
