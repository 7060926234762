import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Injectable} from "@angular/core";
import {catchError, concatMap, from, map, mergeMap, Observable} from "rxjs";
import {ProductPartsDataService} from "../../data-service/product-parts-data.service";
import {normalizeHttpError} from "@lm-nx-frontend/lm-inf-core";
import {
  LOAD_PRODUCT_PRODUCT,
  LoadProductProduct,
  LoadProductProductFailure,
  LoadProductProductSuccess
} from "./product.actions";
import {LoadProductParts} from "../parts/parts.actions";
import {ProductProductDataService} from "../../data-service/product-product-data.service";

@Injectable()
export class ProductEffect {
  load$: Observable<
    | LoadProductProduct
    | LoadProductProductFailure
    | LoadProductProductSuccess
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_PRODUCT_PRODUCT),
      map((action: LoadProductProduct) => action.payload),
      mergeMap((payload) => {
        return this.productProductDataService
          .get(
            payload.userId,
            payload.productId,
          )
          .pipe(
            map(
              (response) =>
                new LoadProductProductSuccess(
                  {
                    userId: payload.userId,
                    productId: payload.productId,
                    product: response,
                  }
                )
            ),
            catchError((error) =>
              from([
                new LoadProductProductFailure(
                  payload,
                  normalizeHttpError(error)
                ),
              ])
            )
          );
      }),
      // withdrawOn(this.contextChange$)
    )
  );

  constructor(
    private actions$: Actions,
    private productProductDataService: ProductProductDataService
  ) {}
}
