/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideDefaultConfig } from '@lm-nx-frontend/lm-inf-core';
import { OccUserRegistrationAdapter } from './adapters';
import { defaultOccOrganizationUserRegistrationConfig } from './config/default-occ-organization-config';
import {UserRegistrationAdapter} from "../core/connectors";

@NgModule({
  imports: [CommonModule],
  providers: [
    provideDefaultConfig(defaultOccOrganizationUserRegistrationConfig),
    {
      provide: UserRegistrationAdapter,
      useExisting: OccUserRegistrationAdapter,
    },
  ],
})
export class UserRegistrationOccModule {}
