<ng-container *ngIf="product$ | async as product">
  <ng-container *ngIf="product.configurable">
    <a
      [routerLink]="
        {
          cxRoute: 'configure' + product.configuratorType,
          params: {
            ownerType: ownerTypeProduct,
            entityKey: product.code
          }
        } | cxUrl
      "
      [queryParams]="{ displayRestartDialog: 'true' }"
      class="btn btn-primary btn-block"
      cxAutoFocus
      [attr.aria-label]="'configurator.a11y.configureProduct'"
      >{{ 'configurator.header.toconfig' }}</a
    >
  </ng-container>
</ng-container>
