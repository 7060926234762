import { OccConfig } from '@lm-nx-frontend/lm-inf-core';

export const defaultOccProductConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        /* eslint-disable max-len */
        productParts: 'products/${productId}/parts?fields=DEFAULT',
        product: 'products/${productId}?fields=DEFAULT',
      },
    },
  },
};
