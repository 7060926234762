
export const environment = {
  baseURL: 'https://api.portal.nonprod.lotzer-muehlenbruch.com/v1',

  env: 'nonprod',

  oauth: {
    host: 'auth.portal.nonprod.lotzer-muehlenbruch.com',
    clientId: '44gf7ecosevmtqivu5cgrakqbj',
  }
};
