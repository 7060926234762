<div
  class="cx-suggested-addresses-dialog"
  [cxFocus]="focusConfig"
  (esc)="closeModal('Escape clicked')"
>
  <div *ngIf="data$ | async as data" class="cx-suggested-addresses-container">
    <div class="cx-suggested-addresses-header cx-modal-header">
      <div class="cx-suggested-addresses-title">
        {{ 'addressSuggestion.verifyYourAddress' | cxTranslate }}
      </div>
      <button
        type="button"
        class="close"
        [attr.aria-label]="'common.close' | cxTranslate"
        (click)="closeModal('Cross click')"
      >
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>
    <div class="cx-suggested-addresses-body" ngForm>
      <div class="cx-dialog-info">
        <p>
          {{ 'addressSuggestion.ensureAccuracySuggestChange' | cxTranslate }}
          {{ 'addressSuggestion.chooseAddressToUse' | cxTranslate }}
        </p>
      </div>

      <div class="cx-dialog-options">
        <div
          class="form-check"
          *ngFor="
            let suggestedAddress of data.suggestedAddresses;
            let i = index
          "
        >
          <input
            class="form-check-input"
            type="radio"
            name="selectedAddress"
            [(ngModel)]="selectedAddress"
            [value]="suggestedAddress"
            [id]="'suggested-addresses--suggested-' + i"
          />
          <label
            class="form-check-label cx-dialog-label"
            [for]="'suggested-addresses--suggested-' + i"
          >
            {{ 'addressSuggestion.suggestedAddress' | cxTranslate }}
            {{ data.suggestedAddresses.length > 1 ? i + 1 : null }}
          </label>
          <div class="cx-dialog-suggested">
            <div class="cx-card-container">
              <div class="cx-card-label">{{ suggestedAddress?.firstName }}</div>
              <div class="cx-card-label">{{ suggestedAddress?.lastName }}</div>
              <div class="cx-card-label">{{ suggestedAddress?.line1 }}</div>
              <div class="cx-card-label">
                <span>{{ suggestedAddress?.line2 }}</span>
              </div>
              <div class="cx-card-label">{{ suggestedAddress?.town }}</div>
              <div class="cx-card-label">
                {{ suggestedAddress?.region?.isocode }}
              </div>
              <div class="cx-card-label">
                {{ suggestedAddress?.postalCode }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="selectedAddress"
            [(ngModel)]="selectedAddress"
            [value]="data.enteredAddress"
            id="suggested-addresses--entered"
          />
          <label
            class="form-check-label cx-dialog-label"
            for="suggested-addresses--entered"
          >
            {{ 'addressSuggestion.enteredAddress' | cxTranslate }}
          </label>
          <div class="cx-dialog-entered">
            <div class="cx-card-container">
              <div class="cx-card-label">
                {{ data.enteredAddress?.firstName }}
                {{ data.enteredAddress?.lastName }}
              </div>
              <div class="cx-card-label">{{ data.enteredAddress?.line1 }}</div>
              <div class="cx-card-label">
                <span>{{ data.enteredAddress?.line2 }}</span>
              </div>
              <div class="cx-card-label">
                {{ data.enteredAddress?.town }}
                {{ data.enteredAddress?.region?.isocode }}
              </div>
              <div class="cx-card-label">
                {{ data.enteredAddress?.postalCode }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cx-suggested-addresses-footer cx-modal-footer">
      <div class="row">
        <div class="cx-dialog-actions col-sm-12 col-md-12 col-lg-6 offset-lg-6">
          <button
            class="btn btn-secondary cx-dialog-buttons"
            (click)="closeModal('Edit address')"
          >
            {{ 'addressSuggestion.editAddress' | cxTranslate }}
          </button>
          <button
            cxAutoFocus
            class="btn btn-primary cx-dialog-buttons"
            (click)="closeModal(selectedAddress)"
          >
            {{ 'addressSuggestion.saveAddress' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
