/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {registerLocaleData} from "@angular/common";
import { datadogRum } from '@datadog/browser-rum';

import localeEn from '@angular/common/locales/en-DE';
import {environment} from "./environments/environment";

datadogRum.init({
  applicationId: '213e0e80-0834-43fb-bd6d-7a19e5c89152',
  clientToken: 'pub900edde63dbffd03e7de256a9d6aa51f',
  site: 'datadoghq.eu',
  service:'infinity',
  env: environment.env,
  // proxy: environment.baseURL + '/rum',
  // Specify a version number to identify the deployed version of your application in Datadog
  version: 'beta',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel:'allow',
  traceSampleRate: 100,
  allowedTracingUrls: [
    { match: environment.baseURL, propagatorTypes: ["tracecontext"]}
  ]
});

datadogRum.startSessionReplayRecording();

registerLocaleData(localeEn);

platformBrowserDynamic([
    // {
    //   provide: null,
    //   useValue: datadogRum,
    // }
  ])
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
