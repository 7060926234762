/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { Converter, Occ, TimeUtils } from '@lm-nx-frontend/lm-inf-core';
import {Budget} from "../../core/model";

@Injectable({
  providedIn: 'root',
})
export class OccBudgetNormalizer implements Converter<Occ.Budget, Budget> {
  convert(source: Occ.Budget, target?: Budget): Budget {
    if (target === undefined) {
      target = { ...(source as any) } as Budget;
    }

    if (source.startDate) {
      target.startDate = TimeUtils.convertDatetimeToDate(source.startDate);
    }

    if (source.endDate) {
      target.endDate = TimeUtils.convertDatetimeToDate(source.endDate);
    }

    return target;
  }
}
