/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { OccConfig } from './occ-config';

export const defaultOccConfig: OccConfig = {
  backend: {
    occ: {
      // prefix: '/v1/',
    },
    media: {},
  },
};
