<ng-container *ngFor="let cartModification of cartModifications$ | async">
  <div
    class="alert alert-danger"
    *ngIf="visibleWarnings[cartModification?.entry?.product?.code ?? '']"
  >
    <span class="alert-icon">
      <cx-icon [type]="iconTypes.ERROR"></cx-icon>
    </span>
    <span>
      <a
        [routerLink]="
          { cxRoute: 'product', params: cartModification.entry!.product } | cxUrl
        "
      >
        {{ cartModification?.entry?.product?.name }}
      </a>
      {{ 'validation.productOutOfStock' | cxTranslate }}
    </span>

    <button
      class="close"
      type="button"
      (click)="removeMessage(cartModification)"
    >
      <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
    </button>
  </div>
</ng-container>
