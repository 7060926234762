/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import {UnitListService} from "../../../administration/components/unit";
import {OrganizationTableType} from "../../../administration/components/public_api";

@Injectable({
  providedIn: 'root',
})
export class AccountSummaryUnitListService extends UnitListService {
  protected tableType = OrganizationTableType.ACCOUNT_SUMMARY_UNIT as any;
}
