/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@lm-nx-frontend/lm-inf-core';
import { ConfiguratorAttributeFooterModule } from '../attribute/footer/configurator-attribute-footer.module';
import { ConfiguratorAttributeHeaderModule } from '../attribute/header/configurator-attribute-header.module';
import { ConfiguratorAttributeNumericInputFieldModule } from '../attribute/types/numeric-input-field/configurator-attribute-numeric-input-field.module';
import { ConfiguratorAttributeNotSupportedModule } from '../attribute/types/not-supported/configurator-attribute-not-supported.module';
import { ConfiguratorAttributeCompositionModule } from '../attribute/composition/configurator-attribute-composition.module';
import { ConfiguratorConflictDescriptionModule } from '../conflict-description/configurator-conflict-description.module';
import { ConfiguratorConflictSuggestionModule } from '../conflict-suggestion/configurator-conflict-suggestion.module';
import { ConfiguratorGroupComponent } from './configurator-group.component';
import {ConfiguratorAttributeInputFieldModule} from "../attribute";
import {ConfiguratorAttributeCheckboxModule} from "../attribute/types/checkbox";

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    I18nModule,
    ConfiguratorAttributeNotSupportedModule,
    ConfiguratorAttributeFooterModule,
    ConfiguratorAttributeInputFieldModule,
    ConfiguratorAttributeNumericInputFieldModule,
    ConfiguratorAttributeCheckboxModule,


    ConfiguratorAttributeHeaderModule,
    ConfiguratorConflictDescriptionModule,
    ConfiguratorConflictSuggestionModule,
    ConfiguratorAttributeCompositionModule,
  ],
  providers: [

  ],
  declarations: [ConfiguratorGroupComponent],
  exports: [ConfiguratorGroupComponent],
})
export class ConfiguratorGroupModule {}
