/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { Converter, Occ } from '@lm-nx-frontend/lm-inf-core';
import {Permission} from "../../core/model";

@Injectable({
  providedIn: 'root',
})
export class OccPermissionNormalizer
  implements Converter<Occ.Permission, Permission>
{
  convert(source: Occ.Permission, target?: Permission): Permission {
    if (target === undefined) {
      target = { ...(source as any) } as Permission;
    }
    return target;
  }
}
