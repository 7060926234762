/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { OccConfig } from '@lm-nx-frontend/lm-inf-core';

export const defaultOccCustomerConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        /* eslint-disable max-len */
        customers:
          'users/${userId}/customers?fields=DEFAULT',
        customerAddresses:
          'users/${userId}/customers/${customerId}/addresses?fields=DEFAULT',
        customerAddress:
          'users/${userId}/customers/${customerId}/addresses/${customerAddressId}?fields=DEFAULT',
        customerCountries:
          'users/${userId}/customers/${customerId}/countries?fields=DEFAULT',
      },
    },
  },
};
