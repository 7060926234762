<ng-template [cxOutlet]="StorefrontOutlets.STOREFRONT" cxPageTemplateStyle>
  <ng-template cxOutlet="cx-header">
    <header
      id="cx-header"
      cxSkipLink="cx-header"
      [cxFocus]="{ disableMouseFocus: true }"
      [class.is-expanded]="isExpanded$ | async"
      (keydown.escape)="collapseMenu()"
      (click)="collapseMenuIfClickOutside($event)"
    >
      <cx-page-layout section="header"></cx-page-layout>
      <cx-page-layout section="navigation"></cx-page-layout>
    </header>
    <cx-page-slot position="BottomHeaderSlot"></cx-page-slot>
    <cx-global-message
      aria-atomic="true"
      aria-live="assertive"
    ></cx-global-message>
  </ng-template>

  <main cxSkipLink="cx-main" [cxFocus]="{ disableMouseFocus: true }">
    <router-outlet></router-outlet>
  </main>

  <ng-template cxOutlet="cx-footer">
    <footer cxSkipLink="cx-footer" [cxFocus]="{ disableMouseFocus: true }">
      <cx-page-layout section="footer"></cx-page-layout>
    </footer>
  </ng-template>
</ng-template>
