<div class="relative" style="z-index: 9999;">
  <mat-progress-bar
    mode="query"
    class="absolute"
    [mode]="navigationLoading ? 'query' : 'determinate'"
    [ngStyle]="{ visibility: navigationLoading ? 'visible' : 'hidden', position: 'absolute' }"
  ></mat-progress-bar>
</div>
<div>
  <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
  <div class="z-[500] relative lg:hidden" role="dialog" aria-modal="true" [class.hidden]="!sidebarVisible">
    <!--
      Off-canvas menu backdrop, show/hide based on off-canvas menu state.

      Entering: "transition-opacity ease-linear duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "transition-opacity ease-linear duration-300"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-600 bg-opacity-75 z-50"></div>

    <div class="fixed inset-0 z-50 flex">
      <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      -->
      <div class="relative flex w-full max-w-xs flex-1 flex-col bg-white border-right border-gray-200 pt-5 pb-4">
        <!--
          Close button, show/hide based on off-canvas menu state.

          Entering: "ease-in-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in-out duration-300"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="absolute top-0 right-0 -mr-12 pt-2">
          <button type="button" class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" (click)="closeSidebar()">
            <span class="sr-only">Close sidebar</span>
            <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div class="flex flex-shrink-0 items-center px-4">
          <img class="h-8 w-auto" src="https://lm-pumps.com/typo3conf/ext/skeleton/Resources/Public/img/svg/logo.svg" alt="Lotzer & Mühlenbruch Logo">
        </div>
        <div class="mt-5 h-0 flex-1 overflow-y-auto">
          <nav class="space-y-1 px-2">
            <!-- Current: "bg-blue-800 text-white", Default: "text-blue-100 hover:bg-blue-600" -->
            <a (click)="closeSidebar()" routerLink="/search" routerLinkActive="bg-gray-100 text-gray-900" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm font-medium">
              <svg routerLinkActive="text-gray-500" class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
              </svg>
              Products
            </a>

<!--            <a (click)="closeSidebar()" routerLinkActive="bg-gray-100 text-gray-900" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm font-medium">-->
<!--              <svg routerLinkActive="text-gray-500" class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">-->
<!--                <g stroke-linecap="round" stroke-linejoin="round" stroke-width="2" fill="none" stroke="currentColor" stroke-miterlimit="10"><path d="M15,15H9 c-3.314,0-6,2.686-6,6v1c0,0,3.125,1,9,1s9-1,9-1v-1C21,17.686,18.314,15,15,15z"></path>-->
<!--                  <path d="M7,6c0-2.761,2.239-5,5-5 s5,2.239,5,5s-2.239,6-5,6S7,8.761,7,6z" stroke="currentColor"></path></g>-->
<!--              </svg>-->
<!--              Customers-->
<!--            </a>-->

            <a (click)="closeSidebar()"
               *ngIf="currentUser?.capabilities?.inquiries?.list"
               routerLink="/inquiries" routerLinkActive="bg-gray-100 text-gray-900" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm font-medium">
              <svg routerLinkActive="text-gray-500" class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z" />
              </svg>
              Inquiries
            </a>

            <a (click)="closeSidebar()" [routerLink]="'/customers/' + 'me' + '/addresses'" routerLinkActive="bg-gray-100 text-gray-900" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm font-medium">
              <svg routerLinkActive="text-gray-500" class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0" fill="currentColor" viewBox="0 0 512 512" aria-hidden="true">
                <path d="M96 32C78.3 32 64 46.3 64 64V448c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H96zM32 64C32 28.7 60.7 0 96 0H384c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V64zM272 192a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-96 0a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zm32 128c-26.5 0-48 21.5-48 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-44.2 35.8-80 80-80h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-26.5-21.5-48-48-48H208zM512 80v64c0 8.8-7.2 16-16 16s-16-7.2-16-16V80c0-8.8 7.2-16 16-16s16 7.2 16 16zM496 192c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16s-16-7.2-16-16V208c0-8.8 7.2-16 16-16zm16 144v64c0 8.8-7.2 16-16 16s-16-7.2-16-16V336c0-8.8 7.2-16 16-16s16 7.2 16 16z"/>
              </svg>
              Addresses
            </a>

<!--            <a (click)="closeSidebar()" routerLinkActive="bg-gray-100 text-gray-900" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm font-medium">-->
<!--              <svg routerLinkActive="text-gray-500" class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0" fill="none" viewBox="0 0 24 24" aria-hidden="true">-->
<!--                <g fill="none"><path d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM13 20.01L4 11V4h7v-.01l9 9-7 7.02z" fill="currentColor"></path><path d="M6.5 8a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" fill="currentColor"></path></g>-->
<!--              </svg>-->


<!--              Offers-->
<!--            </a>-->

<!--            <a (click)="closeSidebar()" routerLinkActive="bg-gray-100 text-gray-900" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm font-medium">-->
<!--              <svg routerLinkActive="text-gray-500" class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0" fill="currentColor" viewBox="0 0 384 512" aria-hidden="true">-->
<!--                <path d="M352 448V192H240c-26.5 0-48-21.5-48-48V32H64C46.3 32 32 46.3 32 64V448c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32zm-.5-288c-.7-2.8-2.1-5.4-4.2-7.4L231.4 36.7c-2.1-2.1-4.6-3.5-7.4-4.2V144c0 8.8 7.2 16 16 16H351.5zM0 64C0 28.7 28.7 0 64 0H220.1c12.7 0 24.9 5.1 33.9 14.1L369.9 129.9c9 9 14.1 21.2 14.1 33.9V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM64 80c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm144 99.6v13.6c8.3 1.1 16.3 2.7 23.7 4.5c8.6 2 13.9 10.7 11.9 19.3s-10.7 13.9-19.3 11.9c-11.2-2.6-22.2-4.6-32.5-4.8c-8.7-.1-17.9 1.7-24.3 5.1c-6.1 3.2-7.4 6.3-7.4 9.4c0 1.5 .2 3.6 6.6 7c7 3.7 16.8 6.4 29.4 9.8l.5 .1c11.3 3 25.2 6.8 36.1 12.9c11.8 6.6 23.1 17.7 23.4 35.9c.3 18.9-10.6 31.7-23.6 38.9c-7.5 4.1-15.9 6.6-24.4 7.9v13.5c0 8.8-7.2 16-16 16s-16-7.2-16-16V414.4c-10.9-1.9-21.2-5-30.3-7.8l0 0 0 0c-2.1-.7-4.2-1.3-6.2-1.9c-8.5-2.5-13.3-11.4-10.8-19.9s11.4-13.3 19.9-10.8c2.4 .7 4.8 1.4 7.1 2.1c13.6 4.1 24.8 7.5 36.8 7.9c9.4 .3 18.5-1.6 24.4-4.9c5.4-3 7.2-6.2 7.1-10.4c0-2.4-.8-4.9-7-8.4c-6.9-3.9-16.8-6.7-29.2-10l-1.6-.4c-11-3-24.2-6.5-34.6-12.1c-11.5-6.2-23.3-16.9-23.4-35.1c-.1-19 11.9-31.3 24.5-37.9c7.3-3.8 15.4-6.2 23.4-7.5V243.6c0-8.8 7.2-16 16-16s16 7.2 16 16z"/>-->
<!--              </svg>-->

<!--              Invoices-->
<!--            </a>-->

<!--            <a (click)="closeSidebar()" routerLinkActive="bg-gray-100 text-gray-900" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm font-medium">-->
<!--              <svg routerLinkActive="text-gray-500" class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0" fill="currentColor" viewBox="0 0 640 512" aria-hidden="true">-->
<!--                <path d="M128 16c0 8.8 7.2 16 16 16h77.1l-5.5 32.7C193.1 68.7 176 88.3 176 112c0 26.5 21.5 48 48 48c18.6 0 34.8-10.6 42.8-26.2L448 158v50c0 8.8 7.2 16 16 16s16-7.2 16-16V144c0-8-5.9-14.8-13.9-15.9L271 102.1c-2.9-13.8-11.8-25.5-23.8-32.1l6.3-38H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zm72 96a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM448 288h32v32H448V288zm-32-8v40H352V280c0-13.3-10.7-24-24-24H280c-13.3 0-24 10.7-24 24v40H192V280c0-13.3-10.7-24-24-24H120c-13.3 0-24 10.7-24 24l0 40c-53 0-96 43-96 96s43 96 96 96H544c53 0 96-43 96-96s-43-96-96-96H512V280c0-13.3-10.7-24-24-24H440c-13.3 0-24 10.7-24 24zM96 352h24 48H280h48H440h48 56c35.3 0 64 28.7 64 64s-28.7 64-64 64H96c-35.3 0-64-28.7-64-64s28.7-64 64-64zm64-64v32H128V288h32zm128 32V288h32v32H288zM128 440a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm216-24a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zm168 24a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/>-->
<!--              </svg>-->

<!--              Fulfillment-->
<!--            </a>-->
          </nav>
        </div>
      </div>

      <div class="w-14 flex-shrink-0" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </div>
  </div>

  <!-- Static sidebar for desktop -->
  <div class="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
      <div class="flex flex-shrink-0 items-center px-4">
        <img class="h-8 w-auto" src="https://lm-pumps.com/typo3conf/ext/skeleton/Resources/Public/img/svg/logo.svg" alt="Lotzer & Mühlenbruch Logo">
      </div>
      <div class="mt-5 flex flex-grow flex-col">
        <nav class="flex-1 space-y-1 px-2 pb-4">
          <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
            <!-- Current: "text-gray-500", Default: "text-gray-400 group-hover:text-gray-500" -->
          <a routerLink="/search" routerLinkActive="bg-gray-100 text-gray-900" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm font-medium">
            <svg routerLinkActive="text-gray-500" class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
            </svg>
            Products
          </a>

<!--          <a routerLinkActive="bg-gray-100 text-gray-900" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm font-medium">-->
<!--            <svg routerLinkActive="text-gray-500" class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">-->
<!--              <g stroke-linecap="round" stroke-linejoin="round" stroke-width="2" fill="none" stroke="currentColor" stroke-miterlimit="10"><path d="M15,15H9 c-3.314,0-6,2.686-6,6v1c0,0,3.125,1,9,1s9-1,9-1v-1C21,17.686,18.314,15,15,15z"></path>-->
<!--                <path d="M7,6c0-2.761,2.239-5,5-5 s5,2.239,5,5s-2.239,6-5,6S7,8.761,7,6z" stroke="currentColor"></path></g>-->
<!--            </svg>-->
<!--            Customers-->
<!--          </a>-->

          <a
            routerLink="/inquiries"
            *ngIf="currentUser?.capabilities?.inquiries?.list"
            routerLinkActive="bg-gray-100 text-gray-900" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm font-medium">
            <svg routerLinkActive="text-gray-500" class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z" />
            </svg>
            Inquiries
          </a>

          <a [routerLink]="'/customers/' + 'me' + '/addresses'" routerLinkActive="bg-gray-100 text-gray-900" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm font-medium">
            <svg routerLinkActive="text-gray-500" class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0" fill="currentColor" viewBox="0 0 512 512" aria-hidden="true">
              <path d="M96 32C78.3 32 64 46.3 64 64V448c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H96zM32 64C32 28.7 60.7 0 96 0H384c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V64zM272 192a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-96 0a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zm32 128c-26.5 0-48 21.5-48 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-44.2 35.8-80 80-80h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-26.5-21.5-48-48-48H208zM512 80v64c0 8.8-7.2 16-16 16s-16-7.2-16-16V80c0-8.8 7.2-16 16-16s16 7.2 16 16zM496 192c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16s-16-7.2-16-16V208c0-8.8 7.2-16 16-16zm16 144v64c0 8.8-7.2 16-16 16s-16-7.2-16-16V336c0-8.8 7.2-16 16-16s16 7.2 16 16z"/>
            </svg>
            Addresses
          </a>

<!--          <a routerLinkActive="bg-gray-100 text-gray-900" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm font-medium">-->
<!--            <svg routerLinkActive="text-gray-500" class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0" fill="none" viewBox="0 0 24 24" aria-hidden="true">-->
<!--              <g fill="none"><path d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM13 20.01L4 11V4h7v-.01l9 9-7 7.02z" fill="currentColor"></path><path d="M6.5 8a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" fill="currentColor"></path></g>-->
<!--            </svg>-->


<!--            Offers-->
<!--          </a>-->

<!--          <a routerLinkActive="bg-gray-100 text-gray-900" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm font-medium">-->
<!--            <svg routerLinkActive="text-gray-500" class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0" fill="currentColor" viewBox="0 0 384 512" aria-hidden="true">-->
<!--              <path d="M352 448V192H240c-26.5 0-48-21.5-48-48V32H64C46.3 32 32 46.3 32 64V448c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32zm-.5-288c-.7-2.8-2.1-5.4-4.2-7.4L231.4 36.7c-2.1-2.1-4.6-3.5-7.4-4.2V144c0 8.8 7.2 16 16 16H351.5zM0 64C0 28.7 28.7 0 64 0H220.1c12.7 0 24.9 5.1 33.9 14.1L369.9 129.9c9 9 14.1 21.2 14.1 33.9V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM64 80c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm144 99.6v13.6c8.3 1.1 16.3 2.7 23.7 4.5c8.6 2 13.9 10.7 11.9 19.3s-10.7 13.9-19.3 11.9c-11.2-2.6-22.2-4.6-32.5-4.8c-8.7-.1-17.9 1.7-24.3 5.1c-6.1 3.2-7.4 6.3-7.4 9.4c0 1.5 .2 3.6 6.6 7c7 3.7 16.8 6.4 29.4 9.8l.5 .1c11.3 3 25.2 6.8 36.1 12.9c11.8 6.6 23.1 17.7 23.4 35.9c.3 18.9-10.6 31.7-23.6 38.9c-7.5 4.1-15.9 6.6-24.4 7.9v13.5c0 8.8-7.2 16-16 16s-16-7.2-16-16V414.4c-10.9-1.9-21.2-5-30.3-7.8l0 0 0 0c-2.1-.7-4.2-1.3-6.2-1.9c-8.5-2.5-13.3-11.4-10.8-19.9s11.4-13.3 19.9-10.8c2.4 .7 4.8 1.4 7.1 2.1c13.6 4.1 24.8 7.5 36.8 7.9c9.4 .3 18.5-1.6 24.4-4.9c5.4-3 7.2-6.2 7.1-10.4c0-2.4-.8-4.9-7-8.4c-6.9-3.9-16.8-6.7-29.2-10l-1.6-.4c-11-3-24.2-6.5-34.6-12.1c-11.5-6.2-23.3-16.9-23.4-35.1c-.1-19 11.9-31.3 24.5-37.9c7.3-3.8 15.4-6.2 23.4-7.5V243.6c0-8.8 7.2-16 16-16s16 7.2 16 16z"/>-->
<!--            </svg>-->

<!--            Invoices-->
<!--          </a>-->

<!--          <a routerLinkActive="bg-gray-100 text-gray-900" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm font-medium">-->
<!--            <svg routerLinkActive="text-gray-500" class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6 flex-shrink-0" fill="currentColor" viewBox="0 0 640 512" aria-hidden="true">-->
<!--              <path d="M128 16c0 8.8 7.2 16 16 16h77.1l-5.5 32.7C193.1 68.7 176 88.3 176 112c0 26.5 21.5 48 48 48c18.6 0 34.8-10.6 42.8-26.2L448 158v50c0 8.8 7.2 16 16 16s16-7.2 16-16V144c0-8-5.9-14.8-13.9-15.9L271 102.1c-2.9-13.8-11.8-25.5-23.8-32.1l6.3-38H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zm72 96a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM448 288h32v32H448V288zm-32-8v40H352V280c0-13.3-10.7-24-24-24H280c-13.3 0-24 10.7-24 24v40H192V280c0-13.3-10.7-24-24-24H120c-13.3 0-24 10.7-24 24l0 40c-53 0-96 43-96 96s43 96 96 96H544c53 0 96-43 96-96s-43-96-96-96H512V280c0-13.3-10.7-24-24-24H440c-13.3 0-24 10.7-24 24zM96 352h24 48H280h48H440h48 56c35.3 0 64 28.7 64 64s-28.7 64-64 64H96c-35.3 0-64-28.7-64-64s28.7-64 64-64zm64-64v32H128V288h32zm128 32V288h32v32H288zM128 440a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm216-24a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zm168 24a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/>-->
<!--            </svg>-->

<!--            Fulfillment-->
<!--          </a>-->
        </nav>
      </div>
    </div>
  </div>
  <div class="flex flex-1 flex-col lg:pl-64 flex flex-col h-[100vh] max-h-[100vh]">
    <div class="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
      <button type="button" class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 lg:hidden" (click)="openSidebar()">
        <span class="sr-only">Open sidebar</span>
        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
        </svg>
      </button>
      <div class="flex flex-1 justify-between px-4">
        <div class="flex items-center ml-auto">
          <button routerLink="/cart" type="button" class="rounded-full relative bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mr-4">
            <span
              *ngIf="cartItemCount$ | async; let cartItemCount"
              class="absolute inline-flex items-center rounded-full w-5 h-5 -top-3 -right-3 bg-gray-100 text-center justify-center p-0.5 text-xs font-medium text-gray-800"
            >
              {{ cartItemCount }}
            </span>

            <span class="sr-only">View cart</span>

            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49A.996.996 0 0 0 20.01 4H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path>
            </svg>
          </button>

          <button type="button" class="hidden rounded-full relative bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
            <span class="absolute inline-flex items-center rounded-full w-5 h-5 -top-3 -right-3 bg-blue-100 text-center justify-center p-0.5 text-xs font-medium text-blue-800">1</span>

            <span class="sr-only">View notifications</span>

            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
            </svg>
          </button>

          <!-- Profile dropdown -->
          <div class="relative ml-4" (lmNxFrontendClickOutside)="onUserMenuClose()">
            <div>
              <button type="button" (click)="onUserMenuShow()" class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                <span class="sr-only">Open user menu</span>
                <img class="h-8 w-8 rounded-full" src="/assets/avatar.jpg" alt="">
              </button>
            </div>

            <!--
              Dropdown menu, show/hide based on menu state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div [class.hidden]="!userMenuVisible" class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
              <!-- Active: "bg-gray-100", Not Active: "" -->
              <a [href]="logoutHref" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">Sign out</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <main class="overflow-y-hidden h-full">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
