import {Inject, Injectable} from '@angular/core';
import {Session} from "./model/session";
import {BehaviorSubject, catchError, map, Observable, of} from "rxjs";
import jwt_decode from "jwt-decode";

import {HttpClient} from "@angular/common/http";
import {AuthConfig} from "./auth-config";
import {AUTH_CONFIG} from "./auth-config.injection-token";

const environment = {
  auth: {
    userPoolId: 'eu-central-1_zPbvixDA3',
    clientId: '4uqdoak8qgeh8v5t0d3lshdiaf'
  }
}

@Injectable({
  providedIn: 'root'
})
export class Oauth2Service {
  session$ = new BehaviorSubject<Session | null>(null);
  loggedIn$ = this.session$.pipe(map(session => session !== null && this.isSessionValid(session)))

  refreshInterval: null|number = null;

  constructor(
    private readonly http: HttpClient,
    @Inject(AUTH_CONFIG) private readonly config: AuthConfig
  ) {}

  authCallback(code: string): Observable<Session|null> {
    return this.http.post<void>('https://' + this.config.host +'/oauth2/token',
      new URLSearchParams({
        client_id: this.config.clientId|| '',
        code_verifier: window.sessionStorage.getItem('code_verifier') ?? '',
        grant_type: "authorization_code",
        code: code,
        redirect_uri: window.location.protocol + '//' + window.location.host+ '/oauth2/authorize',
      }),
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    ).pipe(
      map(({ access_token: accessToken, expires_in: expiresIn, id_token: idToken, refresh_token: refreshToken }: any) => {
        const rawIdToken = jwt_decode<Record<string, any>>(idToken)
        const rawAccessToken = jwt_decode<Record<string, any>>(accessToken)

        const email = rawIdToken?.['email'] ?? ''
        const expiresAtUnix = rawAccessToken?.['exp'] ?? 0
        const expiresAt = new Date(expiresAtUnix * 1000)

        const session = {
          email,
          expiresAt,
          accessToken: {
            token: accessToken,
            data: rawAccessToken
          },
          idToken: {
            token: idToken,
            data: rawIdToken
          },
        }

        this.session$.next(session);

        // start interval
        if (this.refreshInterval) {
          clearInterval(this.refreshInterval)
        }

        this.refreshInterval = setInterval(() => {

          this.http.post<void>('https://' + this.config.host +'/oauth2/token',
            new URLSearchParams({
              client_id: this.config.clientId|| '',
              grant_type: "refresh_token",
              refresh_token: refreshToken,
            }),
            {
              responseType: 'json',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
          ).subscribe(({ access_token: accessToken, id_token: idToken }: any) => {
            const rawIdToken = jwt_decode<Record<string, any>>(idToken)
            const rawAccessToken = jwt_decode<Record<string, any>>(accessToken)

            const email = rawIdToken?.['email'] ?? ''
            const expiresAtUnix = rawAccessToken?.['exp'] ?? 0
            const expiresAt = new Date(expiresAtUnix * 1000)

            const session = {
              email,
              expiresAt,
              accessToken: {
                token: accessToken,
                data: rawAccessToken
              },
              idToken: {
                token: idToken,
                data: rawIdToken
              },
            }

            this.session$.next(session);
          })

        }, (expiresIn * 1000) * 0.5)

        return session
      }),
    )
  }

  isSessionValid(session: Session): boolean {
    return true
  }

  logout(): string {
    return 'https://' + this.config.host +'/logout?client_id=' + (this.config.clientId|| '') + '&logout_uri=' + encodeURIComponent(window.location.protocol + '//' + window.location.host);
  }

  async initiateAuth(): Promise<void> {
    const redirectUri = window.location.protocol + '//' + window.location.host
    const codeVerifier = this.generateRandomString(24)
    const codeChallenge = await this.generateCodeChallenge(codeVerifier)

    const scopes = [
      // 'email',
      'openid',
      // 'aws.cognito.signin.user.admin',
    ]

    window.sessionStorage.setItem("code_verifier", codeVerifier);

    location.href = 'https://' + this.config.host +'/oauth2/authorize?scope=' +  encodeURIComponent(scopes.join(' ')) +  '&response_type=code&client_id=' + (this.config.clientId|| '') +
      '&code_challenge_method=S256&code_challenge=' + encodeURIComponent(codeChallenge) +'&redirect_uri=' + encodeURIComponent(redirectUri + '/oauth2/authorize');
  }

  generateRandomString(length: number) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  async generateCodeChallenge(codeVerifier: string) {
    const digest = await crypto.subtle.digest("SHA-256",
      new TextEncoder().encode(codeVerifier));

    return btoa(String.fromCharCode(...new Uint8Array(digest)))
      .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
  }
}


