/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { OccEndpoint } from '@lm-nx-frontend/lm-inf-core';

export interface UserProfileOccEndpoints {
  /**
   * Titles used for user's personal info.
   */
  titles?: string | OccEndpoint;

  /**
   * Register a new user.
   */
  userRegister?: string | OccEndpoint;

  /**
   * Request an email to reset the password
   */
  userForgotPassword?: string | OccEndpoint;

  /**
   * Reset the password once the email is received.
   */
  userResetPassword?: string | OccEndpoint;

  /**
   * Update the user id with which the user authenticates.
   */
  userUpdateLoginId?: string | OccEndpoint;

  /**
   * Update the user's password
   */
  userUpdatePassword?: string | OccEndpoint;

  /**
   * Update the user's profile
   */
  userUpdateProfile?: string | OccEndpoint;

  /**
   * Close user account
   */
  userCloseAccount?: string | OccEndpoint;
}
declare module '@lm-nx-frontend/lm-inf-core' {
  interface OccEndpoints extends UserProfileOccEndpoints {}
}
