import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {StoreModule} from "@ngrx/store";
import {cartReducer} from "./store/cart.reducer";
import {cartFeatureKey, initialCartState} from "./store/cart.state";
import {EffectsModule} from "@ngrx/effects";
import {CartEffects} from "./store/cart.effects";
import {CartBaseModule, CartBaseOccModule} from "@lm-nx-frontend/lm-inf-cart";
import {ApiModule} from "@lm-nx-frontend/lm-inf-core";

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forFeature(cartFeatureKey, cartReducer, { initialState: initialCartState }),
    EffectsModule.forFeature([ CartEffects ]),

    ApiModule,
    CartBaseModule,
  ],
})
export class InfCartModule {

}
