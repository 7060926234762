<ng-container *ngIf="product$ | async as product">
  <div class="container">
    <h2>{{ 'productDetails.specification' | cxTranslate }}</h2>
    <table *ngFor="let class of product?.classifications">
      <th>
        <h2>{{ class.name }}</h2>
      </th>
      <tr *ngFor="let feature of class.features">
        <td>{{ feature.name }}</td>
        <td>
          <ul>
            <li *ngFor="let featureValue of feature?.featureValues">
              {{ featureValue?.value }}
              <span
                *ngIf="
                  feature.featureUnit &&
                  feature.featureUnit.symbol &&
                  feature.featureUnit.symbol.length > 0 &&
                  feature.featureUnit?.unitType !== '300'
                "
              >
                {{ feature.featureUnit?.symbol }}
              </span>
            </li>
          </ul>
        </td>
      </tr>
    </table>
  </div>
</ng-container>
