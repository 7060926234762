import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {CartItem, CartState, WithCartState} from "./cart.state";
import {
  addCartItem, clearCart, getCartSummary, listCartItems,
  removeCartItem, removeCartItemSuccess,
  setCartItemQuantity, updateCartShippingAddress
} from "./cart.actions";
import {
  selectCartStateAllItems, selectCartStateAllItemsPriceMap, selectCartStateAllItemsQuantityMap,
  selectCartStateItemCount,
  selectCartStateItemEntities, selectCartStateLoading,
  selectCartStateTotalPrice, selectCartSummaryShippingAddress
} from "./cart.selectors";
import {UserIdService} from "@lm-nx-frontend/lm-inf-core";
import {take} from "rxjs";
import {CustomerAddress} from "@lm-nx-frontend/lm-inf-customer-root";
import {UserFacade} from "@lm-nx-frontend/lm-inf-user-root";
import {Product} from "@lm-nx-frontend/lm-inf-product-root";
import * as xksuid from "xksuid";
import {Configurator} from "@lm-nx-frontend/lm-inf-product-configurator-root";

@Injectable({
  providedIn: 'root'
})
export class CartFacade {
  itemCount$ = this.store.select(
    selectCartStateItemCount
  )

  loading$ = this.store.select(
    selectCartStateLoading,
  )

  items$ = this.store.select(
    selectCartStateAllItems
  )

  totalPrice$ = this.store.select(
    selectCartStateTotalPrice
  )

  itemTotalPriceMap$ = this.store.select(
    selectCartStateAllItemsPriceMap
  )

  itemEntities$ = this.store.select(
    selectCartStateItemEntities
  )
  itemQuantityMap$ = this.store.select(
    selectCartStateAllItemsQuantityMap
  )
  shippingAddress$ = this.store.select(
    selectCartSummaryShippingAddress,
  )
  constructor(
    private readonly store: Store<WithCartState>,
    private readonly userIDService: UserIdService,
    private readonly userFacade: UserFacade,
  ) {}

  clearCart(): void {
    this.store.dispatch(clearCart())
  }

  createCartItemFromProduct(product: Product|any, quantity = 1): CartItem {
    let id: string;
    if (product?.id) {
      id = 'p-' + product.id
    } else {
      id = 'c-' + xksuid.generate()
    }

    return {
      id,
      internalID: product.id,
      quantity: product?.quantity ?? 1,
      name: product.lmpNo ?? 'Custom',
      description: product?.lmpDescription ?? product.description,
      unitPrice: product.unitPrice,
      expectedQuantity: (+product?.available) ?? -1,
      unitPriceDiscounted: product?.unitPriceDiscounted ?? null,
      unitPriceDiscountedPercentage: product?.discountApplied?.percentage,
      total: (+product?.unitPrice * quantity).toString(),
      totalDiscounted: product?.unitPriceDiscounted ? (+product.unitPriceDiscounted * quantity).toString() : null,
    }
  }

  createCartItemFromConfiguration(product: Product, config: Configurator.Configuration, quantity = 1): CartItem {
    let id: string;
    if (config?.id) {
      id = 'p-' + config.productID
    } else {
      id = 'c-' + xksuid.generate()
    }

    id += '@' + config.id

    return {
      id,
      configuration: config,
      product: product,
      internalID: config.productID,
      quantity,
      name: product.lmpNo,
      description: product.lmpDescription,
      unitPrice: product.unitPrice,
      expectedQuantity: (+product?.available) ?? -1,
      unitPriceDiscounted: product?.unitPriceDiscounted,
      unitPriceDiscountedPercentage: product?.discountApplied?.percentage,
      total: (+product?.unitPrice * quantity).toString(),
      totalDiscounted: (+product?.unitPriceDiscounted * quantity).toString(),
    }
  }

  addCartItem(cartItem: CartItem): void {
    this.userIDService.getUserId().pipe(
      take(1)
    ).subscribe((userID) => {
      this.store.dispatch(addCartItem({
        cartItem,
        id: cartItem.id,
        cartID: 'ACTIVE',
        userID,
      }))
    })
  }
  removeCartItem(id: string): void {
    this.userIDService.getUserId().pipe(
      take(1)
    ).subscribe((userID) => {
      this.store.dispatch(removeCartItem({
        id,
        cartID: 'ACTIVE',
        userID
      }))
    })
  }
  setCartItemQuantity(id: string, quantity: number): void {
    this.userIDService.getUserId().pipe(
      take(1)
    ).subscribe((userID) => {
      if (quantity <= 0) {
        this.store.dispatch(removeCartItem({
          cartID: 'ACTIVE',
          id,
          userID,
        }))
        return;
      }

      this.store.dispatch(setCartItemQuantity({
        cartID: 'ACTIVE',
        id,
        quantity,
        userID,
      }))
    })

  }
  getCartSummary(): void {
    this.userIDService.getUserId().pipe(
      take(1)
    ).subscribe((userID) => {
      this.store.dispatch(getCartSummary({
        cartID: 'ACTIVE',
        userID: userID,
      }))
    })
  }
  updateShippingAddress(address: CustomerAddress|null): void {
    this.userFacade.currentUser$.pipe(
      take(1)
    ).subscribe((user) => {
      this.store.dispatch(updateCartShippingAddress({
        address: address,
        userID: user.id,
        cartID: 'ACTIVE'
      }))
    })
  }

  getCartItems(): void {
    this.userIDService.getUserId().pipe(
      take(1)
    ).subscribe((userID) => {
      this.store.dispatch(listCartItems({
        cartID: 'ACTIVE',
        userID
      }))
    })
  }
}
