/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import {UserAccountCoreModule} from "./core/user-account-core.module";
import {UserAccountOccModule} from "./occ/user-account-occ.module";
import {UserAccountComponentsModule} from "./components/user-account-component.module";

@NgModule({
  imports: [
    UserAccountCoreModule,
    UserAccountOccModule,
    UserAccountComponentsModule,
  ],
})
export class UserAccountModule {}
