/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
// Imported for side effects (module augmentation)
import '@lm-nx-frontend/lm-inf-ui';
import {Config} from "@lm-nx-frontend/lm-inf-core";

@Injectable({
  providedIn: 'root',
  useExisting: Config,
})
export abstract class CartConfig {
  cart?: {
    selectiveCart?: {
      enabled?: boolean;
    };
    validation?: {
      enabled?: boolean;
    };
  };
}

declare module '@lm-nx-frontend/lm-inf-core' {
  interface Config extends CartConfig {}
}
