import {Action} from "@ngrx/store";
import {CategoryTreeState, defaultCategoryTreeState} from "./tree.state";
import {entityLoaderReducer} from "@lm-nx-frontend/lm-inf-core";
import {CategoryTree} from "../../model/category-tree";
import {
  CATEGORY_PRODUCT_TREE,
  CategoryTreeAction, LOAD_CATEGORY_TREE_FAILURE,
  LOAD_CATEGORY_TREE_SUCCESS,
  LoadCategoryTreeSuccess
} from "./tree.actions";

export function treeReducer(
  state: CategoryTreeState = defaultCategoryTreeState,
  action: CategoryTreeAction,
): CategoryTreeState {
  return entityLoaderReducer<CategoryTree>(CATEGORY_PRODUCT_TREE, (
    state: CategoryTree,
    action: Action,
  ): CategoryTree => {
    // eslint-disable-next-line no-empty
    switch (action.type) {
      case LOAD_CATEGORY_TREE_SUCCESS:
        return (action as LoadCategoryTreeSuccess).payload.tree
      case LOAD_CATEGORY_TREE_FAILURE:
        return null
    }

    return state;
  })(state, action);
}
