<ng-container *ngIf="components$ | async as components">
  <div
    role="region"
    tabindex="-1"
    [attr.aria-label]="ariaLabel | cxTranslate"
    class="container"
  >
    <ng-container *ngFor="let component of components; let i = index">
      <ng-container *ngIf="component">
        <button
          [class.active]="i === activeTabNum"
          (click)="select(i, $event)"
          [attr.aria-expanded]="i === activeTabNum"
        >
          {{
            component.title | cxTranslate: { param: tabTitleParams[i] | async }
          }}

          <span class="accordion-icon" aria-hidden="true"></span>
        </button>

        <div
          [class.active]="i === activeTabNum"
          class="cx-tab-paragraph-content"
          tabindex="0"
          role="presentation"
        >
          <ng-template [cxOutlet]="component.flexType" [cxOutletContext]="{}">
            <ng-container
              [cxComponentWrapper]="component"
              (cxComponentRef)="tabCompLoaded($event)"
            ></ng-container>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
