<div class="cx-section">
  <ng-container
    *ngIf="
      (addresses$ | async)?.length &&
      !(showAddAddressForm || showEditAddressForm)
    "
  >
    <div class="row">
      <div class="col-md-6">
        <button
          class="btn btn-block btn-secondary"
          (click)="addAddressButtonHandle()"
        >
          {{ 'addressBook.addNewAddress' | cxTranslate }}
        </button>
      </div>
    </div>

    <div
      class="row cx-address-deck"
      *ngIf="!(addressesStateLoading$ | async); else loading"
    >
      <div
        role="status"
        [attr.aria-label]="'common.loaded' | cxTranslate"
      ></div>
      <div
        *ngFor="let address of addresses$ | async; let i = index"
        class="col-md-6 cx-address-card"
      >
        <cx-card
          [index]="i"
          [border]="true"
          [fitToContainer]="true"
          [content]="getCardContent(address) | async"
          (editCard)="editAddressButtonHandle(address)"
          (setDefaultCard)="setAddressAsDefault(address)"
          (deleteCard)="setEdit(address.id ?? '')"
          [editMode]="address.id === editCard"
          (cancelCard)="cancelCard()"
        ></cx-card>
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      (!(addresses$ | async)?.length || showAddAddressForm) &&
      !showEditAddressForm
    "
  >
    <section>
      <h2 class="cx-section-msg">
        {{ 'addressBook.addNewDeliveryAddress' | cxTranslate }}
      </h2>
      <cx-address-form
        class="cx-form"
        [showTitleCode]="true"
        [showCancelBtn]="!((addresses$ | async)?.length === 0)"
        actionBtnLabel="{{ 'addressBook.addAddress' | cxTranslate }}"
        cancelBtnLabel="{{ 'addressBook.backToAddressList' | cxTranslate }}"
        [setAsDefaultField]="!((addresses$ | async)?.length === 0)"
        (submitAddress)="addAddressSubmit($event)"
        (backToAddress)="addAddressCancel()"
        (cancelCard)="cancelCard()"
      ></cx-address-form>
    </section>
  </ng-container>

  <ng-container *ngIf="showEditAddressForm">
    <section>
      <h2 class="cx-section-msg">
        {{ 'addressBook.editDeliveryAddress' | cxTranslate }}
      </h2>
      <cx-address-form
        [showTitleCode]="true"
        actionBtnLabel="{{ 'addressBook.updateAddress' | cxTranslate }}"
        cancelBtnLabel="{{ 'addressBook.backToAddressList' | cxTranslate }}"
        [addressData]="currentAddress"
        (submitAddress)="editAddressSubmit($event)"
        (backToAddress)="editAddressCancel()"
      ></cx-address-form>
    </section>
  </ng-container>
</div>

<ng-template #loading>
  <div class="col-md-12 cx-address-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
