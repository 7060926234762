import {CategoryTree} from "../../model/category-tree";
import {EntityLoaderState} from "@lm-nx-frontend/lm-inf-core";

export interface CategoryTreeState extends EntityLoaderState<CategoryTree> {

}

export const defaultCategoryTreeState: CategoryTreeState = {
  entities: {},
}
