import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {WithCustomerState} from "../state";
import {filter, Observable, race, take, tap, throwError} from "rxjs";


import {CustomerAddress} from "../../model/customer";
import {Actions, ofType} from "@ngrx/effects";
import {map} from "rxjs/operators";
import {selectCustomerCountryList, selectCustomerCountryLoading} from "./country.selectors";
import {LoadCustomerCountries} from "./country.actions";

@Injectable({
  providedIn: 'root'
})
export class CountryFacade {
  countries$ = this.store.select(
    selectCustomerCountryList,
  ).pipe(
  )

  loading$ = this.store.select(
    selectCustomerCountryLoading,
  ).pipe(
  )

  constructor(
    protected readonly store: Store<WithCustomerState>,
    protected readonly actions: Actions,
  ) {}

  loadCountries(
    userId: string,
    customerId: string,
  ): void {
    this.store.dispatch(
      new LoadCustomerCountries({
        userId,
        customerId,
      })
    )
  }
}
