<ng-container *ngIf="mainImage$ | async as main">
  <cx-media [container]="main"></cx-media>
</ng-container>

<ng-container *ngIf="thumbs$ | async as thumbs">
  <cx-carousel
    *ngIf="thumbs.length"
    class="thumbs"
    [items]="thumbs"
    itemWidth="120px"
    [hideIndicators]="false"
    [template]="thumb"
  ></cx-carousel>
</ng-container>

<ng-template #thumb let-item="item">
  <cx-media
    [container]="item.container"
    tabindex="0"
    (focus)="openImage(item.container)"
    [class.is-active]="isActive(item.container) | async"
    format="product"
  >
  </cx-media>
</ng-template>
