import { Injectable } from '@angular/core';
import {
  EntitiesModel,
  ItemsModel, ItemsSearchConfig,
  OccEndpointsService,
  ProductActions,
  SearchConfig
} from "@lm-nx-frontend/lm-inf-core";
import {Observable} from "rxjs";
import {Inquiry} from "../model/inquiry";
import {HttpClient} from "@angular/common/http";
import {InquiryMessage} from "../model/inquiry-message";

@Injectable({
  providedIn: 'root'
})
export class InquiryMessagesConnector {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly occEndpointsService: OccEndpointsService,
  ) { }

  get(userId: string, inquiryId: string, inquiryMessageId: string): Observable<InquiryMessage> {
    const url = this.occEndpointsService.buildUrl('inquiryMessage', {
      urlParams: {
        userId,
        inquiryId,
        inquiryMessageId,
      },
    })

    return this.httpClient.get<InquiryMessage>(url);
  }

  create(userId: string, inquiryId: string, inquiry: Partial<InquiryMessage>): Observable<InquiryMessage> {
    const url = this.occEndpointsService.buildUrl('createInquiryMessage', {
      urlParams: {
        userId,
        inquiryId,
      },
    })

    return this.httpClient.post<InquiryMessage>(url, inquiry);
  }

  getList(
    userId: string,
    inquiryId: string,
    params?: SearchConfig
  ): Observable<ItemsModel<InquiryMessage>> {
    const url = this.occEndpointsService.buildUrl('inquiryMessages', {
      urlParams: {
        userId,
        inquiryId,
      },
      queryParams: params
    })

    return this.httpClient.get<ItemsModel<InquiryMessage>>(url);
  }
}
