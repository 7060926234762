/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import {Configurator} from "../../../../../lm-inf-product-configurator-root/src/lib/model/configurator.model";
import {ICON_TYPE} from "@lm-nx-frontend/lm-inf-ui";

@Component({
  selector: 'cx-configurator-conflict-description',
  templateUrl: './configurator-conflict-description.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfiguratorConflictDescriptionComponent {
  @Input() currentGroup: Configurator.Group;

  groupType = Configurator.GroupType;
  iconTypes = ICON_TYPE;

  @HostBinding('tabindex') tabindex = '0';

  constructor() {
    // Intentional empty constructor
  }

  /**
   * Verifies whether the  conflict description should be displayed for the current group.
   *
   * @param {Configurator.Group} group - Current group
   * @return {boolean} - 'True' if the conflict description should be displayed, otherwise 'false'.
   */
  displayConflictDescription(group: Configurator.Group): boolean {
    return group.groupType === Configurator.GroupType.CONFLICT_GROUP;
  }
}
