/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { userRegistration } from './user-registration.i18n';

export const en = {
  userRegistration,
};
