/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import '@lm-nx-frontend/lm-inf-ui';

declare module '@lm-nx-frontend/lm-inf-ui' {
  const enum LAUNCH_CALLER {
    CLOSE_ACCOUNT = 'CLOSE_ACCOUNT',
  }
}
