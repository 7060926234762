import {HttpErrorModel, StateUtils} from "@lm-nx-frontend/lm-inf-core";
import {CustomerAddress} from "../../model/customer";
import {CustomerCountry} from "../../model/customerCountry";

export const LOAD_COUNTRIES = '[Customer] Load Countries';
export const LOAD_COUNTRIES_SUCCESS = '[Customer] Load Countries Success';
export const LOAD_COUNTRIES_FAILURE = '[Customer] Load Countries Failure';
export const COUNTRY_DATA = '[Customer] Country Data';

// action types
export type CustomerCountryAction =
  | LoadCustomerCountries
  | LoadCustomerCountriesSuccess
  | LoadCustomerCountriesFailure
;

export interface LoadCustomerCountriesPayload {
  userId: string
  customerId: string
}

export class LoadCustomerCountries extends StateUtils.EntityLoadAction {
  override readonly type = LOAD_COUNTRIES;

  constructor(
    public payload: LoadCustomerCountriesPayload,
  ) {
    super(COUNTRY_DATA, payload.customerId);
  }
}

export interface LoadCustomerCountriesSuccessPayload {
  userId: string
  customerId: string
  countries: CustomerCountry[]
}

export class LoadCustomerCountriesSuccess extends StateUtils.EntitySuccessAction {
  override readonly type = LOAD_COUNTRIES_SUCCESS;

  constructor(
    public override payload: LoadCustomerCountriesSuccessPayload,
  ) {
    super(COUNTRY_DATA, payload.customerId);
  }
}

export interface LoadCustomerCountriesFailurePayload {
  userId: string
  customerId: string
}

export class LoadCustomerCountriesFailure extends StateUtils.EntityFailAction {
  override readonly type = LOAD_COUNTRIES_FAILURE;

  constructor(
    public payload: LoadCustomerCountriesFailurePayload,
    public error: HttpErrorModel,
  ) {
    super(COUNTRY_DATA, payload.customerId);
  }
}




