import { Route } from '@angular/router';
import {AuthGuard} from "@lm-nx-frontend/lm-inf-core";
// import {SessionResolver} from "@lm-nx-frontend/oauth2";

export const appRoutes: Route[] = [
  {
     path: 'oauth2/authorize',
     loadChildren: () => import('@lm-nx-frontend/oauth2-authorize-callback').then(m => m.Oauth2AuthorizeCallbackModule)
  },
  {
    path: 'error',
    loadChildren: () => import('@lm-nx-frontend/lm-inf-error').then(m => m.LmInfErrorModule)
  },
  {
    path: 'search',
    canActivate: [AuthGuard],
    loadChildren: () => import('@lm-nx-frontend/th-article-list').then(m => m.ThArticleListModule)
  },
  {
    path: 'cart',
    canActivate: [AuthGuard],
    loadChildren: () => import('@lm-nx-frontend/lm-inf-cart-routing').then(m => m.LmInfCartRoutingModule)
  },
  {
    path: 'configure',
    canActivate: [AuthGuard],
    loadChildren: () => import('@lm-nx-frontend/lm-inf-product-configurator').then(m => m.LmInfProductConfiguratorViewModule)
  },
  {
    path: 'inquiries',
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import('@lm-nx-frontend/inf-inquiry-list').then(m => m.InfInquiryListModule)
      },
      {
        path: ':inquiry',
        canActivate: [AuthGuard],
        loadChildren: () => import('@lm-nx-frontend/inf-inquiry-show').then(m => m.InfInquiryShowModule)
      },
    ]
  },
  {
    path: 'customers',
    loadChildren: () => import('@lm-nx-frontend/lm-inf-customer-routing').then(m => m.LmInfCustomerRoutingModule)
  },
  {
    path: '**',
    redirectTo: '/search'
  },
];
