import {Action} from "@ngrx/store";
import {CustomerCountryState, defaultCustomerCountryState} from "./country.state";
import {
  LOAD_COUNTRIES,
  LOAD_COUNTRIES_FAILURE,
  LOAD_COUNTRIES_SUCCESS,
  LoadCustomerCountriesSuccess
} from "./country.actions";


export function countryReducer(
  state: CustomerCountryState = defaultCustomerCountryState,
  action: Action,
): CustomerCountryState {
  switch (action.type) {
    case LOAD_COUNTRIES: {
      return {
        ...state,
        loading: state.loading+1,
      };
    }

    case LOAD_COUNTRIES_SUCCESS: {
      const { payload } = (action as LoadCustomerCountriesSuccess);
      const entities = Object.fromEntries(
        payload.countries.map((a) => [a.code, { value: a }])
      );
      const ids = payload.countries.map((a) => a.code);

      return {
        ...state,
        entities,
        ids,
        loading: state.loading-1,
      };
    }

    case LOAD_COUNTRIES_FAILURE:
      return {
        ...state,
        loading: state.loading-1,
      }

  }

  return state;
}
