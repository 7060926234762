/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { Command, CommandService } from '@lm-nx-frontend/lm-inf-core';
import { Observable } from 'rxjs';
import { UserRegistrationConnector } from '../connectors/user-registration.connector';
import {UserRegistrationFacade} from "../../root/facade";
import {OrganizationUserRegistration} from "../../root/model";

@Injectable()
export class UserRegistrationService implements UserRegistrationFacade {
  protected registerOrganizationUserCommand: Command<
    {
      userData: OrganizationUserRegistration;
    },
    OrganizationUserRegistration
  > = this.command.create((payload) =>
    this.userRegistrationConnector.registerUser(payload.userData)
  );

  constructor(
    protected userRegistrationConnector: UserRegistrationConnector,
    protected command: CommandService
  ) {}

  /**
   * Register a new org user.
   *
   * @param userData
   */
  registerUser(
    userData: OrganizationUserRegistration
  ): Observable<OrganizationUserRegistration> {
    return this.registerOrganizationUserCommand.execute({ userData });
  }
}
