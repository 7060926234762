<a
  *ngIf="hasItem"
  class="hide-focus-border"
  [routerLink]="{ cxRoute: route, params: routeModel } | cxUrl"
  [tabIndex]="tabIndex"
>
  <button
    *ngIf="isSwitchable"
    class="button tree-item-toggle"
    type="button"
    [attr.aria-label]="
      expanded
        ? ('orgUnit.tree.collapse' | cxTranslate)
        : ('orgUnit.tree.expand' | cxTranslate)
    "
    (click)="toggleItem($event)"
  >
    <cx-icon [type]="expanded ? 'CARET_DOWN' : 'CARET_RIGHT'"></cx-icon>
  </button>
  <span class="text" title="{{ combinedName }}">{{ combinedName }}</span>
</a>
