import {EntityState} from "@ngrx/entity";
import {User} from "../../models/user";
import {EntityLoaderState} from "@lm-nx-frontend/lm-inf-core";

export interface UserUserState extends EntityLoaderState<User> {
  currentId: null|string
}

export const defaultUserUserState: UserUserState = {
  currentId: null,
  entities: {}
}
