<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <label>
    <span class="label-content">{{
      'updateProfileForm.title' | cxTranslate
    }}</span>
    <ng-select
      [clearable]="false"
      [searchable]="false"
      formControlName="titleCode"
      id="title-select"
      [cxNgSelectA11y]="{
        ariaLabel: 'updateProfileForm.title' | cxTranslate
      }"
    >
      <ng-option *ngFor="let title of titles$ | async" [value]="title.code">{{
        title.name
      }}</ng-option>
    </ng-select>
  </label>
  <label>
    <span class="label-content">{{
      'updateProfileForm.firstName.label' | cxTranslate
    }}</span>
    <input
      required="true"
      type="text"
      class="form-control"
      name="firstName"
      placeholder="{{
        'updateProfileForm.firstName.placeholder' | cxTranslate
      }}"
      formControlName="firstName"
    />
    <cx-form-errors [control]="form.get('firstName')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{
      'updateProfileForm.lastName.label' | cxTranslate
    }}</span>
    <input
      required="true"
      type="text"
      class="form-control"
      name="lastName"
      placeholder="{{ 'updateProfileForm.lastName.placeholder' | cxTranslate }}"
      formControlName="lastName"
    />
    <cx-form-errors [control]="form.get('lastName')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{
      'updateProfileForm.customerId' | cxTranslate
    }}</span>
    <input
      required="true"
      type="text"
      class="form-control"
      name="customerId"
      formControlName="customerId"
      readonly
    />
    <cx-form-errors [control]="form.get('lastName')"></cx-form-errors>
  </label>

  <a
    class="btn btn-block btn-secondary"
    [routerLink]="{ cxRoute: 'home' } | cxUrl"
  >
    {{ 'common.cancel' | cxTranslate }}
  </a>

  <button class="btn btn-block btn-primary" [disabled]="form.disabled">
    {{ 'common.save' | cxTranslate }}
  </button>
</form>
