import {Action} from "@ngrx/store";
import {entityLoaderReducer, ItemsModel} from "@lm-nx-frontend/lm-inf-core";
import {Product} from "@lm-nx-frontend/lm-inf-product-root";
import {
  LOAD_PRODUCT_PRODUCT_FAILURE,
  LOAD_PRODUCT_PRODUCT_SUCCESS,
  PRODUCT_PRODUCT_DATA,
  ProductProductAction
} from "./product.actions";

function productCoreReducer(
  state: Product|null = null,
  action: ProductProductAction,
): Product|null {
  switch (action.type) {
    case LOAD_PRODUCT_PRODUCT_SUCCESS: {
      return action.payload.product;
    }
    case LOAD_PRODUCT_PRODUCT_FAILURE: {
     return null;
    }
  }

  return state;
}

export const productReducer = entityLoaderReducer<Product|null, any>(
  PRODUCT_PRODUCT_DATA,
  productCoreReducer,
);
