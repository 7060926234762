/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@lm-nx-frontend/lm-inf-core';
import { KeyboardFocusModule } from '@lm-nx-frontend/lm-inf-ui';
import { CardModule } from '../../../../shared/card/card.module';
import { UnitUserRolesFormComponent } from './unit-user-roles.component';

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    FormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    ReactiveFormsModule,
    KeyboardFocusModule,
  ],
  declarations: [UnitUserRolesFormComponent],
})
export class UnitUserRolesModule {}
