/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Action } from '@ngrx/store';
import { Configurator } from '../../../../../lm-inf-product-configurator-root/src/lib/model/configurator.model';
import { CONFIGURATOR_DATA } from '../configurator-state';
import {CommonConfigurator} from "../../common/core/model";
import {StateUtils} from "@lm-nx-frontend/lm-inf-core";
import {CartItem} from "@lm-nx-frontend/inf-cart";
import {OrderEntry} from "@lm-nx-frontend/lm-inf-cart";
// import {MULTI_CART_DATA} from "@lm-nx-frontend/inf-cart";

export const MULTI_CART_DATA =
  '[Configurator] Cart Data';

export const READ_CART_ENTRY_CONFIGURATION =
  '[Configurator] Read Cart Entry Configuration';
export const READ_CART_ENTRY_CONFIGURATION_SUCCESS =
  '[Configurator] Read Cart Entry Configuration Success';
export const READ_CART_ENTRY_CONFIGURATION_FAIL =
  '[Configurator] Read Cart Entry Configuration Fail';
export const READ_ORDER_ENTRY_CONFIGURATION =
  '[Configurator] Read Order Entry Configuration';
export const READ_ORDER_ENTRY_CONFIGURATION_SUCCESS =
  '[Configurator] Read Order Entry Configuration Success';
export const READ_ORDER_ENTRY_CONFIGURATION_FAIL =
  '[Configurator] Read Order Entry Configuration Fail';

export const ADD_TO_CART = '[Configurator] Add to cart';
export const ADD_TO_CART_SUCCESS = '[Configurator] Add to cart Success';
export const ADD_TO_CART_FAIL = '[Configurator] Add to cart Fail';
export const ADD_TO_CART_FINALIZED = '[Configurator] Add to cart finalized';
export const UPDATE_CART_ENTRY = '[Configurator] Update cart entry';
export const UPDATE_CART_ENTRY_SUCCESS =
  '[Configurator] Update cart entry success';

export const ADD_NEXT_OWNER = '[Configurator] Add next owner';
export const SET_NEXT_OWNER_CART_ENTRY =
  '[Configurator] Set next owner cart entry';

export const REMOVE_CART_BOUND_CONFIGURATIONS =
  '[Configurator] Remove cart bound configurations';

export class ReadCartEntryConfiguration extends StateUtils.EntityLoadAction {
  readonly type = READ_CART_ENTRY_CONFIGURATION;
  constructor(
    public payload: CommonConfigurator.ReadConfigurationFromCartEntryParameters
  ) {
    super(CONFIGURATOR_DATA, payload.owner.key);
  }
}

export class ReadCartEntryConfigurationSuccess extends StateUtils.EntitySuccessAction {
  override readonly type = READ_CART_ENTRY_CONFIGURATION_SUCCESS;
  constructor(public override payload: Configurator.Configuration) {
    super(CONFIGURATOR_DATA, payload.owner.key);
  }
}

export class ReadCartEntryConfigurationFail extends StateUtils.EntityFailAction {
  override readonly type = READ_CART_ENTRY_CONFIGURATION_FAIL;
  constructor(public payload: { ownerKey: string; error: any }) {
    super(CONFIGURATOR_DATA, payload.ownerKey, payload.error);
  }
}

export class ReadOrderEntryConfiguration extends StateUtils.EntityLoadAction {
  override readonly type = READ_ORDER_ENTRY_CONFIGURATION;
  constructor(
    public payload: CommonConfigurator.ReadConfigurationFromOrderEntryParameters
  ) {
    super(CONFIGURATOR_DATA, payload.owner.key);
  }
}

export class ReadOrderEntryConfigurationSuccess extends StateUtils.EntitySuccessAction {
  override readonly type = READ_ORDER_ENTRY_CONFIGURATION_SUCCESS;
  constructor(public override payload: Configurator.Configuration) {
    super(CONFIGURATOR_DATA, payload.owner.key);
  }
}

export class ReadOrderEntryConfigurationFail extends StateUtils.EntityFailAction {
  override readonly type = READ_ORDER_ENTRY_CONFIGURATION_FAIL;
  constructor(public payload: { ownerKey: string; error: any }) {
    super(CONFIGURATOR_DATA, payload.ownerKey, payload.error);
  }
}

export class AddToCart extends StateUtils.EntityProcessesIncrementAction {
  override readonly type = ADD_TO_CART;
  constructor(public payload: Configurator.AddToCartParameters) {
    super(CONFIGURATOR_DATA, payload.owner.key);
  }
}

export class AddToCartSuccess extends StateUtils.EntityProcessesDecrementAction {
  override readonly type = ADD_TO_CART_SUCCESS;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      productCode: string;
      quantity: number;
      pickupStore?: string;
      deliveryModeChanged?: boolean;
      entry?: CartItem;
      quantityAdded?: number;
      statusCode?: string;
      statusMessage?: string;
      owner: CommonConfigurator.Owner;
    }
  ) {
    super(CONFIGURATOR_DATA, payload.owner.key);
  }
}

export class AddToCartFail extends StateUtils.EntityProcessesDecrementAction {
  override readonly type = ADD_TO_CART_FAIL;
  constructor(public payload: {
    error: any;
    userId: string;
    cartId: string;
    productCode: string;
    quantity: number;
    pickupStore?: string;
    owner: CommonConfigurator.Owner;
  }) {
    super(CONFIGURATOR_DATA, payload.owner.key);
  }
}

export class AddToCartFinalized implements Action {
  readonly type = ADD_TO_CART_FINALIZED;
  constructor(public payload: { cartItem: CartItem }) {}
}

export class UpdateCartEntry extends StateUtils.EntityProcessesIncrementAction {
  override readonly type = UPDATE_CART_ENTRY;
  constructor(
    public payload: Configurator.UpdateConfigurationForCartEntryParameters
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class AddNextOwner implements Action {
  readonly type = ADD_NEXT_OWNER;
  constructor(public payload: { ownerKey: string; cartEntryNo: string }) {}
}

export class RemoveCartBoundConfigurations implements Action {
  readonly type = REMOVE_CART_BOUND_CONFIGURATIONS;
  constructor() {
    // Intentional Empty Constructor
  }
}

export class SetNextOwnerCartEntry extends StateUtils.EntitySuccessAction {
  override readonly type = SET_NEXT_OWNER_CART_ENTRY;

  constructor(
    public override payload: {
      configuration: Configurator.Configuration;
      cartEntryNo: string;
    }
  ) {
    super(CONFIGURATOR_DATA, payload.configuration.owner.key);
  }
}

export type ConfiguratorCartAction =
  | AddNextOwner
  | SetNextOwnerCartEntry
  | ReadCartEntryConfiguration
  | ReadCartEntryConfigurationSuccess
  | ReadCartEntryConfigurationFail
  | ReadOrderEntryConfiguration
  | ReadOrderEntryConfigurationSuccess
  | ReadOrderEntryConfigurationFail
  | RemoveCartBoundConfigurations
  | UpdateCartEntry;
