<ng-container *ngIf="data$ | async as data">
  <cx-generic-link
    [url]="routerLink"
    [target]="getTarget(data)"
  >
    <p class="headline" *ngIf="data?.properties?.headline" [innerHTML]="data?.properties?.headline"></p>
    <cx-media [container]="getImage(data)"></cx-media>
    <p class="content" *ngIf="data?.properties?.content" [innerHTML]="data?.properties?.content"></p>
  </cx-generic-link>
</ng-container>
