import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ItemsModel, OccEndpointsService, SearchConfig} from "@lm-nx-frontend/lm-inf-core";
import {Observable} from "rxjs";
import {ProductPart} from "../models/part";

@Injectable({
  providedIn: 'root'
})
export class ProductPartsDataService {
  constructor(
    protected readonly endpointsService: OccEndpointsService,
    protected readonly httpClient: HttpClient
  ) { }

  search(
    userId: string,
    productId: string,
    query: string | undefined,
    searchConfig?: SearchConfig
  ): Observable<ItemsModel<ProductPart>> {
    const url = this.endpointsService.buildUrl('productParts', {
      urlParams: {
        userId,
        productId,
      },
      queryParams: {
        ...searchConfig,
        query,
      },
    }, undefined, false)

    return this.httpClient.get<ItemsModel<ProductPart>>(url);
  }

}
