import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {WithUserState} from "../state";
import {selectUserUserCurrentUser} from "./user.selectors";
import {Observable, tap} from "rxjs";
import {User} from "../../models/user";

@Injectable({
  providedIn: 'root'
})
export class UserFacade {
  currentUser$: Observable<User> = this.store.select(
    selectUserUserCurrentUser,
  ).pipe(
  )

  constructor(
    protected readonly store: Store<WithUserState>
  ) {
  }
}
