/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import { provideDefaultConfig } from '@lm-nx-frontend/lm-inf-core';
import { defaultOccUserAccountConfig } from './adapters/config/default-occ-user-account-endpoint.config';
import { OccUserAccountAdapter } from './adapters/occ-user-account.adapter';
import {UserAccountAdapter} from "../core/connectors";

@NgModule({
  providers: [
    provideDefaultConfig(defaultOccUserAccountConfig),
    { provide: UserAccountAdapter, useClass: OccUserAccountAdapter },
  ],
})
export class UserAccountOccModule {}
