/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import {UserProfileCoreModule} from "./core/user-profile-core.module";
import {UserProfileOccModule} from "./occ/user-profile-occ.module";
import {UserProfileComponentsModule} from "./components/user-profile-components.module";

@NgModule({
  imports: [
    UserProfileCoreModule,
    UserProfileOccModule,
    UserProfileComponentsModule,
  ],
})
export class UserProfileModule {}
