import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Observable, tap} from "rxjs";
import {WithProductState} from "../state";
import {selectProductLoading, selectProductRouter, selectProductRouterId} from "./product.selectors";
import {Product} from "../../models/product";
import {LoadProductProduct} from "./product.actions";

@Injectable({
  providedIn: 'root'
})
export class ProductProductFacade {
  productRouterId$: Observable<string> = this.store.select(
    selectProductRouterId,
  ).pipe(
  )

  productRouter$: Observable<Product|null> = this.store.select(
    selectProductRouter,
  ).pipe(
  )
  productRouterLoading$: Observable<boolean> = this.store.select(
    selectProductLoading,
  ).pipe(
  )

  constructor(
    protected readonly store: Store<WithProductState>
  ) {
  }

  getProduct(productId: string): void {
    this.store.dispatch(
      new LoadProductProduct({
        userId: '',
        productId,
      })
    )
  }
}
