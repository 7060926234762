import {createFeatureSelector, createSelector} from "@ngrx/store";
import {cartFeatureKey, cartItemEntityAdapter, CartState} from "./cart.state";

export const selectCartState = createFeatureSelector<CartState>(cartFeatureKey)

export const selectCartStateItems = createSelector(
  selectCartState,
  ({ items }) => items
)

export const selectCartSummary = createSelector(
  selectCartState,
  (state) => state.summary
)

export const selectCartSummaryShippingAddress = createSelector(
  selectCartSummary,
  (state) => state?.shippingAddress
)

const {
  selectEntities,
  selectAll
} = cartItemEntityAdapter.getSelectors()

export const selectCartStateAllItems = createSelector(
  selectCartStateItems,
  selectAll
)


export const selectCartStateItemCount = createSelector(
  selectCartStateAllItems,
  (items) => items.reduce((prev, curr) => prev+curr.quantity, 0)
)

export const selectCartStateAllItemsPrice = createSelector(
  selectCartStateAllItems,
  (items) => {
    return items.map(({id, quantity, unitPrice, unitPriceDiscounted}) => {
      let linePrice = null;
      if (unitPriceDiscounted) {
        linePrice = (+unitPriceDiscounted) * quantity
      } else if (unitPrice) {
        linePrice = (+unitPrice) * quantity
      }

      return [id, linePrice]
    });
  }
)


export const selectCartStateAllItemsQuantity = createSelector(
  selectCartStateAllItems,
  (items) => {
    return items.map(({id, quantity}) => {
      return [id, quantity]
    });
  }
)

export const selectCartStateAllItemsQuantityMap = createSelector(
  selectCartStateAllItemsQuantity,
  (items) => {
    return Object.fromEntries(items)
  }
)

export const selectCartStateAllItemsPriceMap = createSelector(
  selectCartStateAllItemsPrice,
  (items) => {
    return Object.fromEntries(items)
  }
)

export const selectCartStateTotalPrice = createSelector(
  selectCartStateAllItemsPrice,
  (items) => {
    return items.reduce((prev, [, price]) => {
      if (prev === null || price === null) {
        return null
      }

      return prev + (price as number)
    }, 0 as number|null)
  }
)

export const selectCartStateItemEntities = createSelector(
  selectCartStateItems,
  selectEntities
)

export const selectCartStateLoading = createSelector(
  selectCartState,
  ({ loading }) => loading > 0
)
