import {createSelector} from "@ngrx/store";
import {selectProductState} from "../product.selectors";
import {ProductState} from "../state";
import {ProductPartsState} from "./parts.state";
import {entityValueSelector} from "@lm-nx-frontend/lm-inf-core";
import {selectProductRouterId} from "../product/product.selectors";


export const selectProductParts = createSelector(
  selectProductState,
  (user: ProductState) => {
    return user.parts
  }
)

export const selectProductPartsValueFromRouter = createSelector(
  selectProductParts,
  selectProductRouterId,
  (state, routerId: string) => {
    return entityValueSelector(state, routerId)
  }
)
