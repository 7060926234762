<div class="register">
  <p class="cx-section-title">
    {{ 'loginForm.dontHaveAccount' | cxTranslate }}
  </p>

  <ng-container *ngIf="!loginAsGuest">
    <a
      [routerLink]="{ cxRoute: 'register' } | cxUrl"
      class="btn btn-block btn-secondary btn-register"
      >{{ 'loginForm.register' | cxTranslate }}</a
    >
  </ng-container>

  <ng-container *ngIf="loginAsGuest">
    <a
      [routerLink]="{ cxRoute: 'checkoutLogin' } | cxUrl"
      class="btn btn-block btn-secondary btn-guest"
      >{{ 'loginForm.guestCheckout' | cxTranslate }}</a
    >
  </ng-container>
</div>
