/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { Config } from '@lm-nx-frontend/lm-inf-core';

@Injectable({
  providedIn: 'root',
  useExisting: Config,
})
export abstract class SkipLinkConfig {
  skipLinks?: SkipLink[];
}

export abstract class SkipLink {
  key: string;
  i18nKey: string;
  target?: HTMLElement;
  position?: SkipLinkScrollPosition;
}

export enum SkipLinkScrollPosition {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
}

declare module '@lm-nx-frontend/lm-inf-core' {
  interface Config extends SkipLinkConfig {}
}
