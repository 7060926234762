/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';

import { TableService } from '@lm-nx-frontend/lm-inf-ui';
import { Observable } from 'rxjs';
import { OrganizationTableType } from '../../shared/organization.model';
import { SubListService } from '../../shared/sub-list/sub-list.service';
import {B2BUserService, UserGroupService} from "../../../core/services";
import {OrganizationItemStatus, UserGroup} from "../../../core/model";
import {EntitiesModel, PaginationModel} from "@lm-nx-frontend/lm-inf-core";

@Injectable({
  providedIn: 'root',
})
export class UserUserGroupListService extends SubListService<UserGroup> {
  protected tableType = OrganizationTableType.USER_USER_GROUPS;
  protected _domainType = OrganizationTableType.USER_GROUP;

  constructor(
    protected tableService: TableService,
    protected userService: B2BUserService,
    protected userGroupService: UserGroupService
  ) {
    super(tableService);
  }

  protected load(
    pagination: PaginationModel,
    code: string
  ): Observable<EntitiesModel<UserGroup> | undefined> {
    return this.userService.getUserGroups(code, pagination);
  }

  /**
   * @override
   * Assign user group to the user.
   */
  assign(
    userCode: string,
    userGroupCode: string
  ): Observable<OrganizationItemStatus<UserGroup>> {
    this.userService.assignUserGroup(userCode, userGroupCode);
    return this.userGroupService.getLoadingStatus(userGroupCode);
  }

  /**
   * @override
   * Unassign the user group from the user.
   */
  unassign(
    userCode: string,
    userGroupCode: string
  ): Observable<OrganizationItemStatus<UserGroup>> {
    this.userService.unassignUserGroup(userCode, userGroupCode);
    return this.userGroupService.getLoadingStatus(userGroupCode);
  }
}
