import { NgModule } from '@angular/core';
import { UserProfileComponentsModule } from './components/public_api';
import { UserProfileCoreModule } from './core/public_api';
import { UserProfileOccModule } from './occ/public_api';

@NgModule({
  imports: [
    UserProfileCoreModule,
    UserProfileOccModule,
    UserProfileComponentsModule,
  ],
})
export class UserProfileModule {}
