import { NgModule } from '@angular/core';
import {ApiModule, provideDefaultConfig} from "@lm-nx-frontend/lm-inf-core";
import {defaultOccCustomerConfig} from "./model/default-occ-customer-config";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {AddressEffects} from "./store/address/address.effect";
import {addressReducer} from "./store/address/address.reducer";
import {countryReducer} from "./store/country/country.reducer";
import {CountryEffects} from "./store/country/country.effect";

@NgModule({
  imports: [
    ApiModule,

    StoreModule.forFeature('customer', {
      'address': addressReducer,
      'country': countryReducer,
    }),
    EffectsModule.forFeature([
      AddressEffects,
      CountryEffects,
    ]),
  ],
  providers: [
    provideDefaultConfig(defaultOccCustomerConfig),
  ]
})
export class LmInfCustomerRootModule {}
