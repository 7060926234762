<div class="cx-summary-heading">
  {{ 'orderCost.orderSummary' | cxTranslate }}
</div>

<div class="cx-summary-partials" *ngIf="cart">
  <div class="cx-summary-row">
    <div class="col-6 cx-summary-label">
      {{ 'orderCost.subtotal' | cxTranslate }}
    </div>
    <div class="col-6 cx-summary-amount">
      {{ cart.subTotal?.formattedValue }}
    </div>
  </div>
  <div class="cx-summary-row">
    <div class="col-6 cx-summary-label">
      {{
        (cart.deliveryCost?.formattedValue
          ? 'orderCost.shipping'
          : 'orderCost.estimatedShipping'
        ) | cxTranslate
      }}
    </div>
    <div class="col-6 cx-summary-amount">
      {{
        cart.deliveryCost?.formattedValue
          ? cart.deliveryCost?.formattedValue
          : ('orderCost.toBeDetermined' | cxTranslate)
      }}
    </div>
  </div>
  <div class="cx-summary-row" *ngIf="cart.net; else cartWithoutNet">
    <div class="col-6 cx-summary-label">
      {{ 'orderCost.salesTax' | cxTranslate }}
    </div>
    <div class="col-6 cx-summary-amount">
      {{ cart.totalTax?.formattedValue }}
    </div>
  </div>
  <div class="cx-summary-row cx-summary-total">
    <div class="col-6 cx-summary-label">
      {{ 'orderCost.total' | cxTranslate }}
    </div>
    <div class="col-6 cx-summary-amount">
      {{ cart.totalPriceWithTax?.formattedValue }}
    </div>
  </div>
  <div class="cx-summary-row" *ngIf="$any(cart?.totalDiscounts?.value) > 0">
    {{ 'orderCost.discount' | cxTranslate }}
    {{ cart.totalDiscounts?.formattedValue }}
  </div>
  <ng-template #cartWithoutNet>
    <div class="cx-summary-row">
      {{
        cart.totalPriceWithTax?.value !== cart.totalPrice?.value
          ? ('orderCost.grossTax' | cxTranslate)
          : ('orderCost.grossIncludeTax' | cxTranslate)
      }}
      {{ cart.totalTax?.formattedValue }}.
    </div>
  </ng-template>
</div>

<cx-applied-coupons
  [vouchers]="$any(cart?.appliedVouchers)"
  [isReadOnly]="true"
></cx-applied-coupons>
