/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@lm-nx-frontend/lm-inf-core';
import { SubListModule } from '../../../../shared/sub-list/sub-list.module';
import { LinkCellComponent } from './link-cell.component';
import { UnitAddressListComponent } from './unit-address-list.component';

@NgModule({
  imports: [CommonModule, I18nModule, RouterModule, UrlModule, SubListModule],
  declarations: [UnitAddressListComponent, LinkCellComponent],
})
export class UnitAddressListModule {}
