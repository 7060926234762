<cx-carousel
  *ngIf="items$ | async as items"
  [title]="title$ | async"
  [items]="items"
  [template]="carouselItem"
>
</cx-carousel>

<ng-template #carouselItem let-item="item">
  <a tabindex="0" [routerLink]="{ cxRoute: 'product', params: item } | cxUrl">
    <cx-media [container]="item.images?.PRIMARY" format="product"></cx-media>
    <h4>{{ item.name }}</h4>
    <div class="price">{{ item.price?.formattedValue }}</div>
  </a>
</ng-template>
