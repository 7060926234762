import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {WithCustomerState} from "../state";
import {filter, Observable, race, take, tap, throwError} from "rxjs";
import {
  selectCustomerAddressList,
  selectCustomerAddressLoading,
  selectSelectedCustomerAddress
} from "./address.selectors";
import {
  CREATE_ADDRESS, CREATE_ADDRESS_FAILURE,
  CREATE_ADDRESS_SUCCESS,
  CreateCustomerAddress, CreateCustomerAddressFailure,
  CreateCustomerAddressSuccess,
  EditCustomerAddress,
  LoadCustomerAddress,
  LoadCustomerAddressSuccess
} from "./address.actions";
import {CustomerAddress} from "../../model/customer";
import {Actions, ofType} from "@ngrx/effects";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AddressFacade {
  addresses$ = this.store.select(
    selectCustomerAddressList,
  ).pipe(
  )

  selectedAddress$ = this.store.select(
    selectSelectedCustomerAddress,
  ).pipe(
  )


  loading$ = this.store.select(
    selectCustomerAddressLoading,
  ).pipe(
  )

  constructor(
    protected readonly store: Store<WithCustomerState>,
    protected readonly actions: Actions,
  ) {}

  loadAddresses(
    userId: string,
    customerId: string,
  ): void {
    this.store.dispatch(
      new LoadCustomerAddress({
        userId,
        customerId,
      })
    )
  }

  createAddressSuccess(
    userId: string,
    customerId: string,
    address: CustomerAddress
  ): void {
    this.store.dispatch(
      new CreateCustomerAddressSuccess({
        userId,
        customerId,
        address
      })
    )
  }

  createAddress(
    userId: string,
    customerId: string,
    customerAddress: CustomerAddress
  ): void {
    this.store.dispatch(
      new CreateCustomerAddress({
        userId,
        customerId,
        address: customerAddress,
      })
    )
  }

  waitForAddress(): Observable<CustomerAddress> {
    const successEvent$: Observable<CreateCustomerAddressSuccess> = this.actions.pipe(
      ofType(CREATE_ADDRESS_SUCCESS),
    )
    const failureEvent$: Observable<CreateCustomerAddressFailure> = this.actions.pipe(
      ofType(CREATE_ADDRESS_FAILURE),
    )

    return race(
      successEvent$,
      failureEvent$
    ).pipe(
      map((event: any) => {
        if (event instanceof CreateCustomerAddressFailure) {
          return throwError(() => event.error)
        }

        return event.payload.address;
      }),
      take(1),
    )
  }

  editAddress(
    userId: string,
    customerId: string,
    customerAddress: CustomerAddress
  ): void {
    this.store.dispatch(
      new EditCustomerAddress({
        userId,
        customerId,
        customerAddress
      })
    )
  }
}
