<cx-org-card
  *ngIf="model$ | async as model"
  i18nRoot="orgPurchaseLimit.details"
  [cxFocus]="{ refreshFocus: model }"
>
  <a
    actions
    class="link edit"
    [class.disabled]="!model.active || (isInEditMode$ | async)"
    [routerLink]="{ cxRoute: 'orgPurchaseLimitEdit', params: model } | cxUrl"
  >
    {{ 'organization.edit' | cxTranslate }}
  </a>

  <cx-org-toggle-status
    actions
    i18nRoot="orgPurchaseLimit"
    [disabled]="false"
  ></cx-org-toggle-status>

  <cx-org-disable-info
    info
    i18nRoot="orgPurchaseLimit"
    [displayInfoConfig]="{ disabledEnable: false }"
  >
  </cx-org-disable-info>

  <section main class="details" cxOrgItemExists>
    <div class="property">
      <label>{{ 'orgPurchaseLimit.code' | cxTranslate }}</label>
      <span class="value">
        {{ model.code }}
      </span>
    </div>

    <div class="property">
      <label>{{ 'orgPurchaseLimit.active' | cxTranslate }}</label>
      <span class="value" [class.is-active]="model.active">
        {{
          (model.active ? 'organization.enabled' : 'organization.disabled')
            | cxTranslate
        }}
      </span>
    </div>

    <div class="property">
      <label>{{
        'orgPurchaseLimit.orderApprovalPermissionType' | cxTranslate
      }}</label>
      <span class="value">
        {{ model.orderApprovalPermissionType?.name }}
      </span>
    </div>

    <div class="property" *ngIf="model.threshold || model.threshold === 0">
      <label>{{ 'orgPurchaseLimit.threshold' | cxTranslate }}</label>
      <span class="value">
        {{ model.threshold }} {{ model.currency?.symbol }}
      </span>
    </div>

    <div class="property" *ngIf="model.periodRange">
      <label>{{ 'orgPurchaseLimit.periodRange' | cxTranslate }}</label>
      <span class="value">
        {{ model.periodRange }}
      </span>
    </div>

    <div class="property">
      <label>{{ 'orgPurchaseLimit.unit' | cxTranslate }}</label>
      <a
        *ngIf="model.code"
        class="value"
        [routerLink]="
          {
            cxRoute: 'orgUnitDetails',
            params: model.orgUnit
          } | cxUrl
        "
      >
        {{ model.orgUnit?.name }}
      </a>
    </div>
  </section>
</cx-org-card>
