import { createAction, props } from '@ngrx/store';
import {CartItem, CartSummary} from "./cart.state";
import {CustomerAddress} from "@lm-nx-frontend/lm-inf-customer-root";

export const clearCart = createAction(
  '[Cart] Clear'
);

export const addCartItem = createAction(
  '[Cart] Add Item',
  props<{ cartID: string, userID: string, id: string, cartItem: CartItem|null }>()
);
export const addCartItemSuccess = createAction(
  '[Cart] Add Item Success',
  props<{ cartID: string, userID: string, id: string, cartItem: CartItem|null }>()
);

export const addCartItemFailure = createAction(
  '[Cart] Add Item Failure',
  props<{ cartID: string, userID: string, id: string, errorMessage: string }>()
);

export const removeCartItem = createAction(
  '[Cart] Remove Item',
  props<{ cartID: string, userID: string, id: string }>()
);
export const removeCartItemSuccess = createAction(
  '[Cart] Remove Item Success',
  props<{ cartID: string, userID: string, id: string }>()
);

export const removeCartItemFailure = createAction(
  '[Cart] Remove Item Failure',
  props<{ cartID: string, userID: string, id: string, errorMessage: string }>()
);

export const setCartItemQuantity = createAction(
  '[Cart] Set Cart Item Quantity',
  props<{ cartID: string, userID: string, id: string, quantity: number }>()
);
export const setCartItemQuantitySuccess = createAction(
  '[Cart] Set Cart Item Quantity Success',
  props<{ cartID: string, userID: string, id: string, quantity: number, cartItem: CartItem|null }>()
);

export const setCartItemQuantityFailure = createAction(
  '[Cart] Set Cart Item Quantity Failure',
  props<{ cartID: string, userID: string, id: string, errorMessage: string }>()
);


export const listCartItems = createAction(
  '[Cart] List Items',
  props<{ cartID: string, userID: string }>()
);
export const listCartItemsSuccess = createAction(
  '[Cart] List Items Success',
  props<{ cartID: string, userID: string, cartItems: CartItem[] }>()
);

export const listCartItemsFailure = createAction(
  '[Cart] List Items Failure',
  props<{ cartID: string, userID: string, errorMessage: string }>()
);

export const getCartSummary = createAction(
  '[Cart] Get Summary',
  props<{ cartID: string, userID: string }>()
);
export const getCartSummarySuccess = createAction(
  '[Cart] Get Summary Success',
  props<{ cartID: string, userID: string, summary: CartSummary }>()
);

export const getCartSummaryFailure = createAction(
  '[Cart] Get Summary Failure',
  props<{ cartID: string, userID: string, errorMessage: string }>()
);

export const updateCartShippingAddress = createAction(
  '[Cart] Update Shipping Address',
  props<{ cartID: string, userID: string, address: CustomerAddress|null }>()
);
export const updateCartShippingAddressSuccess = createAction(
  '[Cart] Update Shipping Address Success',
  props<{ cartID: string, userID: string, summary: CartSummary }>()
);

export const updateCartShippingAddressFailure = createAction(
  '[Cart] Update Shipping Address Failure',
  props<{ cartID: string, userID: string, errorMessage: string }>()
);
