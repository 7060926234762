/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { OccEndpoint } from '@lm-nx-frontend/lm-inf-core';

export interface InquiryOccEndpoints {
  createInquiry?: string | OccEndpoint;
  inquiries?: string | OccEndpoint;
  inquiry?: string | OccEndpoint;
  inquiryMessages?: string | OccEndpoint;
}

declare module '@lm-nx-frontend/lm-inf-core' {
  interface OccEndpoints extends InquiryOccEndpoints {}
}
