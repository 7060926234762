import { NgModule } from '@angular/core';
import {ApiModule, provideDefaultConfig} from "../../../lm-inf-core/src";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {TreeEffects} from "./store/tree/tree.effect";
import {defaultOccCategoryConfig} from "./model/default-occ-category-config";
import {treeReducer} from "./store/tree/tree.reducer";
import {CategoryTreeAction} from "./store/tree/tree.actions";
import {CategoryState} from "./store/state";



@NgModule({
  imports: [
    ApiModule,

    StoreModule.forFeature<CategoryState, CategoryTreeAction>('category', {
      'tree': treeReducer,
    }),
    EffectsModule.forFeature([
      TreeEffects,
    ]),
  ],
  providers: [
    provideDefaultConfig(defaultOccCategoryConfig),
  ]
})
export class LmInfCategoryRootModule { }
