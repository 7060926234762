import {HttpErrorModel, ItemsModel, StateUtils} from "@lm-nx-frontend/lm-inf-core";
import {Product} from "@lm-nx-frontend/lm-inf-product-root";
export const PRODUCT_PRODUCT_DATA = '[Product] Product Data';

// action types
export type ProductProductAction =
  | LoadProductProduct
  | LoadProductProductSuccess
  | LoadProductProductFailure
;

export const LOAD_PRODUCT_PRODUCT = '[Product] Load Product Product';

export interface LoadProductProductPayload {
  userId: string
  productId: string
}

export class LoadProductProduct extends StateUtils.EntityLoadAction {
  override readonly type = LOAD_PRODUCT_PRODUCT;

  constructor(
    public payload: LoadProductProductPayload,
  ) {
    super(PRODUCT_PRODUCT_DATA, payload.productId);
  }
}

export const LOAD_PRODUCT_PRODUCT_FAILURE = '[Product] Load Product Product Failure';

export interface LoadProductProductFailurePayload {
  userId: string
  productId: string
}

export class LoadProductProductFailure extends StateUtils.EntityFailAction {
  override readonly type = LOAD_PRODUCT_PRODUCT_FAILURE;

  constructor(
    public payload: LoadProductProductFailurePayload,
    public error: HttpErrorModel,
  ) {
    super(PRODUCT_PRODUCT_DATA, payload.productId);
  }
}


export const LOAD_PRODUCT_PRODUCT_SUCCESS = '[Product] Load Product Product Success';

export interface LoadProductProductSuccessPayload {
  userId: string
  productId: string
  product: Product
}

export class LoadProductProductSuccess extends StateUtils.EntitySuccessAction {
  override readonly type = LOAD_PRODUCT_PRODUCT_SUCCESS;

  constructor(
    public override payload: LoadProductProductSuccessPayload,
  ) {
    super(PRODUCT_PRODUCT_DATA, payload.productId);
  }
}
