/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { EntitiesModel, PaginationModel } from '@lm-nx-frontend/lm-inf-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizationTableType } from '../../../shared/organization.model';
import { UserGroupPermissionListService } from '../user-group-permission-list.service';
import {Permission} from "../../../../core/model";

@Injectable({
  providedIn: 'root',
})
export class UserGroupAssignedPermissionsListService extends UserGroupPermissionListService {
  protected tableType = OrganizationTableType.USER_GROUP_ASSIGNED_PERMISSIONS;
  /**
   * @override
   * Load all b2b users assigned to the given user group
   */
  protected load(
    pagination: PaginationModel,
    code: string
  ): Observable<EntitiesModel<Permission> | undefined> {
    return super
      .load(pagination, code)
      .pipe(map((users) => this.filterSelected(users)));
  }
}
