import {createSelector} from "@ngrx/store";
import {CustomerState} from "../state";
import {selectCustomer} from "../customer.selectors";
import {CustomerCountryState} from "./country.state";


export const selectCustomerCountry = createSelector(
  selectCustomer,
  (customerState: CustomerState) => customerState.country
)

export const selectCustomerCountryList = createSelector(
  selectCustomerCountry,
  (customerState: CustomerCountryState) => {
    return customerState.ids.map(id => customerState.entities[id]?.value)
  }
)

export const selectCustomerCountryLoading = createSelector(
  selectCustomerCountry,
  ({ loading }) => loading > 0
)
