import {HttpErrorModel, StateUtils} from "@lm-nx-frontend/lm-inf-core";
import {CategoryTree} from "../../model/category-tree";

export const LOAD_CATEGORY_TREE = '[Category] Load Tree';
export const LOAD_CATEGORY_TREE_SUCCESS = '[Category] Load Tree Success';
export const LOAD_CATEGORY_TREE_FAILURE = '[Category] Load Tree Failure';
export const CATEGORY_PRODUCT_TREE = '[Category] Product Tree';

// action types
export type CategoryTreeAction =
  | LoadCategoryTree
  | LoadCategoryTreeSuccess
  | LoadCategoryTreeFailure
;

export interface LoadCategoryTreePayload {
  userId: string
  customerId: string
}

export class LoadCategoryTree extends StateUtils.EntityLoadAction {
  override readonly type = LOAD_CATEGORY_TREE;

  constructor(
    public payload: LoadCategoryTreePayload,
  ) {
    super(CATEGORY_PRODUCT_TREE, 'product');
  }
}

export interface LoadCategoryTreeSuccessPayload {
  userId: string
  customerId: string
  tree: CategoryTree
}

export class LoadCategoryTreeSuccess extends StateUtils.EntitySuccessAction {
  override readonly type = LOAD_CATEGORY_TREE_SUCCESS;

  constructor(
    public override payload: LoadCategoryTreeSuccessPayload,
  ) {
    super(CATEGORY_PRODUCT_TREE, 'product');
  }
}

export interface LoadCategoryTreeFailurePayload {
  userId: string
  customerId: string
}

export class LoadCategoryTreeFailure extends StateUtils.EntityFailAction {
  override readonly type = LOAD_CATEGORY_TREE_FAILURE;

  constructor(
    public payload: LoadCategoryTreeFailurePayload,
    public error: HttpErrorModel,
  ) {
    super(CATEGORY_PRODUCT_TREE, 'product');
  }
}




