<div class="form-check">
  <label>
    <input
      type="checkbox"
      class="form-check-input"
      (change)="onConsentChange()"
      [checked]="consentGiven"
      [disabled]="isRequired(consentTemplate?.id)"
    />
    <span class="form-check-label cx-be-bold">
      {{ consentTemplate?.name }}
    </span>
    <br />
    <span class="form-check-label">
      {{ consentTemplate?.description }}
    </span>
  </label>
</div>
