<div class="row">
  <div class="col-12 col-md-4">
    <a
      [attr.aria-label]="
        !product.images?.hasOwnProperty('PRIMARY')
          ? ('productDetails.noProductImage'
            | cxTranslate: { product: product.name })
          : undefined
      "
      [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
      class="cx-product-image-container"
    >
      <cx-media
        class="cx-product-image"
        [container]="product.images?.PRIMARY"
        format="product"
        [alt]="product.name || product.summary"
      ></cx-media>
    </a>
  </div>
  <div class="col-12 col-md-8">
    <a
      [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
      class="cx-product-name"
    >
      <h2 [innerHtml]="product.nameHtml"></h2>
    </a>

    <ng-template [cxOutlet]="ProductListOutlets.ITEM_DETAILS">
      <cx-star-rating
        *ngIf="product.averageRating"
        [rating]="product?.averageRating"
      ></cx-star-rating>
      <div *ngIf="!product.averageRating" class="cx-product-no-review">
        {{ 'productDetails.noReviews' | cxTranslate }}
      </div>
      <div
        class="cx-product-price"
        [attr.aria-label]="'productDetails.productPrice' | cxTranslate"
      >
        {{ product.price?.formattedValue }}
      </div>
    </ng-template>

    <div class="row">
      <div class="col-12 col-md-7 col-xl-7">
        <p class="cx-product-summary" [innerHtml]="product.summary">
          {{ product.summary }}
        </p>
      </div>
      <div class="col-12 col-md-5 col-xl-5">
        <ng-template [cxOutlet]="ProductListOutlets.ITEM_ACTIONS">
          <ng-container cxInnerComponentsHost></ng-container>
        </ng-template>
      </div>
    </div>
  </div>
</div>
