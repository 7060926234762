import { NgModule } from '@angular/core';
import {ConfigModule, provideDefaultConfigFactory} from "@lm-nx-frontend/lm-inf-core";
import {defaultConfiguratorConfigFactory} from "./common/connectors/default-configurator-config";
import {ConfiguratorFormModule} from "./components/form";

@NgModule({
  imports: [
    ConfigModule,
    ConfiguratorFormModule,
  ],
  providers: [
    provideDefaultConfigFactory(defaultConfiguratorConfigFactory),
  ],
})
export class LmInfProductConfiguratorRootModule {}

