<button
  class="cx-hamburger"
  type="button"
  (click)="toggle()"
  [class.is-active]="isExpanded | async"
  [attr.aria-expanded]="isExpanded | async"
  [attr.aria-label]="'common.menu' | cxTranslate"
  aria-controls="cx-header"
>
  <svg _ngcontent-abi-c15="" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="h-6 w-6"><path _ngcontent-abi-c15="" stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"></path></svg>
</button>
