/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ConfiguratorPriceModule } from '../../price/configurator-price.module';
import { ConfiguratorAttributeQuantityModule } from '../quantity/configurator-attribute-quantity.module';
import { ConfiguratorAttributeProductCardComponent } from './configurator-attribute-product-card.component';
import {I18nModule, UrlModule} from "@lm-nx-frontend/lm-inf-core";
import {IconModule, KeyboardFocusModule, MediaModule} from "@lm-nx-frontend/lm-inf-ui";

@NgModule({
  declarations: [ConfiguratorAttributeProductCardComponent],
  exports: [ConfiguratorAttributeProductCardComponent],
  imports: [
    CommonModule,
    ConfiguratorAttributeQuantityModule,
    I18nModule,
    RouterModule,
    UrlModule,
    FormsModule,
    ReactiveFormsModule,
    MediaModule,
    ConfiguratorPriceModule,
    KeyboardFocusModule,
    IconModule,
  ],
})
export class ConfiguratorAttributeProductCardModule {}
