import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {CustomerAddress} from "@lm-nx-frontend/lm-inf-customer-root";
import {Product} from "@lm-nx-frontend/lm-inf-product-root";
import {Configurator} from "@lm-nx-frontend/lm-inf-product-configurator-root";

export interface CartItem {
  id: string
  internalID: string
  quantity: number
  expectedQuantity?: number
  name: string
  description: string
  total: null|string
  totalDiscounted?: null|string
  unitPrice: null|string
  unitPriceDiscounted?: null|string
  unitPriceDiscountedPercentage?: null|string
  product?: Product
  configuration?: Configurator.Configuration
}

export interface CartSummary {
  type: string
  total: number|null
  quantity: number|null
  shippingAddress: CustomerAddress|null
}

export interface OrderCartItem extends CartItem {
  expectedQuantity: number
}

export const cartFeatureKey = 'carts'

export interface WithCartState {
  [cartFeatureKey]: CartState
}

export interface CartState {
  items: EntityState<CartItem>
  loading: number;

  summary: CartSummary|null
}

export const cartItemEntityAdapter = createEntityAdapter<CartItem>({
  selectId({ id }) {
    return id
  }
})

export const initialCartState: CartState = {
  items: cartItemEntityAdapter.getInitialState(),
  loading: 0,
  summary: null
};
