<a
  *ngIf="linkable; else text"
  [routerLink]="{ cxRoute: route, params: routeModel } | cxUrl"
  [tabIndex]="tabIndex"
>
  <ng-container *ngTemplateOutlet="text"></ng-container>
</a>

<ng-template #text>
  <span class="text" title="{{ property }}">{{ property }}</span>
</ng-template>
