/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {from, Observable, tap} from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import {normalizeHttpError, SiteContextActions, withdrawOn} from "@lm-nx-frontend/lm-inf-core";
import {AddressConnector} from "../../connectors/address.connector";
import {
  CREATE_ADDRESS,
  CreateCustomerAddress,
  CreateCustomerAddressFailure, CreateCustomerAddressSuccess,
  EDIT_ADDRESS,
  EditCustomerAddress, EditCustomerAddressFailure, EditCustomerAddressSuccess,
  LOAD_ADDRESS,
  LoadCustomerAddress,
  LoadCustomerAddressFailure,
  LoadCustomerAddressSuccess
} from "./address.actions";

@Injectable()
export class AddressEffects {
  load$: Observable<
    | LoadCustomerAddress
    | LoadCustomerAddressFailure
    | LoadCustomerAddressSuccess
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_ADDRESS),
      map((action: LoadCustomerAddress) => action.payload),
      concatMap((payload) => {
        return this.addressConnector
          .search(
            payload.userId,
            payload.customerId,
            '',
          )
          .pipe(
            map(
              (response) =>
                new LoadCustomerAddressSuccess(
                  {
                    userId: payload.userId,
                    customerId: payload.customerId,
                    addresses: response.items,
                  }
                )
            ),
            catchError((error) =>
              from([
                new LoadCustomerAddressFailure(
                  payload,
                  normalizeHttpError(error)
                ),
              ])
            )
          );
      }),
      // withdrawOn(this.contextChange$)
    )
  );

  edit$: Observable<
    | EditCustomerAddress
    | EditCustomerAddressFailure
    | EditCustomerAddressSuccess
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(EDIT_ADDRESS),
      map((action: EditCustomerAddress) => action.payload),
      concatMap((payload) => {
        return this.addressConnector
          .update(
            payload.userId,
            payload.customerId,
            payload.customerAddress,
          )
          .pipe(
            map(
              (response) =>
                new EditCustomerAddressSuccess(
                  {
                    userId: payload.userId,
                    customerId: payload.customerId,
                    customerAddress: response,
                  }
                )
            ),
            catchError((error) =>
              from([
                new EditCustomerAddressFailure(
                  payload,
                  normalizeHttpError(error)
                ),
              ])
            )
          );
      }),
      // withdrawOn(this.contextChange$)
    )
  );

  create$: Observable<
    | CreateCustomerAddress
    | CreateCustomerAddressFailure
    | CreateCustomerAddressSuccess
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(CREATE_ADDRESS),
      map((action: CreateCustomerAddress) => action.payload),
      concatMap((payload) => {
        return this.addressConnector
          .create(
            payload.userId,
            payload.customerId,
            payload.address,
          )
          .pipe(
            map(
              (response) =>
                new CreateCustomerAddressSuccess(
                  {
                    userId: payload.userId,
                    customerId: payload.customerId,
                    address: response,
                  }
                )
            ),
            catchError((error) =>
              from([
                new CreateCustomerAddressFailure(
                  payload,
                  normalizeHttpError(error)
                ),
              ])
            )
          );
      }),
      // withdrawOn(this.contextChange$)
    )
  );

  constructor(
    private actions$: Actions,
    private addressConnector: AddressConnector
  ) {}
}
