/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfiguratorConflictSuggestionComponent } from './configurator-conflict-suggestion.component';
import {I18nModule} from "@lm-nx-frontend/lm-inf-core";

@NgModule({
  imports: [CommonModule, I18nModule],
  declarations: [ConfiguratorConflictSuggestionComponent],
  exports: [ConfiguratorConflictSuggestionComponent],
})
export class ConfiguratorConflictSuggestionModule {}
