import {createSelector} from "@ngrx/store";
import {selectCategory} from "../category.selectors";
import {CategoryState} from "../state";


export const selectCategoryTree = createSelector(
  selectCategory,
  (customerState: CategoryState) => {
    return customerState.tree
  }
)
