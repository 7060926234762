import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {combineReducers, StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {partsReducer} from "./store/parts/parts.reducer";
import {PartsEffect} from "./store/parts/parts.effect";
import {provideDefaultConfig} from "@lm-nx-frontend/lm-inf-core";
import {defaultOccProductConfig} from "./models/default-occ-product-config";
import {productReducer} from "./store/product/product.reducer";
import {ProductEffect} from "./store/product/product.effect";

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forFeature('product', combineReducers({
      parts: partsReducer,
      product: productReducer,
    })),
    EffectsModule.forFeature([
      PartsEffect,
      ProductEffect,
    ]),
  ],
  providers: [
    provideDefaultConfig(defaultOccProductConfig),
  ]
})
export class LmInfProductRootModule {}
