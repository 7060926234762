import {createFeatureSelector, createSelector} from "@ngrx/store";
import {CustomerState} from "../state";
import {selectCustomer} from "../customer.selectors";
import {CustomerAddressState} from "./address.state";
import {getRouterState} from "../../../../../lm-inf-core/src/lib/routing/store/selectors/routing.selector";
import {RouterState} from "@lm-nx-frontend/lm-inf-core";
import {selectCartState} from "../../../../../inf-cart/src/lib/store/cart.selectors";


export const selectCustomerAddress = createSelector(
  selectCustomer,
  (customerState: CustomerState) => customerState.address
)

export const selectCustomerAddressList = createSelector(
  selectCustomerAddress,
  (customerState: CustomerAddressState) => {
    return customerState.ids.map(id => customerState.entities[id]?.value)
  }
)

export const selectCustomerAddressRouterId = createSelector(
  getRouterState,
  (routerState: RouterState) => {
    return routerState.state.params?.['customerAddressID']
  }
)

export const selectSelectedCustomerAddress = createSelector(
  selectCustomerAddress,
  selectCustomerAddressRouterId,
  (customerState: CustomerAddressState, routerId: string|null) => {
    return customerState.entities?.[routerId]?.value;
  }
)

export const selectCustomerAddressLoading = createSelector(
  selectCustomerAddress,
  ({ loading }) => loading > 0
)
