<ng-container *ngIf="orderEntry$ | async as orderEntry">
  <ng-container *ngIf="hasIssues(orderEntry) && !(readonly$ | async)">
    <cx-icon [type]="iconTypes.ERROR"></cx-icon>
    <div id="{{ getErrorMessageId(orderEntry) }}">
      {{
        'configurator.notificationBanner.numberOfIssues'
      }}
      <ng-container *ngIf="quantityControl$ | async as quantityControl">
        <cx-configure-cart-entry
          class="cx-error-msg-action"
          *ngIf="
            (shouldShowButton$ | async) && orderEntry?.product?.configurable
          "
          [cartEntry]="orderEntry"
          [readOnly]="(readonly$ | async) ?? true"
          [msgBanner]="true"
          [disabled]="quantityControl.disabled"
        ></cx-configure-cart-entry
      ></ng-container>
    </div>
  </ng-container>
</ng-container>
