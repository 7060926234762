/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export enum ConfiguratorProductScope {
  CONFIGURATOR = 'configurator',
  CONFIGURATOR_PRODUCT_CARD = 'configuratorProductCard',
}
