/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Configurator } from '../../../../lm-inf-product-configurator-root/src/lib/model/configurator.model';
import {EntityProcessesLoaderState} from "@lm-nx-frontend/lm-inf-core";

export const CONFIGURATOR_FEATURE = 'productConfigurator';
export const CONFIGURATOR_DATA = '[Configurator] Configuration Data';

export interface StateWithConfigurator {
  [CONFIGURATOR_FEATURE]: ConfiguratorState;
}

export interface ConfiguratorState {
  configurations: EntityProcessesLoaderState<Configurator.Configuration>;
}
