/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConverterService,
  normalizeHttpError,
  Occ,
  OccEndpointsService,
} from '@lm-nx-frontend/lm-inf-core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {USER_ACCOUNT_NORMALIZER, UserAccountAdapter} from "../../core/connectors";
import {User} from "../../root/model";

@Injectable()
export class OccUserAccountAdapter implements UserAccountAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {}

  load(userId: string): Observable<User> {
    const url = this.occEndpoints.buildUrl('user', { urlParams: { userId } });
    return this.http.get<Occ.User>(url).pipe(
      catchError((error) => throwError(normalizeHttpError(error))),
      this.converter.pipeable(USER_ACCOUNT_NORMALIZER)
    );
  }
}
