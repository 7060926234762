/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisableInfoComponent } from './disable-info.component';
import { IconModule } from '@lm-nx-frontend/lm-inf-ui';
import { I18nModule } from '@lm-nx-frontend/lm-inf-core';

@NgModule({
  imports: [CommonModule, IconModule, I18nModule],
  declarations: [DisableInfoComponent],
  exports: [DisableInfoComponent],
})
export class DisableInfoModule {}
