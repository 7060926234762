import {EntityLoaderState} from "@lm-nx-frontend/lm-inf-core";
import {CustomerAddress} from "../../model/customer";

export interface CustomerAddressState extends EntityLoaderState<CustomerAddress> {
  ids: string[]
  loading: number
}

export const defaultCustomerAddressState: CustomerAddressState = {
  entities: {},
  ids: [],
  loading: 0,
}
