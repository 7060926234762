/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import {AccountSummaryCoreModule} from "./core/account-summary-core.module";
import {AdministrationModule} from "../administration/administration.module";
import {AccountSummaryComponentsModule} from "./components/account-summary-components.module";
import {AccountSummaryOccModule} from "./occ/account-summary-occ.module";

@NgModule({
  imports: [
    AccountSummaryCoreModule,
    AccountSummaryOccModule,
    AccountSummaryComponentsModule,
    AdministrationModule,
  ],
})
export class AccountSummaryModule {}
