<ng-container *ngIf="user$ | async as user; else login">
  <div class="cx-login-greet">
    {{ 'miniLogin.userGreeting' | cxTranslate: { name: user.name } }}
  </div>
  <cx-page-slot id="account-nav" position="HeaderLinks"></cx-page-slot>
</ng-container>

<ng-template #login>
  <a role="link" [routerLink]="{ cxRoute: 'login' } | cxUrl">{{
    'miniLogin.signInRegister' | cxTranslate
  }}</a>
</ng-template>
