import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ItemsModel, OccEndpointsService, SearchConfig} from "@lm-nx-frontend/lm-inf-core";
import {Observable} from "rxjs";
import {Product} from "@lm-nx-frontend/lm-inf-product-root";

@Injectable({
  providedIn: 'root'
})
export class ProductProductDataService {
  constructor(
    protected readonly endpointsService: OccEndpointsService,
    protected readonly httpClient: HttpClient
  ) { }

  get(
    userId: string,
    productId: string,
  ): Observable<Product> {
    const url = this.endpointsService.buildUrl('product', {
      urlParams: {
        userId,
        productId,
      },
    }, undefined, false)

    return this.httpClient.get<Product>(url);
  }

}
