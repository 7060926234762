<ng-container *ngIf="isLoggedIn$ | async">
  <div
    class="cx-close-account-modal"
    [cxFocus]="focusConfig"
    (esc)="dismissModal('Escape click')"
  >
    <div class="cx-close-account-modal-container">
      <div class="cx-close-account-modal-header cx-modal-header">
        <h3 class="cx-close-account-modal-title">
          {{ 'closeAccount.confirmAccountClosure' | cxTranslate }}
        </h3>
        <button
          type="button"
          class="close"
          [attr.aria-label]="'common.close' | cxTranslate"
          (click)="dismissModal('Cross click')"
        >
          <span aria-hidden="true">
            <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
          </span>
        </button>
      </div>

      <div *ngIf="isLoading$ | async; else loaded">
        <div class="cx-spinner">
          <cx-spinner> </cx-spinner>
        </div>
      </div>

      <ng-template #loaded>
        <div class="cx-close-account-modal-body modal-body">
          <p class="cx-confirmation">
            {{ 'closeAccount.confirmAccountClosureMessage' | cxTranslate }}
          </p>
        </div>
        <div class="cx-close-account-modal-footer cx-modal-footer">
          <button class="btn btn-primary" (click)="closeAccount()">
            {{ 'closeAccount.closeMyAccount' | cxTranslate }}
          </button>
          <button
            (click)="dismissModal('Cancel')"
            class="btn btn-block btn-secondary"
          >
            {{ 'common.cancel' | cxTranslate }}
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
