<div id="{{ createAttributeIdForConfigurator(attribute) }}" class="form-group">
  <input
    [formControl]="attributeInputForm"
    [required]="isRequired"
    class="form-control"
    attr.required="{{ attribute.required }}"
    attr.maxlength="{{ attribute.maxlength }}"
    [attr.aria-label]="
      attribute.userInput === undefined || attribute.userInput.length === 0
        ? ('configurator.a11y.valueOfAttributeBlank'
          | cxTranslate
            : {
                attribute: attribute.label
              })
        : ('configurator.a11y.valueOfAttributeFull'
          | cxTranslate
            : {
                value: attribute.userInput,
                attribute: attribute.label
              })
    "
    [attr.aria-describedby]="createAttributeUiKey('label', attribute.name)"
    [cxFocus]="{
      key: createAttributeIdForConfigurator(attribute)
    }"
  />
</div>
