import {Inject, Injectable} from "@angular/core";
import {
  addCartItem,
  addCartItemFailure,
  addCartItemSuccess,
  getCartSummary,
  getCartSummaryFailure,
  getCartSummarySuccess,
  listCartItems,
  listCartItemsFailure,
  listCartItemsSuccess,
  removeCartItem,
  removeCartItemFailure,
  removeCartItemSuccess,
  setCartItemQuantity,
  setCartItemQuantityFailure,
  setCartItemQuantitySuccess,
  updateCartShippingAddress, updateCartShippingAddressFailure,
  updateCartShippingAddressSuccess
} from "./cart.actions";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, exhaustMap, from, map, mergeMap, of, switchMap, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {CartItem, CartSummary} from "@lm-nx-frontend/inf-cart";
import {OccEndpointsService} from "@lm-nx-frontend/lm-inf-core";
import {CartEntryAdapter} from "@lm-nx-frontend/lm-inf-cart";

@Injectable()
export class CartEffects {
  addCartItem$ = createEffect(
    () => this.actions$.pipe(
      ofType(addCartItem),
      mergeMap(({ cartID, id, cartItem, userID }) => {
        return this.httpClient.post<CartItem>(
          this.occEndpointsService.buildUrl('addItems', {
            urlParams: {
              userId: userID,
              cartId: cartID,
              cartItemID: id,
            }
          }, undefined, false),
          {
            ...cartItem,
            ...(
              cartItem?.id?.startsWith('p-') ? {
                product: {
                  id: cartItem?.internalID
                }
              } : {}
            )
          }
        ).pipe(
          mergeMap(
            (cartItem: CartItem) => [
              addCartItemSuccess({
                cartID,
                userID,
                id,
                cartItem,
              }),
              listCartItems({
                cartID,
                userID,
              })
            ]
          ),
          catchError(({ message: errorMessage }) =>
            of(
              addCartItemFailure({
                cartID,
                id,
                userID,
                errorMessage
              })
            )
          )
        )
      })
    )
  );

  setCartItemQuantity$ = createEffect(
    () => this.actions$.pipe(
      ofType(setCartItemQuantity),
      mergeMap(({ cartID, id, quantity, userID }) => {
        return this.httpClient.patch<CartItem>(
          this.occEndpointsService.buildUrl('updateItems', {
            urlParams: {
              userId: userID,
              cartId: cartID,
              cartItemID: id,
            }
          }, undefined, false),
          {
            quantity
          }
        ).pipe(
          mergeMap(
            (cartItem: CartItem) => [
              setCartItemQuantitySuccess({
                cartID,
                id,
                userID,
                cartItem,
                quantity,
              }),
              listCartItems({
                cartID,
                userID,
              })
            ]
          ),
          catchError(({ message: errorMessage }) =>
            of(
              setCartItemQuantityFailure({
                cartID,
                id,
                userID,
                errorMessage
              })
            )
          )
        )
      })
    )
  );
  removeCartItem$ = createEffect(
    () => this.actions$.pipe(
      ofType(removeCartItem),
      mergeMap(({ cartID, id, userID }) => {
        return this.httpClient.delete<CartItem>(
          this.occEndpointsService.buildUrl('removeItems', {
            urlParams: {
              userId: userID,
              cartId: cartID,
              cartItemID: id,
            }
          }, undefined, false)
        ).pipe(
          mergeMap(
          (cartItem: CartItem) => [
            removeCartItemSuccess({
              cartID,
              userID,
              id,
            }),
            listCartItems({
              cartID,
              userID,
            })
          ]
         ),
          catchError(({ message: errorMessage }) =>
            of(
              removeCartItemFailure({
                cartID,
                userID,
                id,
                errorMessage
              })
            )
          )
        )
      }),
    )
  );
  listCartItems$ =  createEffect(
    () => this.actions$.pipe(
      ofType(listCartItems),
      mergeMap(({ cartID, userID }) => {
        return this.httpClient.get<CartItem[]>(
          this.occEndpointsService.buildUrl('items', {
            urlParams: {
              userId: userID,
              cartId: cartID,
            }
          }, undefined, false)
        ).pipe(
          map(
            (cartItems: CartItem[]) => listCartItemsSuccess({
              cartID,
              userID,
              cartItems,
            })
          ),
          catchError(({ message: errorMessage }) =>
            of(
              listCartItemsFailure({
                cartID,
                userID,
                errorMessage
              })
            )
          )
        )
      })
    )
  );

  getCartSummary$ =  createEffect(
    () => this.actions$.pipe(
      ofType(getCartSummary, setCartItemQuantitySuccess),
      mergeMap(({ cartID, userID }) => {
        return this.httpClient.get<CartSummary>(
          this.occEndpointsService.buildUrl('cart', {
            urlParams: {
              userId: userID,
              cartId: cartID,
            }
          }, undefined, false)
        ).pipe(
          map(
            (summary: CartSummary) => getCartSummarySuccess({
              cartID,
              userID,
              summary,
            })
          ),
          catchError(({ message: errorMessage }) =>
            of(
              getCartSummaryFailure({
                cartID,
                userID,
                errorMessage
              })
            )
          )
        )
      })
    )
  );

  updateCartShippingAddress$ =  createEffect(
    () => this.actions$.pipe(
      ofType(updateCartShippingAddress),
      mergeMap(({ cartID, userID, address }) => {
        return this.httpClient.patch<CartSummary>(
          this.occEndpointsService.buildUrl('cart', {
            urlParams: {
              userId: userID,
              cartId: cartID,
            }
          }, undefined, false),
          {
            shippingAddress: address,
          }
        ).pipe(
          map(
            (summary: CartSummary) => updateCartShippingAddressSuccess({
              cartID,
              userID,
              summary,
            })
          ),
          catchError(({ message: errorMessage }) =>
            of(
              updateCartShippingAddressFailure({
                cartID,
                userID,
                errorMessage
              })
            )
          )
        )
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly httpClient: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected occCartEntryAdapter: CartEntryAdapter
  ) {}

}
