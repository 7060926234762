import {createSelector} from "@ngrx/store";
import {entityLoadingSelector, entityValueSelector, getRouterState, RouterState} from "@lm-nx-frontend/lm-inf-core";
import {selectProductState} from "../product.selectors";
import {ProductState} from "../state";
import {ProductProductState} from "./product.state";
import {Product} from "@lm-nx-frontend/lm-inf-product-root";

export const selectProductProductState = createSelector(
  selectProductState,
  (user: ProductState) => {
    return user.product
  }
)

export const selectProductRouterId = createSelector(
  getRouterState,
  (routerState: RouterState) => {
    return routerState.state.params?.['productID']
  }
)

export const selectProductRouter = createSelector(
  selectProductRouterId,
  selectProductProductState,
  (routerId: string|null, entities) => {
    console.log(JSON.parse(JSON.stringify(entities)))
    return entityValueSelector<Product>(entities, routerId);
  }
)

export const selectProductLoading = createSelector(
  selectProductRouterId,
  selectProductProductState,
  (routerId: string|null, entities) => {
    return entityLoadingSelector<Product>(entities, routerId);
  }
)
