/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ConfigurationInfo, StatusSummary } from './common-configurator.model';
declare module '@lm-nx-frontend/lm-inf-core' {
  interface Product {
    configurable?: boolean;
    configuratorType?: string;
  }
}
declare module '@lm-nx-frontend/inf-cart' {
  interface OrderEntry {
    statusSummaryList?: StatusSummary[];
    configurationInfos?: ConfigurationInfo[];
  }
}
