import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {WithCategoryState} from "../state";
import {Observable, tap} from "rxjs";
import {Actions} from "@ngrx/effects";
import {map} from "rxjs/operators";
import {selectCategory} from "../category.selectors";
import {entityValueSelector} from "@lm-nx-frontend/lm-inf-core";
import {CATEGORY_PRODUCT_TREE, LoadCategoryTree} from "./tree.actions";
import {CategoryTree} from "../../model/category-tree";
import {selectCategoryTree} from "./tree.selectors";

@Injectable({
  providedIn: 'root'
})
export class TreeFacade {
  constructor(
    protected readonly store: Store<WithCategoryState>,
    protected readonly actions: Actions,
  ) {}

  load(
    userId: string,
    customerId: string,
  ): void {
    this.store.dispatch(
      new LoadCategoryTree({
        userId,
        customerId,
      })
    )
  }

  get(): Observable<CategoryTree|undefined> {
    return this.store.select(
      selectCategoryTree,
    ).pipe(
      map(v => entityValueSelector(v, 'product')),
    )
  }
}
