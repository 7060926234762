import {createReducer, on} from "@ngrx/store";
import {CartItem, cartItemEntityAdapter, initialCartState} from "./cart.state";
import {
  addCartItem,
  addCartItemFailure,
  addCartItemSuccess,
  clearCart, getCartSummary, getCartSummaryFailure, getCartSummarySuccess,
  listCartItems,
  listCartItemsFailure,
  listCartItemsSuccess,
  removeCartItem,
  removeCartItemFailure,
  removeCartItemSuccess,
  setCartItemQuantity,
  setCartItemQuantityFailure,
  setCartItemQuantitySuccess,
  updateCartShippingAddress,
  updateCartShippingAddressFailure,
  updateCartShippingAddressSuccess
} from "./cart.actions";

export const cartReducer = createReducer(
  initialCartState,
  on(clearCart, (state) => ({ ...state, items: cartItemEntityAdapter.removeAll(state.items), productCartItemMapping: {} })),
  on(addCartItem, removeCartItem, setCartItemQuantity, listCartItems, updateCartShippingAddress, getCartSummary, (state) => {
    return {
      ...state,
      loading: state.loading+1
    }
  }),
  on(addCartItemFailure, removeCartItemFailure, setCartItemQuantityFailure, listCartItemsFailure, updateCartShippingAddressFailure, getCartSummaryFailure, (state) => {
    return {
      ...state,
      loading: state.loading-1
    }
  }),
  on(addCartItemSuccess, (state, { id, cartItem }) => {
    let currentItem: Partial<CartItem>|CartItem = {
      ...cartItem,
      quantity: 0,
      id,
    }

    if (state.items?.entities?.[id]) {
      currentItem = { ...state.items?.entities?.[id] ?? {}, ...currentItem }
    }

    if (!currentItem.quantity) {
      currentItem.quantity = 0
    }

    if (cartItem) {
      currentItem.quantity += cartItem.quantity
    }

    return {
      ...state,
      loading: state.loading -1,
      items: cartItemEntityAdapter.upsertOne(currentItem as CartItem, state.items)
    }
  }),
  on(removeCartItemSuccess, (state, { id }) => {
    return {
      ...state,

      loading: state.loading -1,
      items: cartItemEntityAdapter.removeOne(id, state.items)
    }
  }),
  on(listCartItemsSuccess, (state, { cartItems }) => {
    return {
      ...state,

      loading: state.loading -1,
      items: cartItemEntityAdapter.setMany(cartItems ?? [], state.items)
    }
  }),
  on(updateCartShippingAddressSuccess, getCartSummarySuccess, (state, { summary }) => {
    return {
      ...state,
      loading: state.loading -1,
      summary,
    }
  }),
  on(setCartItemQuantitySuccess, (state, {  id, cartID, cartItem, quantity}) => {
    const data = state.items.entities?.[id]
    if (!data) {
      return {
        ...state,
        loading: state.loading -1,
      }
    }

    if (!quantity) {
      return {
        ...state,
        loading: state.loading -1,
        items: cartItemEntityAdapter.removeOne(id, state.items)
      }
    }

    return {
      ...state,
      loading: state.loading -1,
      items: cartItemEntityAdapter.upsertOne({ ...data, quantity }, state.items)
    }
  }),
);
