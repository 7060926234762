<div *ngIf="!attribute.visible" class="cx-hidden-msg">
  <cx-icon [type]="iconTypes.WARNING" aria-hidden="true"></cx-icon>
  {{ 'configurator.attribute.notVisibleAttributeMsg' }}
</div>

<label
  id="{{ createAttributeUiKey('label', attribute.name) }}"
  [class.cx-required-error]="showRequiredMessageForDomainAttribute$ | async"
  class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 mb-2"
  [attr.aria-label]="
    !attribute.required
      ? ('configurator.a11y.attribute'
        )
      : ('configurator.a11y.requiredAttribute'
        )
  "
  ><span
    [class.cx-required-icon]="attribute.required"
    [attr.aria-describedby]="createAttributeUiKey('label', attribute.name)"
    >{{ getLabel(expMode, attribute.label, attribute.name) }}</span
  ></label
>
<div
  *ngIf="attribute.hasConflicts"
  class="cx-conflict-msg"
  id="{{ createAttributeUiKey('attribute-msg', attribute.name) }}"
  [attr.aria-live]="isConflictResolutionActive() ? 'assertive' : 'off'"
  [attr.aria-atomic]="isConflictResolutionActive() ? true : false"
  [attr.role]="isConflictResolutionActive() ? 'alert' : null"
  [attr.aria-label]="
    isConflictResolutionActive()
      ? ('configurator.a11y.conflictDetected' | cxTranslate)
      : ''
  "
>
  <cx-icon
    *ngIf="isAttributeGroup()"
    [type]="iconTypes.WARNING"
    aria-hidden="true"
  ></cx-icon>
  <ng-container *ngIf="isAttributeGroup(); else conflictGroup">
    <ng-container *ngIf="isNavigationToConflictEnabled(); else withoutLink">
      <a
        class="link cx-action-link"
        (click)="navigateToGroup()"
        (keydown.enter)="navigateToGroup()"
        tabindex="0"
        [attr.aria-label]="
          'configurator.a11y.navigateToConflict'
            | cxTranslate: { attribute: attribute.label }
        "
      >
        {{ getConflictMessageKey() | cxTranslate }}
      </a>
    </ng-container>
  </ng-container>
</div>
<div
  *ngIf="showRequiredMessageForDomainAttribute$ | async"
  class="cx-required-error-msg"
  id="{{ createAttributeUiKey('attribute-msg', attribute.name) }}"
  [attr.aria-label]="getRequiredMessageKey() | cxTranslate"
>
  <cx-icon [type]="iconTypes.ERROR"></cx-icon>
  {{ getRequiredMessageKey() | cxTranslate }}
</div>
<img
  *ngIf="hasImage"
  class="cx-attribute-img"
  src="{{ image?.url }}"
  alt="{{ image?.altText }}"
/>

<ng-template #conflictGroup>
  <a
    *ngIf="isNavigationToGroupEnabled"
    class="link cx-action-link"
    (click)="navigateToGroup()"
    (keydown.enter)="navigateToGroup()"
    tabindex="0"
  >
    {{ getConflictMessageKey() | cxTranslate }}
  </a>
</ng-template>

<ng-template #withoutLink>
  <div class="cx-conflict-msg">
    {{ getConflictMessageKey() | cxTranslate }}
  </div>
</ng-template>
