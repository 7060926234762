/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ClearCartDialogComponent } from './clear-cart-dialog.component';
import {DIALOG_TYPE, LayoutConfig} from "@lm-nx-frontend/lm-inf-ui";

export const defaultClearCartLayoutConfig: LayoutConfig = {
  launch: {
    CLEAR_CART: {
      inline: true,
      component: ClearCartDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};
