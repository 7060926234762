/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRegistrationAdapter } from './user-registration.adapter';
import {OrganizationUserRegistration} from "../../root/model";

@Injectable()
export class UserRegistrationConnector {
  constructor(protected adapter: UserRegistrationAdapter) {}

  registerUser(
    userData: OrganizationUserRegistration
  ): Observable<OrganizationUserRegistration> {
    return this.adapter.registerUser(userData);
  }
}
