<ng-container *ngIf="displayConflictSuggestion(currentGroup)">
  <div
    class="cx-title"
    [attr.aria-label]="
      ('configurator.conflict.suggestionTitle'
        +
      ' ' +
      ('configurator.conflict.suggestionText'))
    "
  >
    <span aria-hidden="true">{{
      'configurator.conflict.suggestionTitle'
    }}</span>
  </div>
  <span aria-hidden="true">{{
    'configurator.conflict.suggestionText'
  }}</span>
</ng-container>
