<ng-container *ngIf="orderEntry$ | async as orderEntry">
  <ng-container *ngIf="isBundleBasedConfigurator(orderEntry)">
    <ng-container *ngIf="numberOfLineItems$ | async as numberOfItems">
      <div class="cx-number-items">
        {{
          'configurator.header.items'
        }}
      </div>
      <button
        (click)="toggleItems()"
        [attr.aria-expanded]="!this.hideItems"
        [attr.aria-label]="getItemsMsg(numberOfItems)"
      >
        <div class="cx-toggle-hide-items">
          {{ getButtonText() }}
        </div>
      </button>

      <div class="cx-item-infos" [class.open]="!hideItems">
        <div
          *ngFor="let lineItem of lineItems$ | async; let i = index"
          class="cx-item-info"
          attr.aria-describedby="{{ getHiddenItemInfoId(i) }}"
        >
          <span id="{{ getHiddenItemInfoId(i) }}" class="cx-visually-hidden">
            {{ getHiddenItemInfo(lineItem) }}
          </span>
          <div class="cx-item-name" aria-hidden="true">
            {{ lineItem?.name }}
          </div>
          <div class="cx-item-quantity" aria-hidden="true">
            <ng-container *ngIf="lineItem?.formattedQuantity">
              <span class="cx-identifier">{{
                'configurator.attribute.quantity'
              }}</span>
              <span class="cx-item">{{
                lineItem?.formattedQuantity | cxNumeric
              }}</span>
            </ng-container>
          </div>
          <div class="cx-item-price" aria-hidden="true">
            <ng-container *ngIf="lineItem?.formattedPrice">
              <span class="cx-identifier">{{
                'configurator.overviewForm.itemPrice'
              }}</span>
              <span class="cx-item">{{ lineItem?.formattedPrice }}</span>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="quantityControl$ | async as quantityControl">
      <cx-configure-cart-entry
        *ngIf="(shouldShowButton$ | async) && orderEntry?.product?.configurable"
        [cartEntry]="orderEntry"
        [readOnly]="(readonly$ | async) ?? true"
        [msgBanner]="false"
        [disabled]="quantityControl.disabled"
      ></cx-configure-cart-entry
    ></ng-container>
  </ng-container>
</ng-container>
