import { Injectable } from '@angular/core';
import {
  EntitiesModel,
  ItemsModel,
  OccEndpointsService,
  ProductActions,
  SearchConfig
} from "@lm-nx-frontend/lm-inf-core";
import {Observable} from "rxjs";
import {Customer, CustomerAddress} from "../model/customer";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AddressConnector {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly occEndpointsService: OccEndpointsService,
  ) { }


  search(
    userId: string,
    customerId: string,
    query: string | undefined,
    searchConfig?: SearchConfig
  ): Observable<ItemsModel<CustomerAddress>> {
    const url = this.occEndpointsService.buildUrl('customerAddresses', {
      urlParams: {
        userId,
        customerId,
      },
      queryParams: {
        ...searchConfig,
        query,
      }
    })

    return this.httpClient.get<ItemsModel<CustomerAddress>>(url);
  }

  create(
    userId: string,
    customerId: string,
    address: CustomerAddress
  ): Observable<CustomerAddress> {
    const url = this.occEndpointsService.buildUrl('customerAddresses', {
      urlParams: {
        userId,
        customerId,
      },
    })

    return this.httpClient.post<CustomerAddress>(url, address);
  }

  update(
    userId: string,
    customerId: string,
    address: CustomerAddress
  ): Observable<CustomerAddress> {
    const url = this.occEndpointsService.buildUrl('customerAddress', {
      urlParams: {
        userId,
        customerId,
        customerAddressId: address.id,
      },
    })

    return this.httpClient.post<CustomerAddress>(url, address);
  }
}
