import { Injectable } from '@angular/core';
import {
  OccEndpointsService,
} from "@lm-nx-frontend/lm-inf-core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {CategoryTree} from "../model/category-tree";

@Injectable({
  providedIn: 'root'
})
export class TreeConnector {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly occEndpointsService: OccEndpointsService,
  ) { }


  get(
    userId: string,
    customerId: string,
  ): Observable<CategoryTree> {
    const url = this.occEndpointsService.buildUrl('categoryTrees', {
      urlParams: {
        userId,
        customerId,
      },
      queryParams: {
      }
    })

    return this.httpClient.get<CategoryTree>(url);
  }
}
