<div class="col-lg-8 col-md-9">
  <div class="row cx-btn-group">
    <div class="col-sm-6">
      <a
        [routerLink]="{ cxRoute: 'home' } | cxUrl"
        class="btn btn-block btn-secondary"
        >{{ 'common.cancel' | cxTranslate }}</a
      >
    </div>
    <div class="col-sm-6">
      <button #element class="btn btn-block btn-primary" (click)="openModal()">
        {{ 'closeAccount.closeMyAccount' | cxTranslate }}
      </button>
    </div>
  </div>
</div>
