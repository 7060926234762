/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { InjectionToken } from '@angular/core';
import { Converter } from '@lm-nx-frontend/lm-inf-core';
import {OrganizationUserRegistration} from "../../root/model";

export const ORGANIZATION_USER_REGISTRATION_SERIALIZER = new InjectionToken<
  Converter<OrganizationUserRegistration, any>
>('OrganizationUserRegistrationSerializer');
