/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { OccEndpoint } from '@lm-nx-frontend/lm-inf-core';

declare module '@lm-nx-frontend/lm-inf-core' {
  interface OccEndpoints {
    /**
     * Endpoint for organization user registration
     *
     * @member {string}
     */
    organizationUserRegistration?: string | OccEndpoint;
  }
}
