import { Injectable } from '@angular/core';
import {
  EntitiesModel,
  ItemsModel,
  OccEndpointsService,
  ProductActions,
  SearchConfig
} from "@lm-nx-frontend/lm-inf-core";
import {Observable} from "rxjs";
import {Customer, CustomerAddress} from "../model/customer";
import {HttpClient} from "@angular/common/http";
import {CustomerCountry} from "../model/customerCountry";

@Injectable({
  providedIn: 'root'
})
export class CountryConnector {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly occEndpointsService: OccEndpointsService,
  ) { }


  search(
    userId: string,
    customerId: string,
    query: string | undefined,
    searchConfig?: SearchConfig
  ): Observable<ItemsModel<CustomerCountry>> {
    const url = this.occEndpointsService.buildUrl('customerCountries', {
      urlParams: {
        userId,
        customerId,
      },
      queryParams: {
        ...searchConfig,
        query,
      }
    })

    return this.httpClient.get<ItemsModel<CustomerCountry>>(url);
  }
}
