<ng-container *ngIf="cart$ | async as cart">
  <div *ngIf="$any(cart?.totalItems) > 0" class="clear-cart-wrapper">
    <button
      #element
      (click)="openDialog($event)"
      class="btn btn-tertiary clear-cart-btn"
      type="button"
    >
      {{ 'clearCart.clearCart' | cxTranslate }}
    </button>
  </div>
</ng-container>
