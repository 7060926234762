<nav [attr.aria-label]="'common.breadcrumbs' | cxTranslate" tabindex="-1">
  <ol [attr.aria-label]="'common.breadcrumbs' | cxTranslate">
    <li *ngFor="let crumb of crumbs$ | async">
      <a
        [attr.aria-label]="
          'navigation.navigateTo' | cxTranslate: { nav: crumb.label }
        "
        [routerLink]="crumb.link"
        [innerHTML]="crumb.label"
      ></a>
    </li>
  </ol>
</nav>
<!-- Hidden page title for Screen Reader initialized after view to avoid old values -->
<span class="cx-visually-hidden" aria-live="polite" aria-atomic="true">
  {{ lastestTitle$ | async }}
</span>
<h1>{{ title$ | async }}</h1>
