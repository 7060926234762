<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <label>
    <span class="label-content">{{
      'forgottenPassword.emailAddress.label' | cxTranslate
    }}</span>
    <input
      required="true"
      type="email"
      class="form-control"
      placeholder="{{
        'forgottenPassword.emailAddress.placeholder' | cxTranslate
      }}"
      formControlName="userEmail"
    />
    <cx-form-errors [control]="form.get('userEmail')"></cx-form-errors>
  </label>

  <a
    class="btn btn-block btn-secondary"
    [routerLink]="{ cxRoute: 'login' } | cxUrl"
    >{{ 'common.cancel' | cxTranslate }}</a
  >
  <button class="btn btn-block btn-primary" [disabled]="form.disabled">
    {{ 'common.submit' | cxTranslate }}
  </button>
</form>
