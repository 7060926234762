import {
  isDevMode,
  NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// import { AppComponent } from './app.component';
// import {SpinnerComponent} from "./spinner.component";
import {HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors} from "@angular/common/http";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {environment} from "../environments/environment";
import {
  AnonymousConsentsModule,
  AuthModule,
  ExternalRoutesModule,
  ProductModule,
  UserModule,
  LmInfCoreModule,
  provideConfig,
  OccConfig,
  UserOccModule,
  ProductOccModule,
  provideDefaultConfig,
  CostCenterOccModule,
  SiteContextConfig, CmsConfig, I18nConfig, AuthConfig, RoutingConfig, RoutingModule,
} from "@lm-nx-frontend/lm-inf-core";
import {
  defaultCmsContentProviders,
  layoutConfig,
  LmInfUiModule, mediaConfig, PWAModuleConfig
  // AddressBookModule,
  // AnonymousConsentManagementBannerModule,
  // AnonymousConsentsDialogModule,
  // AppRoutingModule, BannerModule, BreadcrumbModule, CategoryNavigationModule,
  // ConsentManagementModule, defaultCmsContentProviders,
  // defaultOnNavigateConfig, FooterNavigationModule,
  // HamburgerMenuModule, HomePageEventModule, layoutConfig, LinkModule,
  // LmInfUiModule,
  // LoginRouteModule,
  // LogoutModule, mediaConfig,
  // MyInterestsModule, NavigationEventModule, NavigationModule,
  // NotificationPreferenceModule,
  // onNavigateFactory,
  // OnNavigateService, PageTitleModule,
  // PaymentMethodsModule,
  // ProductCarouselModule,
  // ProductDetailsPageModule,
  // ProductFacetNavigationModule,
  // ProductImagesModule,
  // ProductIntroModule,
  // ProductListingPageModule,
  // ProductListModule,
  // ProductPageEventModule,
  // ProductReferencesModule,
  // ProductSummaryModule,
  // ProductTabsModule, PWAModuleConfig, ScrollToTopModule,
  // SearchBoxModule,
  // SiteContextSelectorModule,
  // StorefrontComponent,
  // AnonymousConsentsDialogModule,
  // HamburgerMenuModule,
  // ProductListModule,
  // SiteContextSelectorModule,
} from "@lm-nx-frontend/lm-inf-ui";
import { ServiceWorkerModule } from '@angular/service-worker';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  ORGANIZATION_USER_REGISTRATION_FEATURE, OrganizationUserRegistrationRootModule,
  organizationUserRegistrationTranslationChunksConfig,
  organizationUserRegistrationTranslations,
  USER_ACCOUNT_FEATURE,
  UserAccountRootModule,
  userAccountTranslationChunksConfig,
  userAccountTranslations
} from "@lm-nx-frontend/lm-inf-customer";
import {translations} from "../assets/translations/translations";
import {translationChunksConfig} from "../assets/translations/translation-chunks-config";
import {AppComponent} from "./app.component";
import {SpinnerComponent} from "./spinner.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {RouterModule} from "@angular/router";
import {appRoutes} from "./app.routes";
import {CartBaseFeatureModule} from "./features/cart/cart-base-feature.module";
import {LmInfInquiryModule} from "@lm-nx-frontend/lm-inf-inquiry";
import {InfCartModule} from "@lm-nx-frontend/inf-cart";
import {LmInfCustomerRootModule} from "@lm-nx-frontend/lm-inf-customer-root";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {InfClickOutsideModule} from "@lm-nx-frontend/inf-click-outside";
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyMaterialModule } from '@ngx-formly/material';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { provideFuse } from "@fuse/fuse.provider";
import {MATERIAL_SANITY_CHECKS} from "@angular/material/core";
import {Oauth2Module} from "@lm-nx-frontend/oauth2";
import {RouterState, StoreRouterConnectingModule} from "@ngrx/router-store";
import {OAuthModule} from "angular-oauth2-oidc";
import {LmInfUserRootModule} from "@lm-nx-frontend/lm-inf-user-root";
import {LmInfProductRootModule} from "@lm-nx-frontend/lm-inf-product-root";
import {LmInfCategoryRootModule} from "@lm-nx-frontend/lm-inf-category-root";
import {
  LmInfProductConfiguratorRootModule
} from "../../../../libs/lm-inf-product-configurator/src/lib/lm-inf-product-configurator-root.module";


// import {ProductOccModule, UserOccModule} from "../../../../libs/lm-inf-core/src/lib/api/adapters";
// import {
//   AddressBookModule, ConsentManagementModule, MyCouponsModule, MyInterestsModule,
//   NotificationPreferenceModule,
//   AnonymousConsentManagementBannerModule,
//   PaymentMethodsModule
// } from "@lm-nx-frontend/lm-inf-ui";

@NgModule({
  declarations: [AppComponent, SpinnerComponent],
  imports: [
    BrowserAnimationsModule,
    FormlyModule.forRoot({}),

    MatProgressSpinnerModule,
    MatProgressBarModule,

    StoreModule.forRoot(),
    EffectsModule.forRoot(),

    RoutingModule.forRoot(),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: !isDevMode()}),

    BrowserModule,
    RouterModule.forRoot(appRoutes, { // appRoutes
       initialNavigation: 'enabledBlocking',
       anchorScrolling: 'enabled'
    }),

    // AppRoutingModule, spartacus

    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // LmInfApiModule.forRoot({
    //   baseURL: environment.baseURL
    // }),
    Oauth2Module.forRoot({
       host: environment.oauth.host,
       clientId: environment.oauth.clientId
    }),
    // NgHttpLoaderModule.forRoot(),
    MatProgressSpinnerModule,
    // InfClickOutsideModule,
    // InfCartModule,

    OAuthModule.forRoot(),
    LmInfUserRootModule,

    InfClickOutsideModule,

    LmInfCoreModule,
    LmInfUiModule,

    LmInfProductConfiguratorRootModule,

    // //
    // // Auth Core
    // AuthModule.forRoot(),
    //
    // LogoutModule, // will be come part of auth package
    // LoginRouteModule, // will be come part of auth package
    //
    // // Basic Cms Components
    // HamburgerMenuModule,
    // SiteContextSelectorModule,
    // LinkModule,
    // BannerModule,
    // // CmsParagraphModule,
    // // TabParagraphContainerModule,
    // // BannerCarouselModule,
    // CategoryNavigationModule,
    // NavigationModule,
    // FooterNavigationModule,
    // PageTitleModule,
    // BreadcrumbModule,
    // // PDFModule,
    // ScrollToTopModule,
    // // VideoModule,
    //
    // // User Core
    // UserModule,
    // UserOccModule,
    // // User UI
    // AddressBookModule,
    // PaymentMethodsModule,
    // NotificationPreferenceModule,
    // MyInterestsModule,
    // // StockNotificationModule,
    // ConsentManagementModule,
    // // MyCouponsModule,
    //
    // // Anonymous Consents Core
    // AnonymousConsentsModule.forRoot(),
    // // Anonymous Consents UI
    // AnonymousConsentsDialogModule,
    // AnonymousConsentManagementBannerModule,
    //
    // // Product Core
    ProductModule.forRoot(),
    ProductOccModule,
    //
    // // Product UI
    // ProductDetailsPageModule,
    // ProductListingPageModule,
    // ProductListModule,
    // SearchBoxModule,
    // ProductFacetNavigationModule,
    // ProductTabsModule,
    // ProductCarouselModule,
    // ProductReferencesModule,
    // ProductImagesModule,
    // ProductSummaryModule,
    // ProductIntroModule,
    //
    // // Cost Center
    // CostCenterOccModule,
    //
    // // Page Events
    // NavigationEventModule,
    // HomePageEventModule,
    // ProductPageEventModule,


    LmInfInquiryModule,
    LmInfCustomerRootModule,
    LmInfProductRootModule,
    LmInfCategoryRootModule,


    // /************************* Opt-in features *************************/
    //
    // ExternalRoutesModule.forRoot(), // to opt-in explicitly, is added by default schematics
    // // JsonLdBuilderModule,
    // //
    // // /************************* Feature libraries *************************/
    // // UserFeatureModule,
    // //
    // InfCartModule,
    // // CartBaseFeatureModule,

    // to be removed
    InfCartModule,

    // CartBaseFeatureModule,

    // // WishListFeatureModule,
    // // SavedCartFeatureModule,
    // // QuickOrderFeatureModule,
    // // ImportExportFeatureModule,
    // //
    // // OrderFeatureModule,
    // //
    // // CheckoutFeatureModule,
    // //
    // // TrackingFeatureModule,
    // //
    // // AsmFeatureModule,
    // //
    // // StorefinderFeatureModule,
    // //
    // // QualtricsFeatureModule,
    // //
    // // SmartEditFeatureModule,
    // //
    // // VariantsFeatureModule,
    // // ImageZoomFeatureModule,
    // //
    // // CustomerTicketingFeatureModule,
    // //
    // // ProductConfiguratorTextfieldFeatureModule,
    // // todo: LmInfProductConfiguratorModule,
    // // ...featureModules,


    // UserAccountRootModule,

    // OrganizationUserRegistrationRootModule,

     FormlyModule.forRoot(),
     ReactiveFormsModule,
     FormlyMaterialModule,
  ],
  providers: [
    provideFuse({
      fuse   : {
        layout : 'classy',
        scheme : 'light',
        screens: {
          sm: '600px',
          md: '960px',
          lg: '1280px',
          xl: '1440px',
        },
        theme  : 'theme-default',
        themes : [
          {
            id  : 'theme-default',
            name: 'Default',
          },
        ],
      },
    }),


    provideConfig(<OccConfig>{
      backend: {
        occ: {
          baseUrl: environment.baseURL
        }
      },
    }),

    provideConfig(layoutConfig),
    provideConfig(mediaConfig),
    ...defaultCmsContentProviders,
    // b2b
    // provideConfig(defaultB2bOccConfig),
    // provideConfig(defaultB2BCheckoutConfig),
    provideConfig(<SiteContextConfig>{
      context: {
        urlParameters: [], // 'language'
        baseSite: ['portal'],
      },
    }),
    provideConfig(<RoutingConfig>{
      // custom routing configuration for e2e testing
      routing: {
        protected: true,
        routes: {
          product: {
            paths: ['product/:productCode/:name', 'product/:productCode'],
            paramsMapping: { name: 'slug' },
          },
        },
      },
    }),
    provideConfig(<PWAModuleConfig>{
      pwa: {
        enabled: true,
        addToHomeScreen: true,
      },
    }),

    provideConfig(<AuthConfig> {
      authentication: {
        baseUrl: 'https://' + environment.oauth.host,
        tokenEndpoint: '/oauth2/token',
        revokeEndpoint: '/oauth2/revoke',
        loginUrl: '/oauth2/authorize',
        client_id: environment.oauth.clientId,
        // client_secret: 'secret',
        userinfoEndpoint: '/oauth2/userinfo',
        client_secret: undefined,
        OAuthLibConfig: {
          responseType: 'code',
          scope: 'openid',
          disablePKCE: false,

          customTokenParameters: ['token_type'],
          strictDiscoveryDocumentValidation: false,
          skipIssuerCheck: true,
          oidc: false,
          clearHashAfterLogin: false,
        }
      },

    }),

    provideConfig(<CmsConfig>{
      featureModules: {
        [USER_ACCOUNT_FEATURE]: {
          module: () =>
            import('@lm-nx-frontend/lm-inf-customer').then(
              (m) => m.UserAccountModule
            ),
        },
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        fallbackLang: 'en',
        resources: translations,
        chunks: translationChunksConfig,
      },
    }),


    provideConfig(<I18nConfig>{
      i18n: {
        resources: userAccountTranslations,
        chunks: userAccountTranslationChunksConfig,
      },
    }),

    provideConfig(<CmsConfig>{
      featureModules: {
        [ORGANIZATION_USER_REGISTRATION_FEATURE]: {
          module: () =>
            import('@lm-nx-frontend/lm-inf-customer').then(
              (m) => m.OrganizationUserRegistrationModule
            ),
        },
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: organizationUserRegistrationTranslations,
        chunks: organizationUserRegistrationTranslationChunksConfig,
      },
    }),

  ],
  bootstrap: [AppComponent],
  // bootstrap: [StorefrontComponent],
})
export class AppModule {}
