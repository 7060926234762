<ng-container>
  <fieldset>
    <legend style="display: none">{{ attribute.label }}</legend>
    <div id="{{ createAttributeIdForConfigurator(attribute) }}">
      <div class="form-check flex items-center">
        <div class="relative flex gap-x-3">
          <input
            id="{{
              createAttributeValueIdForConfigurator(
                attribute,
                attributeValue.valueCode
              )
            }}"
            type="checkbox"
            class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            [value]="attributeValue.valueCode"
            [cxFocus]="{
              key: attribute.name + '-' + attributeValue.name
            }"
            (change)="onSelect()"
            [formControl]="attributeCheckBoxForm"
            name="{{ createAttributeIdForConfigurator(attribute) }}"
            [attr.aria-label]="
              attributeValue.valuePrice &&
              attributeValue.valuePrice?.value !== 0
                ? ('configurator.a11y.valueOfAttributeFullWithPrice'
                  )
                : ('configurator.a11y.valueOfAttributeFull'
                  )
            "
            [attr.aria-describedby]="
              createAttributeUiKey('label', attribute.name) +
              ' ' +
              createAttributeUiKey('attribute-msg', attribute.name)
            "
          />

          <div class="text-sm leading-6">
            <label
              id="{{
              createValueUiKey(
                'label',
                attribute.name,
                attributeValue.valueCode
              )
            }}"
              for="{{
              createAttributeValueIdForConfigurator(
                attribute,
                attributeValue.valueCode
              )
            }}"
              aria-hidden="true"
              class="form-check-label"
            >{{
              getLabel(
                expMode,
                attributeValue.valueDisplay,
                attributeValue.valueCode
              )
              }}</label>
          </div>
        </div>
        <div class="ml-4 cx-value-price">
          <cx-configurator-price
            [formula]="extractValuePriceFormulaParameters(attributeValue)"
          ></cx-configurator-price>
        </div>
      </div>
    </div>
  </fieldset>
</ng-container>
