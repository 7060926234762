/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import {
  CmsNavigationComponent,
  CmsNavigationNode,
  CmsService,
  SemanticPathService,
} from '@lm-nx-frontend/lm-inf-core';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { NavigationNode } from './navigation-node.model';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    protected cmsService: CmsService,
    protected semanticPathService: SemanticPathService
  ) {}

  public createNavigation(
    data$: Observable<CmsNavigationComponent>
  ): Observable<NavigationNode> {
    return combineLatest([data$, this.getNavigationNode(data$)]).pipe(
      map(([data, nav]) => {
        return {
          title: data.name,
          children: [nav],
        };
      })
    );
  }

  /**
   * returns an observable with the `NavigationNode` for the given `CmsNavigationComponent`.
   * This function will load the navigation underlying entries and children if they haven't been
   * loaded so far.
   */
  public getNavigationNode(
    data$: Observable<CmsNavigationComponent>
  ): Observable<NavigationNode> {
    if (!data$) {
      return EMPTY;
    }
    return data$.pipe(
      filter((data) => !!data),
      switchMap((data) => {
        const navigation = data?.properties?.navigationNode ? data.properties.navigationNode : data;
        return this.cmsService
          .getNavigationEntryItems(navigation.id ?? '')
          .pipe(
            tap((items) => {
              if (items === undefined) {
                this.loadNavigationEntryItems(navigation as CmsNavigationNode, true);
                return;
              }
              // we should check whether the existing node items are what expected
              const expectedItems: {
                superType: string | undefined;
                id: string | undefined;
              }[] = [];
              this.loadNavigationEntryItems(navigation as CmsNavigationNode, false, expectedItems);
              const existingItems = Object.keys(items).map(
                (key) => items[key].id ?? ''
              );
              const missingItems = expectedItems.filter(
                (it) => it.id && !existingItems.includes(it.id)
              );
              if (missingItems.length > 0) {
                this.cmsService.loadNavigationItems(
                  navigation.id ?? '',
                  missingItems
                );
              }
            }),
            filter(Boolean),
            map((items) => this.populateNavigationNode(navigation as CmsNavigationNode, items) ?? {}),
          );
      })
    );
  }

  /**
   * Loads all navigation entry items' type and id. Dispatch action to load all these items
   * @param nodeData
   * @param root
   * @param itemsList
   */
  private loadNavigationEntryItems(
    nodeData: CmsNavigationNode,
    root: boolean,
    itemsList: { superType: string | undefined; id: string | undefined }[] = []
  ): void {
    if (nodeData?.properties?.entries && nodeData?.properties?.entries.length > 0) {
      nodeData.properties.entries.forEach((entry) => {
        itemsList.push({
          superType: entry.itemSuperType,
          id: entry.itemId,
        });
      });
    }

    if (nodeData?.properties?.children && nodeData?.properties?.children.length > 0) {
      nodeData.properties.children.forEach((child) =>
        this.loadNavigationEntryItems(child, false, itemsList)
      );
    }

    if (root && nodeData.id) {
      this.cmsService.loadNavigationItems(nodeData.id, itemsList);
    }
  }

  /**
   * Create a new node tree for the view
   * @param nodeData
   * @param items
   */
  private populateNavigationNode(
    nodeData: CmsNavigationNode,
    items: any
  ): NavigationNode | null {
    const node: NavigationNode = {};

    if (nodeData.properties?.title) {
      // the node title will be populated by the first entry (if any)
      // if there's no nodeData.title available
      node.title = nodeData.properties?.title;
    }

    // populate style classes to apply CMS driven styling
    if (nodeData.properties?.styleClasses) {
      node.styleClasses = nodeData.properties?.styleClasses;
    }
    // populate style attributes to apply CMS driven styling
    if (nodeData.properties?.styleAttributes) {
      node.styleAttributes = nodeData.properties?.styleAttributes;
    }

    if (nodeData.properties?.entries && nodeData.properties?.entries.length > 0) {
      this.populateLink(node, nodeData.properties?.entries[0], items);
    }

    if ((nodeData.properties?.children?.length ?? 0) > 0) {
      const children: NavigationNode[] = (nodeData.properties?.children ?? [])
        .map((child: any) => this.populateNavigationNode(child, items))
        .filter(Boolean) as any;

      if (children.length > 0) {
        node.children = children;
      }
    }

    // return null in case there are no children
    return Object.keys(node).length === 0 ? null : node;
  }

  /**
   * The node link is driven by the first entry.
   */
  private populateLink(node: NavigationNode, entry: any, items: Record<string,  CmsNavigationComponent | CmsNavigationNode | any>) {
    const item = items[`${entry.itemId}_${entry.itemSuperType}`];

    // now we only consider CMSLinkComponent
    if (item?.properties && entry.itemType === 'CMSLinkComponent') {
      if (!node.title) {
        node.title = item?.properties?.linkName;
      }
      const url = this.getLink(item);
      // only populate the node link if we have a visible node
      if (node.title && url) {
        node.url = url;
        // the backend provide boolean value for the target
        // in case the link should be opened in a new window
        if (item?.properties.target === 'true' || item?.properties.target === true) {
          node.target = '_blank';
        }
      }
      // populate style classes to apply CMS driven styling
      if (item?.properties.styleClasses) {
        node.styleClasses = item?.properties.styleClasses;
      }
      // populate style attributes to apply CMS driven styling
      if (item?.properties.styleAttributes) {
        node.styleAttributes = item?.properties.styleAttributes;
      }
      if (item?.properties.class) {
        if (node.styleClasses) {
          node.styleClasses += ' '
        } else {
          node.styleClasses = ''
        }
        node.styleClasses += item?.properties.class;
      }
      if (item?.properties.classActive) {
        node.classActive = item?.properties.classActive;
      }
      if (item?.properties.classActiveExact) {
        node.classActiveExact = item?.properties.classActiveExact;
      }
    }
  }

  /**
   *
   * Gets the URL or link to a related item (category),
   * also taking into account content pages (contentPageLabelOrId)
   * and product pages (productCode)
   */
  protected getLink(item: CmsNavigationComponent | CmsNavigationNode | any): string | string[] | undefined {
    if (item.properties.url) {
      return item.properties.url;
    } else if (item.properties.contentPageLabelOrId) {
      return item.properties.contentPageLabelOrId;
    } else if (item.properties.categoryCode) {
      return this.semanticPathService.transform({
        cxRoute: 'category',
        params: {
          code: item.categoryCode,
          name: item.name,
        },
      });
    } else if (item.properties.productCode) {
      return this.semanticPathService.transform({
        cxRoute: 'product',
        params: {
          code: item.productCode,
          name: item.name,
        },
      });
    }
  }
}

// CHECK SONAR
