<cx-org-sub-list key="customerId" [routerKey]="routerKey" [showHint]="true">
  <a
    *ngIf="isUpdatingUserAllowed"
    actions
    class="link"
    routerLink="create"
    [class.disabled]="!(unit$ | async)?.active"
  >
    {{ 'organization.create' | cxTranslate }}
  </a>
  <cx-org-disable-info
    info
    i18nRoot="orgUnitUsers"
    [displayInfoConfig]="{
      disabledCreate: true,
      disabledEnable: false,
      disabledEdit: false
    }"
  >
  </cx-org-disable-info>
</cx-org-sub-list>
