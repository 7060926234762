/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  ConverterService,
  normalizeHttpError,
  OccEndpointsService,
} from '@lm-nx-frontend/lm-inf-core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {CART_VALIDATION_NORMALIZER, CartValidationAdapter} from "../../core/connectors";
import {CartModificationList} from "../../root/public_api";

@Injectable()
export class OccCartValidationAdapter implements CartValidationAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {}

  validate(cartId: string, userId: string): Observable<CartModificationList> {
    const url = this.occEndpoints.buildUrl('validate', {
      urlParams: { cartId, userId },
    });

    return this.http.post<any>(url, null).pipe(
      catchError((error) => throwError(normalizeHttpError(error))),
      this.converter.pipeable(CART_VALIDATION_NORMALIZER)
    );
  }
}
