import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {catchError, Observable, of, take} from 'rxjs';
import {switchMap, tap} from "rxjs/operators";
import {Session} from "../model/session";
import {Oauth2Service} from "../oauth2.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(
    private readonly authService: Oauth2Service,
    private readonly router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // const currentEnv = environment.environments.find(env => request.url.startsWith(env.apiBaseURL))

    const currentEnv = {

    }

    let result: Observable<any>

    if (
      currentEnv
    ) {
      // @ts-ignore
      result = this.authService.session$.pipe(
        take(1),
        switchMap((user: Session|null) =>
          next.handle(request.clone({
            setHeaders: {
             'X-Api-Key': user?.accessToken.token ?? ''
            }
          }))
        )
      );
    } else {
      result = next.handle(request)
    }

    return result.pipe(
      catchError((resp: any) => {
        if ((resp.status === 500 || resp.status === 0 || resp.status >= 400) && resp.status !== 401) {
          return this.router.navigate(['/error'], {
            queryParams: {
              code: resp?.code ?? resp?.name,
              description: resp?.error?.message ?? resp?.message
            }
          });
        }
        return of(resp)
      })
    );
  }
}
