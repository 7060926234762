/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { Config } from '@lm-nx-frontend/lm-inf-core';

@Injectable({
  providedIn: 'root',
  useExisting: Config,
})
export abstract class FormConfig {
  form?: {
    passwordVisibilityToggle: boolean;
  };
}

declare module '@lm-nx-frontend/lm-inf-core' {
  interface Config extends FormConfig {}
}
