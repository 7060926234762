<section
  class="inner"
  *ngIf="(facetList$ | async)?.facets as facets"
  [attr.aria-label]="'productFacetNavigation.filterBy.facet' | cxTranslate"
  [cxFocus]="isDialog ? dialogFocusConfig : {}"
  [tabindex]="-1"
  (esc)="close($event)"
  (click)="block($event)"
>
  <div class="list-header">
    <h4>
      {{ 'productList.filterBy.label' | cxTranslate }}
    </h4>
    <button
      type="button"
      class="close"
      [attr.aria-label]="'common.close' | cxTranslate"
      (click)="close()"
    >
      <cx-icon aria-hidden="true" [type]="iconTypes.CLOSE"></cx-icon>
    </button>
  </div>

  <!--
      Here we'd like to introduce configurable facet components,
      either by using specific configuration or generic sproutlets
  -->
  <cx-facet
    *ngFor="let facet of facets"
    #facetRef
    [facet]="facet"
    [class.expanded]="isExpanded(facet) | async"
    [class.collapsed]="isCollapsed(facet) | async"
    role="group"
    attr.aria-label="{{
      'productFacetNavigation.ariaLabelItemsAvailable'
        | cxTranslate
          : {
              name: facet.name,
              count: facet?.values?.length
            }
    }}"
  ></cx-facet>
</section>
