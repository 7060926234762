import {HttpErrorModel, ItemsModel, StateUtils} from "@lm-nx-frontend/lm-inf-core";
import {ProductPart} from "../../models/part";



export const PRODUCT_PARTS_DATA = '[Product] Parts Data';

// action types
export type ProductPartsAction =
  | LoadProductParts
  | LoadProductPartsSuccess
  | LoadProductPartsFailure
;

export const LOAD_PRODUCT_PARTS = '[User] Load Product Parts';

export interface LoadProductPartsPayload {
  userId: string
  productId: string
}

export class LoadProductParts extends StateUtils.EntityLoadAction {
  override readonly type = LOAD_PRODUCT_PARTS;

  constructor(
    public payload: LoadProductPartsPayload,
  ) {
    super(PRODUCT_PARTS_DATA, payload.productId);
  }
}

export const LOAD_PRODUCT_PARTS_FAILURE = '[User] Load Product Parts Failure';

export interface LoadProductPartsFailurePayload {
  userId: string
  productId: string
}

export class LoadProductPartsFailure extends StateUtils.EntityFailAction {
  override readonly type = LOAD_PRODUCT_PARTS_FAILURE;

  constructor(
    public payload: LoadProductPartsFailurePayload,
    public error: HttpErrorModel,
  ) {
    super(PRODUCT_PARTS_DATA, payload.productId);
  }
}


export const LOAD_PRODUCT_PARTS_SUCCESS = '[User] Load Product Parts Success';

export interface LoadProductPartsSuccessPayload {
  userId: string
  productId: string
  parts: ItemsModel<ProductPart>
}

export class LoadProductPartsSuccess extends StateUtils.EntitySuccessAction {
  override readonly type = LOAD_PRODUCT_PARTS_SUCCESS;

  constructor(
    public override payload: LoadProductPartsSuccessPayload,
  ) {
    super(PRODUCT_PARTS_DATA, payload.productId);
  }
}
