<div class="arrow"></div>
<div class="popover-body" [cxFocus]="focusConfig">
  <div class="cx-close-row">
    <button
      *ngIf="displayCloseButton"
      type="button"
      class="close"
      (keydown.enter)="close($event)"
      (keydown.space)="close($event)"
      (click)="close($event)"
    >
      <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
    </button>
  </div>
  <ng-container *ngIf="isTemplate(content)">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>
  <span *ngIf="isString(content)">{{ content }}</span>
</div>
