<ng-select
  [searchable]="false"
  [clearable]="false"
  placeholder="{{ placeholder }}"
  (change)="sortList($event)"
  [ngModel]="selectedOption"
  [cxNgSelectA11y]="{
    ariaLabel: ariaLabel || ('productList.sortResults' | cxTranslate),
    ariaControls: ariaControls
  }"
>
  <ng-option *ngFor="let sort of sortOptions" [value]="sort.code">{{
    sort.name ? sort.name : sortLabels && sort.code ? sortLabels[sort.code] : ''
  }}</ng-option>
</ng-select>
