import {Action, createReducer, on} from "@ngrx/store";
import {User} from "../../models/user";
import {CmsActions, EntityLoaderState, Page} from "@lm-nx-frontend/lm-inf-core";
import {defaultUserUserState, UserUserState} from "./user.state";
import {LOAD_USER_SUCCESS, LoadUserSuccess, UserAction} from "./user.actions";

export function userReducer(
  state: UserUserState = defaultUserUserState,
  action: Action,
): UserUserState {
  switch (action.type) {
    case LOAD_USER_SUCCESS: {
      const user = (action as LoadUserSuccess).payload;
      return {
        ...state,
        currentId: user.userId,
        entities: { ...state.entities, [user.userId ?? '']: {
          value: user.user

        } },
      };
    }
  }

  return state;
}
