import {Component, OnDestroy, OnInit} from '@angular/core';
import {SpinnerComponent} from "./spinner.component";
// import {CartFacade} from "@lm-nx-frontend/inf-cart";
// import {Oauth2Service} from "@lm-nx-frontend/oauth2";
import {Router} from "@angular/router";
import {filter, of, Subscription} from "rxjs";
import {CartFacade} from "@lm-nx-frontend/inf-cart";
import {NavigationService} from "@lm-nx-frontend/lm-inf-ui";
import {RoutingService} from "@lm-nx-frontend/lm-inf-core";
import {isNavigating} from "../../../../libs/lm-inf-core/src/lib/routing/store/selectors/routing.selector";
import {User, UserFacade} from "@lm-nx-frontend/lm-inf-user-root";

@Component({
  selector: 'lm-nx-frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  cartItemCount$ = this.cartFacade.itemCount$
  matSpinner = SpinnerComponent

  sidebarVisible = false
  userMenuVisible = false

  navigationLoading = true

  subscription = new Subscription()

  currentUser: User|null = null

  constructor(
    private readonly cartFacade: CartFacade,
    // private readonly oauth2Service: Oauth2Service,
    private readonly router: Router,
    private readonly routingService: RoutingService,
    private readonly userFacade: UserFacade
  ) {

  }

  closeSidebar(): void {
    this.sidebarVisible = false
  }
  openSidebar(): void {
    this.sidebarVisible = true
  }

  onUserMenuShow(): void {
    this.userMenuVisible = true
  }
  onUserMenuClose(): void {
    this.userMenuVisible = false
  }

  get logoutHref(): string {
    //return this.oauth2Service.logout()
    return '/logout'
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

  ngOnInit(): void {
    this.subscription.add(
      this.routingService.isNavigating().subscribe(isNavigating => this.navigationLoading = isNavigating)
    )
    this.subscription.add(
      this.userFacade.currentUser$.subscribe(currentUser => {
        this.currentUser = currentUser
      })
    )
  }
}
