<div [ngClass]="getCssClassesForMessage" class="cx-message">
  <div class="cx-message-content">
    <div class="cx-message-header">
      <span class="cx-message-icon">
        <cx-icon [type]="getIconType"></cx-icon>
      </span>

      <span class="cx-message-text">
        {{ text }}
        <button
          *ngIf="accordionText"
          (click)="showBody = !showBody"
          [attr.aria-expanded]="showBody"
          [attr.aria-label]="accordionText"
          aria-hidden="true"
          class="cx-message-accordion-button link cx-action-link"
          type="button"
        >
          {{ accordionText }}
          <cx-icon
            [type]="showBody ? 'CARET_UP' : 'CARET_DOWN'"
            class="cx-message-accordion-icon"
          ></cx-icon>
        </button>

        <button
          *ngIf="actionButtonText"
          (click)="buttonAction.emit()"
          [cxAtMessage]="actionButtonMessage"
          aria-hidden="true"
          class="btn btn-link cx-action-link"
          type="button"
        >
          {{ actionButtonText }}
        </button>
      </span>

      <button
        *ngIf="isVisibleCloseButton"
        (click)="closeMessage.emit()"
        [attr.aria-label]="'common.close' | cxTranslate"
        [cxAtMessage]="'common.close' | cxTranslate"
        aria-hidden="true"
        class="close"
        type="button"
      >
        <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
      </button>
    </div>

    <div *ngIf="showBody || !accordionText" class="cx-message-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
