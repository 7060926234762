import {EntityLoaderState} from "@lm-nx-frontend/lm-inf-core";
import {CustomerAddress} from "../../model/customer";
import {CustomerCountry} from "../../model/customerCountry";

export interface CustomerCountryState extends EntityLoaderState<CustomerCountry> {
  ids: string[]
  loading: number
}

export const defaultCustomerCountryState: CustomerCountryState = {
  entities: {},
  ids: [],
  loading: 0,
}
