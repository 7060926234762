<div id="{{ createGroupId(group.id) }}" role="tabpanel">
  <ng-container *ngIf="displayConflictDescription(group)">
    <cx-configurator-conflict-description
      [currentGroup]="group"
    ></cx-configurator-conflict-description>
  </ng-container>
  <div
    class="pt-6"
    [class.hidden]="!attribute.visible"
    *ngFor="let attribute of group.attributes; let indexOfAttribute = index"
  >
    <ng-container *ngIf="isConflictGroupType(group.groupType)">
      <cx-configurator-conflict-suggestion
        [currentGroup]="group"
        [attribute]="attribute"
        [suggestionNumber]="indexOfAttribute"
      ></cx-configurator-conflict-suggestion>
    </ng-container>

    <ng-container *ngIf="activeLanguage$ | async as activeLanguage">
      <div
        [cxConfiguratorAttributeComponent]="{
          componentKey: 'Header',
          attribute: attribute,
          owner: owner,
          group: group,
          language: activeLanguage,
          expMode: (expMode | async) ?? false,
          isNavigationToGroupEnabled: isNavigationToGroupEnabled
        }"
      ></div>

      <div
        [cxConfiguratorAttributeComponent]="{
          componentKey: getComponentKey(attribute),
          attribute: attribute,
          owner: owner,
          group: group,
          language: activeLanguage,
          expMode: (expMode | async) ?? false
        }"
      ></div>

      <div
        [cxConfiguratorAttributeComponent]="{
          componentKey: 'Footer',
          attribute: attribute,
          owner: owner,
          group: group,
          language: activeLanguage,
          expMode: (expMode | async) ?? false
        }"
      ></div>
    </ng-container>
  </div>
</div>
