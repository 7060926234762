import {APP_INITIALIZER, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {LmInfApiModule} from "@lm-nx-frontend/lm-inf-api";
import {UserAccountOccModule} from "../../../lm-inf-user/src/lib/account/occ/user-account-occ.module";
import {ActionReducerMap, combineReducers, Store, StoreModule} from "@ngrx/store";
import {userReducer} from "./store/user/user.reducer";
import {Oauth2Module} from "@lm-nx-frontend/oauth2";
import {CmsState} from "@lm-nx-frontend/lm-inf-core";
import {UserState} from "./store/state";
import {EffectsModule} from "@ngrx/effects";

@NgModule({
  imports: [
    CommonModule,
    LmInfApiModule,
    Oauth2Module,
    UserAccountOccModule,
    StoreModule.forFeature('user', combineReducers({
      user: userReducer,
    })),
    EffectsModule.forFeature([
    ])
  ],
  providers: [
  ]
})
export class LmInfUserRootModule {}
