/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {from, Observable, tap} from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import {normalizeHttpError} from "@lm-nx-frontend/lm-inf-core";
import {AddressConnector} from "../../connectors/address.connector";
import {
  LOAD_COUNTRIES,
  LoadCustomerCountries,
  LoadCustomerCountriesFailure,
  LoadCustomerCountriesSuccess
} from "./country.actions";
import {CountryConnector} from "../../connectors/country.connector";


@Injectable()
export class CountryEffects {
  loadCountries$: Observable<
    | LoadCustomerCountries
    | LoadCustomerCountriesSuccess
    | LoadCustomerCountriesFailure
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_COUNTRIES),
      map((action: LoadCustomerCountries) => action.payload),
      concatMap((payload) => {
        return this.countryConnector
          .search(
            payload.userId,
            payload.customerId,
            '',
          )
          .pipe(
            map(
              (response) =>
                new LoadCustomerCountriesSuccess(
                  {
                    userId: payload.userId,
                    customerId: payload.customerId,
                    countries: response.items,
                  }
                )
            ),
            catchError((error) =>
              from([
                new LoadCustomerCountriesFailure(
                  payload,
                  normalizeHttpError(error)
                ),
              ])
            )
          );
      }),
      // withdrawOn(this.contextChange$)
    )
  );

  constructor(
    private actions$: Actions,
    private countryConnector: CountryConnector
  ) {}
}
