<cx-generic-link
  *ngIf="data$ | async as data"
  [url]="data.properties?.url ?? ''"
  [class]="data.properties?.class ?? ''"
  [classActive]="data.properties?.classActive ?? ''"
  [style]="data.properties?.styleAttributes"
  [target]="getTarget(data)"
  >{{ data.properties?.linkName }}</cx-generic-link
>

