/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {AuthConfigService, AuthService, AuthStorageService, AuthToken, OAuthFlow} from '@lm-nx-frontend/lm-inf-core';
import {EMPTY, exhaustMap, Observable, of} from 'rxjs';
import {filter, switchMap, take, tap} from 'rxjs/operators';
import { CmsPageGuard } from '../../../cms-structure/guards/cms-page.guard';

/**
 * Guards the _login_ route.
 *
 * Takes care of routing the user to a auth server login page (if implicit or code flow is used).
 * In case of Resource Owner Password Flow just renders the page as normal CMS page.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(
    protected authService: AuthService,
    protected authStorageService: AuthStorageService,
    protected authConfigService: AuthConfigService,
    protected cmsPageGuard: CmsPageGuard
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.authService.checkOAuthParamsInUrlInProgress$.pipe(
      filter(v => !v),
      exhaustMap(() => this.authStorageService.getToken().pipe(take(1))),
      switchMap((userToken: AuthToken) => {
        const isUserLoggedIn = Boolean(userToken?.access_token)

        if (isUserLoggedIn && userToken?.expires_at) {
          return this.cmsPageGuard.canActivate(route, state);
        }
        const redirected = this.authService.loginWithRedirect();
        return of(false)
      }),
      // switchMap((token) =>
      //   this.authService.isUserLoggedIn().pipe(
      //     take(1),
      //     switchMap((isUserLoggedIn) => {
      //       if (
      //         this.authConfigService.getOAuthFlow() ===
      //         OAuthFlow.ResourceOwnerPasswordFlow ||
      //         isUserLoggedIn
      //       ) {
      //         return this.cmsPageGuard.canActivate(route, state);
      //       } else {
      //         // This method can trigger redirect to OAuth server that's why we don't return anything in this case
      //         const redirected = this.authService.loginWithRedirect();
      //
      //         // added to prevent EMPTY error's
      //         return of(false);
      //
      //         if (!redirected) {
      //           return of(false);
      //         }
      //         return EMPTY;
      //       }
      //     })
      //   )
      // )
    )
  }
}
