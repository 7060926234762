<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <div *ngIf="$any(cart.totalItems) > 0" class="cart-details-wrapper">
      <cx-cart-validation-warnings></cx-cart-validation-warnings>

      <h2 class="cx-total">
        {{ 'cartDetails.cartName' | cxTranslate: { code: cart.code } }}
      </h2>

      <cx-promotions
        [promotions]="
          (cart.appliedOrderPromotions || []).concat(
            cart.potentialOrderPromotions || []
          )
        "
      ></cx-promotions>

      <cx-cart-item-list
        [items]="entries"
        [cartIsLoading]="!(cartLoaded$ | async)"
        [promotionLocation]="promotionLocation"
        [options]="{
          isSaveForLater: false,
          optionalBtn: saveForLaterBtn
        }"
      ></cx-cart-item-list>
    </div>
  </ng-container>
</ng-container>

<ng-template let-ctx #saveForLaterBtn>
  <button
    *ngIf="selectiveCartEnabled"
    class="btn btn-tertiary cx-sfl-btn"
    [disabled]="ctx.loading"
    (click)="saveForLater(ctx.item)"
    type="button"
  >
    {{ 'saveForLaterItems.saveForLater' | cxTranslate }}
  </button>
</ng-template>
