<ng-container *ngIf="b2bUserModel as model">
  <ng-template #details>
    <div class="popover-details">
      <div class="property">
        <label>{{ 'orgUser.name' | cxTranslate }}</label>
        <a
          class="value"
          [routerLink]="
            {
              cxRoute: 'orgUserDetails',
              params: model
            } | cxUrl
          "
          >{{ model.name }}</a
        >
      </div>

      <div class="property full-width">
        <label>{{ 'orgUser.uid' | cxTranslate }}</label>
        <span class="value">
          {{ model.uid }}
        </span>
      </div>

      <div class="property">
        <label>{{ 'orgUser.roles' | cxTranslate }}</label>
        <ul class="value">
          <ng-container *ngFor="let role of model.roles">
            <li
              *ngIf="availableRoles.includes(role)"
              [innerText]="'organization.userRoles.' + role | cxTranslate"
            ></li>
          </ng-container>
          <li *ngIf="model.roles?.length === 0">-</li>
        </ul>
      </div>

      <div class="property" *ngIf="hasRight(model)">
        <label>{{ 'orgUser.rights' | cxTranslate }}</label>
        <ul class="value">
          <ng-container *ngFor="let right of model.roles">
            <li
              *ngIf="availableRights.includes(right)"
              [innerText]="'organization.userRights.' + right | cxTranslate"
            ></li>
          </ng-container>
          <li *ngIf="model.roles?.length === 0">-</li>
        </ul>
      </div>

      <div class="property">
        <label>{{ 'orgUser.orgUnit' | cxTranslate }}</label>
        <a
          class="value"
          [routerLink]="
            {
              cxRoute: 'orgUnitDetails',
              params: model.orgUnit
            } | cxUrl
          "
        >
          {{ model.orgUnit?.name }}
        </a>
      </div>
    </div>
  </ng-template>

  <button
    class="button text"
    [cxPopover]="details"
    [cxPopoverOptions]="{
      placement: 'auto',
      class: 'my-company-popover',
      appendToBody: true,
      displayCloseButton: true
    }"
  >
    {{ model?.name }}
  </button>
</ng-container>
