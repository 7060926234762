import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {provideDefaultConfig} from "@lm-nx-frontend/lm-inf-core";
import {defaultOccInquiryConfig} from "./model/default-occ-inquiry-config";

@NgModule({
  imports: [CommonModule],
  providers: [
    provideDefaultConfig(defaultOccInquiryConfig),
  ]
})
export class LmInfInquiryModule {}
