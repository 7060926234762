/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import {CartBaseOccModule} from "./occ/cart-base-occ.module";
import {CartBaseCoreModule} from "./core/cart-base-core.module";
import {CartBaseComponentsModule} from "./components/cart-base-components.module";

@NgModule({
  imports: [CartBaseCoreModule, CartBaseOccModule, CartBaseComponentsModule],
})
export class CartBaseModule {}
