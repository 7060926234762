<cx-org-list [hideAddButton]="!isUpdatingUnitAllowed">
  <ng-container actions>
    <button class="link" (click)="expandAll()">
      {{ 'orgUnit.tree.expandAll' | cxTranslate }}
    </button>
    <button class="link" (click)="collapseAll()">
      {{ 'orgUnit.tree.collapseAll' | cxTranslate }}
    </button>
  </ng-container>
</cx-org-list>
