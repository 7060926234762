import { Injectable } from '@angular/core';
import {
  EntitiesModel,
  ItemsModel, ItemsSearchConfig,
  OccEndpointsService,
  ProductActions,
  SearchConfig
} from "@lm-nx-frontend/lm-inf-core";
import {Observable} from "rxjs";
import {Inquiry} from "../model/inquiry";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class InquiriesConnector {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly occEndpointsService: OccEndpointsService,
  ) { }

  get(userId: string, inquiryId: string): Observable<Inquiry> {
    const url = this.occEndpointsService.buildUrl('inquiry', {
      urlParams: {
        userId,
        inquiryId,
      },
    })

    return this.httpClient.get<Inquiry>(url);
  }

  create(userId: string, inquiry: Partial<Inquiry>): Observable<Inquiry> {
    const url = this.occEndpointsService.buildUrl('createInquiry', {
      urlParams: {
        userId,
      },
    })

    return this.httpClient.post<Inquiry>(url, inquiry);
  }

  search(
    userId: string,
    query: string | undefined,
    searchConfig?: ItemsSearchConfig
  ): Observable<ItemsModel<Inquiry>> {
    const url = this.occEndpointsService.buildUrl('inquiries', {
      urlParams: {
        userId,
      },
      queryParams: {
        ...searchConfig,
        query,
      }
    })

    return this.httpClient.get<ItemsModel<Inquiry>>(url);
  }

  getList(
    userId: string,
    params?: SearchConfig
  ): Observable<ItemsModel<Inquiry>> {
    const url = this.occEndpointsService.buildUrl('inquiries', {
      urlParams: {
        userId,
      },
      queryParams: params
    })

    return this.httpClient.get<ItemsModel<Inquiry>>(url);
  }
}
