import {HttpErrorModel, StateUtils} from "@lm-nx-frontend/lm-inf-core";
import {CustomerAddress} from "../../model/customer";

export const LOAD_ADDRESS = '[Customer] Load Address';
export const LOAD_ADDRESS_SUCCESS = '[Customer] Load Address Success';
export const LOAD_ADDRESS_FAILURE = '[Customer] Load Address Failure';

export const CREATE_ADDRESS = '[Customer] Create Address';
export const CREATE_ADDRESS_FAILURE = '[Customer] Create Address Failure';
export const CREATE_ADDRESS_SUCCESS = '[Customer] Create Address Success';

export const ADDRESS_DATA = '[Customer] Address Data';

// action types
export type CustomerAddressAction =
  | LoadCustomerAddress
  | LoadCustomerAddressSuccess
;

export interface LoadCustomerAddressPayload {
  userId: string
  customerId: string
}

export class LoadCustomerAddress extends StateUtils.EntityLoadAction {
  override readonly type = LOAD_ADDRESS;

  constructor(
    public payload: LoadCustomerAddressPayload,
  ) {
    super(ADDRESS_DATA, payload.customerId);
  }
}

export interface LoadCustomerAddressSuccessPayload {
  userId: string
  customerId: string
  addresses: CustomerAddress[]
}

export class LoadCustomerAddressSuccess extends StateUtils.EntitySuccessAction {
  override readonly type = LOAD_ADDRESS_SUCCESS;

  constructor(
    public override payload: LoadCustomerAddressSuccessPayload,
  ) {
    super(ADDRESS_DATA, payload.customerId);
  }
}

export interface LoadCustomerAddressFailurePayload {
  userId: string
  customerId: string
}

export class LoadCustomerAddressFailure extends StateUtils.EntityFailAction {
  override readonly type = LOAD_ADDRESS_FAILURE;

  constructor(
    public payload: LoadCustomerAddressFailurePayload,
    public error: HttpErrorModel,
  ) {
    super(ADDRESS_DATA, payload.customerId);
  }
}






export interface CreateCustomerAddressPayload {
  userId: string
  customerId: string
  address: CustomerAddress
}

export class CreateCustomerAddress extends StateUtils.EntityLoadAction {
  override readonly type = CREATE_ADDRESS;

  constructor(
    public payload: CreateCustomerAddressPayload,
  ) {
    super(ADDRESS_DATA, payload.customerId);
  }
}

export interface CreateCustomerAddressFailurePayload {
  userId: string
  customerId: string
  address: CustomerAddress
}

export class CreateCustomerAddressFailure extends StateUtils.EntityFailAction {
  override readonly type = CREATE_ADDRESS_FAILURE;

  constructor(
    public payload: CreateCustomerAddressFailurePayload,
    public error: HttpErrorModel,
  ) {
    super(ADDRESS_DATA, payload.customerId);
  }
}

export interface CreateCustomerAddressSuccessPayload {
  userId: string
  customerId: string
  address: CustomerAddress
}

export class CreateCustomerAddressSuccess extends StateUtils.EntitySuccessAction {
  override readonly type = CREATE_ADDRESS_SUCCESS;

  constructor(
    public override payload: CreateCustomerAddressSuccessPayload,
  ) {
    super(ADDRESS_DATA, payload.customerId);
  }
}













export const EDIT_ADDRESS = '[Customer] Edit Address';
export const EDIT_ADDRESS_SUCCESS = '[Customer] Edit Address Success';
export const EDIT_ADDRESS_FAILURE = '[Customer] Edit Address Failure';

export interface EditCustomerAddressPayload {
  userId: string
  customerId: string
  customerAddress: CustomerAddress
}

export class EditCustomerAddress extends StateUtils.EntityLoadAction {
  override readonly type = EDIT_ADDRESS;

  constructor(
    public payload: EditCustomerAddressPayload,
  ) {
    super(ADDRESS_DATA, payload.customerAddress.id);
  }
}

export interface EditCustomerAddressSuccessPayload {
  userId: string
  customerId: string
  customerAddress: CustomerAddress
}

export class EditCustomerAddressSuccess extends StateUtils.EntitySuccessAction {
  override readonly type = EDIT_ADDRESS_SUCCESS;

  constructor(
    public override payload: EditCustomerAddressSuccessPayload,
  ) {
    super(ADDRESS_DATA, payload.customerAddress.id);
  }
}

export interface EditCustomerAddressFailurePayload {
  userId: string
  customerId: string
  customerAddress: CustomerAddress
}

export class EditCustomerAddressFailure extends StateUtils.EntityFailAction {
  override readonly type = EDIT_ADDRESS_SUCCESS;

  constructor(
    public payload: EditCustomerAddressFailurePayload,
    public error: HttpErrorModel,
  ) {
    super(ADDRESS_DATA, payload.customerAddress.id);
  }
}
