/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Observable } from 'rxjs';
import {User} from "../../root/model";

export abstract class UserAccountAdapter {
  abstract load(userId: string): Observable<User>;
}
