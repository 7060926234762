<section
  class="cx-page-section container"
  *ngIf="!(isLoading$ | async); else loading"
>
  <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="cx-section">
        <form (ngSubmit)="submitForm()" [formGroup]="registerForm">
          <div class="form-group">
            <label>
              <span class="label-content">{{
                'register.title' | cxTranslate
              }}</span>
              <ng-select
                [clearable]="false"
                [items]="titles$ | async"
                [placeholder]="'register.selectTitle' | cxTranslate"
                [searchable]="false"
                bindLabel="name"
                bindValue="code"
                formControlName="titleCode"
                id="title-select"
                [cxNgSelectA11y]="{
                  ariaLabel: 'register.title' | cxTranslate
                }"
              >
              </ng-select>
            </label>
          </div>

          <div class="form-group">
            <label>
              <span class="label-content">{{
                'register.firstName.label' | cxTranslate
              }}</span>
              <input
                required="true"
                class="form-control"
                type="text"
                name="firstname"
                placeholder="{{
                  'register.firstName.placeholder' | cxTranslate
                }}"
                formControlName="firstName"
              />
              <cx-form-errors
                [control]="registerForm.get('firstName')"
              ></cx-form-errors>
            </label>
          </div>

          <div class="form-group">
            <label>
              <span class="label-content">{{
                'register.lastName.label' | cxTranslate
              }}</span>
              <input
                required="true"
                class="form-control"
                type="text"
                name="lastname"
                placeholder="{{
                  'register.lastName.placeholder' | cxTranslate
                }}"
                formControlName="lastName"
              />
              <cx-form-errors
                [control]="registerForm.get('lastName')"
              ></cx-form-errors>
            </label>
          </div>

          <div class="form-group">
            <label>
              <span class="label-content">{{
                'register.emailAddress.label' | cxTranslate
              }}</span>
              <input
                required="true"
                class="form-control"
                type="email"
                name="email"
                placeholder="{{
                  'register.emailAddress.placeholder' | cxTranslate
                }}"
                formControlName="email"
              />
              <cx-form-errors
                [control]="registerForm.get('email')"
              ></cx-form-errors>
            </label>
          </div>

          <div class="form-group">
            <label>
              <span class="label-content">{{
                'register.password.label' | cxTranslate
              }}</span>
              <input
                required="true"
                class="form-control"
                type="password"
                name="password"
                placeholder="{{
                  'register.password.placeholder' | cxTranslate
                }}"
                formControlName="password"
                [attr.aria-label]="
                  'register.password.placeholder' | cxTranslate
                "
                cxPasswordVisibilitySwitch
              />
              <cx-form-errors
                [control]="registerForm.get('password')"
              ></cx-form-errors>
            </label>
          </div>

          <div class="form-group">
            <label>
              <span class="label-content">{{
                'register.confirmPassword.label' | cxTranslate
              }}</span>
              <input
                required="true"
                class="form-control"
                type="password"
                name="confirmpassword"
                placeholder="{{
                  'register.confirmPassword.placeholder' | cxTranslate
                }}"
                formControlName="passwordconf"
                [attr.aria-label]="
                  'register.confirmPassword.placeholder' | cxTranslate
                "
                cxPasswordVisibilitySwitch
              />
              <cx-form-errors
                [control]="registerForm.get('passwordconf')"
              ></cx-form-errors>
            </label>
          </div>

          <div class="form-group">
            <div class="form-check">
              <label *ngIf="anonymousConsent$ | async as anonymousConsent">
                <input
                  type="checkbox"
                  name="newsletter"
                  class="form-check-input"
                  formControlName="newsletter"
                  [checked]="isConsentGiven(anonymousConsent.consent)"
                />
                <span class="form-check-label">
                  {{ anonymousConsent.template }}
                </span>
              </label>
            </div>
          </div>

          <div class="form-group">
            <div class="form-check">
              <label>
                <input
                  required="true"
                  type="checkbox"
                  name="termsandconditions"
                  formControlName="termsandconditions"
                />
                <span class="form-check-label">
                  {{ 'register.confirmThatRead' | cxTranslate }}
                  <a
                    [routerLink]="{ cxRoute: 'termsAndConditions' } | cxUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ 'register.termsAndConditions' | cxTranslate }}
                  </a>
                </span>
                <cx-form-errors
                  [control]="registerForm.get('termsandconditions')"
                ></cx-form-errors>
              </label>
            </div>
          </div>
          <button type="submit" class="btn btn-block btn-primary">
            {{ 'register.register' | cxTranslate }}
          </button>
          <a
            class="cx-login-link btn-link"
            [routerLink]="{ cxRoute: 'login' } | cxUrl"
            >{{ 'register.signIn' | cxTranslate }}</a
          >
        </form>
      </div>
    </div>
  </div>
</section>

<ng-template #loading>
  <div class="cx-spinner"><cx-spinner></cx-spinner></div>
</ng-template>
