/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { RoutingService } from '@lm-nx-frontend/lm-inf-core';
import { Observable } from 'rxjs';
import { CurrentItemService } from '../../shared/current-item.service';
import {ROUTE_PARAMS} from "../../../root/route-params";
import {BudgetService} from "../../../core/services";
import {Budget} from "../../../core/model";

@Injectable({
  providedIn: 'root',
})
export class CurrentBudgetService extends CurrentItemService<Budget> {
  constructor(
    protected routingService: RoutingService,
    protected budgetService: BudgetService
  ) {
    super(routingService);
  }

  protected getDetailsRoute(): string {
    return 'orgBudgetDetails';
  }

  protected getParamKey() {
    return ROUTE_PARAMS.budgetCode;
  }

  protected getItem(code: string): Observable<Budget> {
    return this.budgetService.get(code);
  }

  getError(code: string): Observable<boolean> {
    return this.budgetService.getErrorState(code);
  }
}
