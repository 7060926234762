/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import {UserRegistrationCoreModule} from "./core/user-registration-core.module";
import {UserRegistrationComponentsModule} from "./components/user-registration-components.module";
import {UserRegistrationOccModule} from "./occ/user-registration-occ.module";

@NgModule({
  imports: [
    UserRegistrationCoreModule.forRoot(),
    UserRegistrationComponentsModule,
    UserRegistrationOccModule,
  ],
})
export class OrganizationUserRegistrationModule {}
