<label *ngIf="attribute.intervalInDomain" class="cx-intervalHelpText">{{
  this.getHelpTextForInterval()
}}</label>
<div id="{{ createAttributeIdForConfigurator(attribute) }}" class="relative rounded-md shadow-sm w-full max-w-lg">
  <input
    [formControl]="attributeInputForm"
    [required]="isRequired"
    class="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
    [ngClass]="{
      'pr-12': attribute?.suffix,
    }"
    [attr.aria-describedby]="
      mustDisplayValidationMessage()
        ? createAttributeUiKey('label', attribute.name) +
          ' ' +
          createAttributeUiKey('attribute-msg', attribute.name)
        : createAttributeUiKey('label', attribute.name)
    "
    attr.role="{{ attribute.dataType }}"
    attr.required="{{ attribute.required }}"
    (change)="onChange()"
    maxlength="{{ attribute.maxlength }}"
    [attr.aria-label]="getAriaLabelComplete()"
    [cxFocus]="{
      key: createAttributeIdForConfigurator(attribute)
    }"
  />
  <div
    *ngIf="attribute.suffix"
    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
  >
    <span class="text-gray-500 sm:text-sm">{{ attribute.suffix }}</span>
  </div>
</div>
<div
  class="cx-validation-msg"
  id="{{ createAttributeUiKey('attribute-msg', attribute.name) }}"
  *ngIf="mustDisplayValidationMessage()"
  aria-live="assertive"
  aria-atomic="true"
  role="alert"
>
  <cx-icon [type]="iconType.WARNING"></cx-icon>
  {{
    'configurator.attribute.wrongNumericFormat'
  }}
</div>
