import { OccConfig } from '@lm-nx-frontend/lm-inf-core';

export const defaultOccCategoryConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        /* eslint-disable max-len */
        categoryTrees:
          'categories?fields=DEFAULT',
      },
    },
  },
};
