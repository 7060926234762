import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {from, Observable, tap} from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import {normalizeHttpError} from "@lm-nx-frontend/lm-inf-core";
import {TreeConnector} from "../../connectors/tree.connector";
import {LOAD_CATEGORY_TREE, LoadCategoryTree, LoadCategoryTreeFailure, LoadCategoryTreeSuccess} from "./tree.actions";


@Injectable()
export class TreeEffects {
  loadTree$: Observable<
    | LoadCategoryTree
    | LoadCategoryTreeSuccess
    | LoadCategoryTreeFailure
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_CATEGORY_TREE),
      map((action: LoadCategoryTree) => action.payload),
      concatMap((payload) => {
        return this.treeConnector
          .get(
            payload.userId,
            payload.customerId,
          )
          .pipe(
            map(
              (response) =>
                new LoadCategoryTreeSuccess(
                  {
                    userId: payload.userId,
                    customerId: payload.customerId,
                    tree: response,
                  }
                )
            ),
            catchError((error) =>
              from([
                new LoadCategoryTreeFailure(
                  payload,
                  normalizeHttpError(error)
                ),
              ])
            )
          );
      }),
      // withdrawOn(this.contextChange$)
    )
  );

  constructor(
    private actions$: Actions,
    private treeConnector: TreeConnector
  ) {}
}
